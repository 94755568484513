<template>
  <div class="inputs-wrapper task-type">
    <basic-input
      v-model="name"
      type="text"
      label="Task type name"
      :setFocus="true"
      @keyup.enter="saveChanges"
    />
    <basic-input v-model="ttp_points" type="number" label="EP" />
    <basic-input v-model="ttp_defaultepcoef" type="number" label="Default EP" />
    <basic-checkbox v-model="ttp_billable" label="Billable" />
  </div>
  <div class="inputs-wrapper task-type">
    <basic-button label="Create task type" @click="saveChanges" />
  </div>
</template>
<script setup lang="ts">
import { ModalStore } from "../../store/modalStore";
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const name = ref<string | null>(null);
const props = defineProps(["options"]);

const ttp_points = ref<number | null>(null);
const ttp_billable = ref<boolean | null>(true); //(default je true)
const ttp_defaultepcoef = ref<number | null>(null);

async function saveChanges() {
  if (!name.value) {
    openCustomToast("The 'Task type name' field is required", "error");
    return;
  }
  const params: any = {
    ttp_name: name.value,
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    ttp_billable: ttp_billable.value,
  };
  if (ttp_points.value) {
    params.ttp_points = Number(ttp_points.value);
  }
  if (ttp_defaultepcoef.value) {
    params.ttp_defaultepcoef = Number(ttp_defaultepcoef.value);
  }
  try {
    await api.createTaskType(params);
    openCustomToast("You have successfully created a task type", "success");
    if (props.options.update) {
      props.options.update();
    }
    modalStore.setModalData(null);
  } catch (error: any) {
    openCustomToast("An error occurred while creating the task type", "error");
  }
}
</script>
<style lang="scss">
.inputs-wrapper.task-type {
  margin-bottom: 25px;
  .input-wrapper {
    margin-bottom: 15px;
  }
}
</style>
