<template>
  <div class="inputs-wrapper">
    <div class="input-wrapper">
      <basic-input
        v-model="currentPassword"
        type="password"
        placeholder="Current password"
        label="Current password"
        :color="defaultInputColor"
        :setFocus="true"
        @keyup.enter="saveChanges"
      />
    </div>
    <div class="input-wrapper">
      <basic-input
        v-model="newPassword"
        type="password"
        label="New password"
        placeholder="New password"
        @keyup.enter="saveChanges"
      />
    </div>
    <div class="input-wrapper">
      <basic-input
        v-model="reNewPassword"
        type="password"
        label="Confirm new password"
        placeholder="Confirm new password"
        @keyup.enter="saveChanges"
      />
    </div>
  </div>
  <div class="btn-wrapper">
    <basic-button
      label="Save"
      :modal="true"
      style="width: 100px"
      @click="saveChanges"
    />
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";

const defaultInputColor = "#5190FF";

const emit = defineEmits(["close"]);
const currentPassword = ref<string | null>(null);
const newPassword = ref<string | null>(null);
const reNewPassword = ref<string | null>(null);
defineProps(["options"]);

async function saveChanges() {
  if (!currentPassword.value) {
    openCustomToast("The 'Current password' field is required", "error");
    return;
  }
  if (!newPassword.value || newPassword.value.length < 7) {
    openCustomToast(
      "The new password must have at least 7 characters",
      "error"
    );
    return;
  }
  if (newPassword.value !== reNewPassword.value) {
    openCustomToast(
      "The fields 'New password' and 'Confirm new password' are not the same",
      "error"
    );
    return;
  }
  const params: any = {
    password: newPassword.value,
    old_password: currentPassword.value,
  };
  try {
    await api.changePassword(params);
    openCustomToast("You have successfully changed your password", "success");
    emit("close");
  } catch (error: any) {
    openCustomToast("An error occurred while changing the password", "error");
  }
}
</script>
<style lang="scss" scoped>
.input-wrapper {
  margin-bottom: 15px;
}

.btn-wrapper {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
