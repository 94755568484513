import api from "@/api";
import { type ChatItem } from "@/assets/js/types";
import { type AxiosResponse } from "axios";
import { defaultStore } from ".";

export const MessageStore = defineStore("MessageStore", () => {
  const store = defaultStore();
  const activeTaskId = ref<number | null>(null);
  const oldMessages = ref<ChatItem[]>([]);
  const currentOffset = ref<number>(0);
  const messageLimit = ref<number>(0);
  const currentScrollOffset = ref<number>(0);

  // getters
  const newMessages = computed(() =>
    store.getTaskMessages.map((el) => el.data as ChatItem)
  );
  const getActiveTaskId = computed(() => activeTaskId.value);
  const getMessages = computed(() =>
    newMessages.value.concat(oldMessages.value)
  );

  function updateMessages(payload: ChatItem[]) {
    oldMessages.value.push(...payload);
    currentOffset.value += payload.length;
  }

  function updateTaskId(id: number) {
    activeTaskId.value = id;
    clearState();
  }

  async function getOlderMessages(): Promise<AxiosResponse | never | any> {
    if (!activeTaskId.value) {
      // eslint-disable-next-line no-storage/no-browser-storage, prefer-promise-reject-errors
      return await Promise.reject("No Task Id");
    }
    const params = {
      task_id: activeTaskId.value,
      offset: currentOffset.value,
    };
    return await api.getOlderMessages(params.task_id, params);
  }

  function updateMessageLimit(val: number) {
    if (messageLimit.value) {
      return;
    }
    messageLimit.value = val;
  }

  function clearState() {
    currentOffset.value = 0;
    messageLimit.value = 0;
    oldMessages.value = [];
  }

  return {
    getActiveTaskId,
    getMessages,
    getOlderMessages,
    updateMessages,
    currentOffset,
    updateMessageLimit,
    updateTaskId,
    currentScrollOffset,
    // TODO remove later
    oldMessages,
  };
});
