export const WEB_SOCKET_CONNECTION_MAP = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const colorMap = {
  purple: {
    backgroundColor: "#8451C4",
    borderColor: "#CEAFF3",
  },
  blue: {
    backgroundColor: "#2A9DDD",
    borderColor: "#B6DBF0",
  },
  pink: {
    backgroundColor: "#C45196",
    borderColor: "#DEB5CE",
  },
  yellow: {
    backgroundColor: "#F2DA00",
    borderColor: "#FFF7AB",
  },
  green: {
    backgroundColor: "#51C48D",
    borderColor: "#C9F3DF",
  },
};

export const FIVE_MINUTES_IN_MILIS = 1000 * 300;
export const THIRTY_SECONDS_IN_MILIS = 1000 * 30;

export const taskPriorities = [
  { name: "CRITICAL", value: 10 },
  { name: "HIGHEST", value: 9 },
  { name: "HIGHER", value: 8 },
  { name: "HIGH", value: 7 },
  { name: "ABOVE NORMAL", value: 6 },
  { name: "NORMAL", value: 5 },
  { name: "BELOW NORMAL", value: 4 },
  { name: "LOW", value: 3 },
  { name: "LOWER", value: 2 },
  { name: "LOWEST", value: 1 },
  { name: "IDLE", value: 0 },
];
// TODO possibly rework
export const WS_MAP = {
  general: {
    invalidMessage: "invalid-message",
    invalidJwt: "invalid-jwt",
  } as const,
  notification: {
    refreshJwt: "refresh-jwt",
    newJwt: "new-jwt",
  } as const,
  chat: {
    newMessage: "new-message",
    addMember: "add-member",
    removeMember: "remove-member",
  } as const,
};
