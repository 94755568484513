<template>
  <section class="page-wrapper">
    <div class="page">
      <div class="header">
        <basic-search
          v-model="search"
          placeholder="Search"
          style="align-self: flex-end"
          @update:model-value="onSearch"
        />
        <basic-dropdown
          v-if="isChecked && checkPermission(['hours-approve'])"
          class="types"
          :list="userList"
          :selected="approved_user"
          :searchable="true"
          :labelKey="'usr_displayname'"
          style="margin-left: 20px; align-self: flex-end; min-width: 200px"
          @selected="setUser"
          @keydown.arrow-up="handleArrowUsers('up')"
          @keydown.arrow-down="handleArrowUsers('down')"
        />
        <!-- title="Period" -->
        <basic-dropdown
          class="types"
          :list="periodList"
          :selected="period"
          :searchable="true"
          :labelKey="'label'"
          style="margin-left: 20px; align-self: flex-end; min-width: 230px"
          @selected="setPeriod"
          @keydown.arrow-up="handleArrowPeriod('up')"
          @keydown.arrow-down="handleArrowPeriod('down')"
        />
        <div
          v-if="!(period && period.key === 'all')"
          class="datepicker-wrapper small"
        >
          <!-- <div class="datepicker-label">Date from</div> -->
          <Datepicker
            v-model="start"
            :enable-time-picker="false"
            :clearable="false"
            format="dd.MM.yyyy"
            :auto-apply="true"
            :dark="true"
            input-class-name="datepicker-input"
            menu-class-name="datepicker-dropdown"
            :max-date="end"
            @update:model-value="setCustomPeriod"
          />
        </div>
        <div
          v-if="!(period && period.key === 'all')"
          class="datepicker-wrapper small"
        >
          <!-- <div class="datepicker-label">Date to</div> -->
          <Datepicker
            v-model="end"
            :enable-time-picker="false"
            :clearable="false"
            format="dd.MM.yyyy"
            :auto-apply="true"
            :dark="true"
            input-class-name="datepicker-input"
            menu-class-name="datepicker-dropdown"
            :min-date="start"
            @update:model-value="setCustomPeriod"
          />
        </div>
        <!-- NEW FILTERS 1 part -->
        <div
          v-if="isChecked && checkPermission(['hours-approve'])"
          class="vue-multiselect"
        >
          <!-- <div class="label-multiselect">Client</div> -->
          <dropdownTwo
            v-model="selectedClients"
            :options="clientList"
            dropdownLabel="cli_name"
            dropdownKey="cli_id"
            class="mt-5"
            :label="'Clients'"
            :disabled="false"
            :styleInputWrapper="{ 'max-width': '150px' }"
            :styleLabel="{ width: '100px' }"
            :dropdownStyle="{ width: '150px' }"
            @option-selected="setClient"
            @option-remove="setClient"
          />
          <!-- <multiselect
            ref="myMultiselectRefClient"
            v-model="selectedClients"
            :options="clientList"
            :close-on-select="false"
            multiple
            track-by="cli_id"
            label="cli_name"
            placeholder="Clients"
            :options-limit="300"
            :limit="0"
            :limit-text="limitText"
            :show-labels="false"
            :optionHeight="100"
            @select="setClient"
            @remove="setClient"
            @click="toggleDropdownClient"
          >
            <template #caret>
              <img
                :src="arrowIcon"
                :alt="dropdownAlt"
                :class="{
                  'dropdown-icon': !isDropdownOpenClient,
                  rotated: !isDropdownOpenClient,
                }"
              />
            </template>
          </multiselect> -->
        </div>
        <div
          v-if="isChecked && checkPermission(['hours-approve'])"
          class="vue-multiselect"
        >
          <!-- <div class="label-multiselect">Project</div> -->
          <dropdownTwo
            v-model="selectedProjects"
            :options="projectList"
            dropdownLabel="tsk_name"
            dropdownKey="tsk_id"
            class="mt-5"
            :label="'Projects'"
            :disabled="false"
            :styleInputWrapper="{ 'max-width': '150px' }"
            :styleLabel="{ width: '100px' }"
            :dropdownStyle="{ width: '150px' }"
            @option-selected="setProject"
            @option-remove="setProject"
          />

          <!-- <multiselect
            ref="myMultiselectRefProject"
            v-model="selectedProjects"
            :options="projectList"
            :close-on-select="false"
            multiple
            track-by="tsk_id"
            label="tsk_name"
            placeholder="Projects"
            :options-limit="300"
            :limit="0"
            :limit-text="limitText"
            :show-labels="false"
            @select="setProject"
            @remove="setProject"
            @click="toggleDropdownProject"
          >
            <template #caret>
              <img
                :src="arrowIcon"
                :alt="dropdownAlt"
                :class="{
                  'dropdown-icon': !isDropdownOpenProject,
                  rotated: !isDropdownOpenProject,
                }"
              />
            </template>
          </multiselect> -->
        </div>

        <!-- END NEW FILTERS 1 part -->

        <basic-dropdown
          v-if="isChecked && checkPermission(['hours-approve'])"
          class="types"
          :list="approveFilterList"
          :selected="approved_status_filter"
          :searchable="true"
          :labelKey="'apr_name'"
          style="margin-left: 20px; align-self: flex-end; min-width: 140px"
          @selected="setApprovedFilterStatus"
          @keydown.arrow-up="handleArrowStatus('up')"
          @keydown.arrow-down="handleArrowStatus('down')"
        />

        <!-- NEW FILTERS 2 part -->
        <div v-if="isChecked" class="vue-multiselect">
          <dropdownTwo
            v-model="selectedDropdown"
            :options="taskTypes"
            dropdownLabel="ttp_name"
            dropdownKey="ttp_id"
            class="mt-5"
            :label="'Task type'"
            :disabled="false"
            :styleInputWrapper="{ 'max-width': '150px' }"
            :styleLabel="{ width: '100px' }"
            :dropdownStyle="{ width: '150px' }"
            @option-selected="setTaskType"
            @option-remove="setTaskType"
          />

          <!-- <multiselect
            ref="myMultiselectRefType"
            v-model="selectedDropdown"
            :options="taskTypes"
            :close-on-select="false"
            multiple
            track-by="ttp_id"
            label="ttp_name"
            placeholder="Type to search"
            :options-limit="300"
            :limit="0"
            :limit-text="limitText"
            :show-labels="false"
            @select="setTaskType"
            @remove="setTaskType"
            @click="toggleDropdownType"
          >
            <template #caret>
              <img
                :src="arrowIcon"
                :alt="dropdownAlt"
                :class="{
                  'dropdown-icon': !isDropdownOpenType,
                  rotated: !isDropdownOpenType,
                }"
              />
            </template>
          </multiselect> -->
        </div>
        <!-- END NEW FILTERS 2 part -->
      </div>
      <div class="header">
        <div class="ep">
          <span>Approved / Total EP:</span>
          {{ ep?.ep_approved && ep.ep_approved !== "/" ? ep.ep_approved : 0 }}
          /
          {{ ep?.ep && ep.ep !== "/" ? ep.ep : 0 }}
        </div>
        <div class="ep">
          <span>Approved / Total Hours:</span>
          {{ ep?.total_approved ? ep.total_approved : 0 }}
          /
          {{ ep?.total_hours ? ep.total_hours : 0 }}
        </div>
        <!-- {{ hours[0]?.tms_date }} -->
      </div>
      <table-wrapper
        :data="hours"
        :options="options"
        style="margin-bottom: 10px"
        @delete-row="openConfirmModal"
        @edit="openEditModal"
        @info="openInfoModal"
      />
      <pagination-one
        v-if="count > 0"
        v-model:page="page"
        :perPage="perPage"
        :offset="offset"
        :count="count"
        prevText="Prev"
        nextText="Next"
        style="margin-top: auto"
        @page-changed="
          onChange($event, isChecked ? getHoursToApprove : undefined)
        "
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import {
  openCustomToast,
  debounce,
  checkPermission,
  setTime,
} from "@/assets/js/helpers";
import { type Hour, type Modal, type Period } from "@/assets/js/types";
import dayjs from "dayjs";
import { usePagination } from "@/assets/js/composables";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// const dropdownAlt = computed(() => {
//   return isDropdownOpenClient.value ||
//     myMultiselectRefProject.value ||
//     myMultiselectRefType.value
//     ? "Open dropdown"
//     : "Close dropdown";
// });

// const myMultiselectRefClient = ref<any>(null);
// const myMultiselectRefProject = ref<any>(null);
// const myMultiselectRefType = ref<any>(null);

// const isDropdownOpenClient = ref(false);
// const isDropdownOpenProject = ref(false);
// const isDropdownOpenType = ref(false);

function handleArrowUsers(direction) {
  let index = -1;
  for (let i = 0; i < userList.value.length; i++) {
    if (
      userList.value[i].usr_displayname === approved_user.value.usr_displayname
    ) {
      index = i;
      break;
    }
  }
  if (index !== -1) {
    let newIndex;
    if (direction === "up") {
      newIndex = index > 0 ? index - 1 : 0;
    } else if (direction === "down") {
      newIndex = index < userList.value.length - 1 ? index + 1 : index;
    }
    approved_user.value = userList.value[newIndex];
  }
}
function handleArrowStatus(direction) {
  let index = -1;
  for (let i = 0; i < approveFilterList.value.length; i++) {
    if (
      approveFilterList.value[i].apr_name ===
      approved_status_filter.value.apr_name
    ) {
      index = i;
      break;
    }
  }
  if (index !== -1) {
    let newIndex;
    if (direction === "up") {
      newIndex = index > 0 ? index - 1 : 0;
    } else if (direction === "down") {
      newIndex = index < approveFilterList.value.length - 1 ? index + 1 : index;
    }
    approved_status_filter.value = approveFilterList.value[newIndex];
  }
}
function handleArrowPeriod(direction) {
  let index = -1;
  for (let i = 0; i < periodList.value.length; i++) {
    if (periodList.value[i].label === period.value?.label) {
      index = i;
      break;
    }
  }
  if (index !== -1) {
    let newIndex;
    if (direction === "up") {
      newIndex = index > 0 ? index - 1 : 0;
    } else if (direction === "down") {
      newIndex = index < periodList.value.length - 1 ? index + 1 : index;
    }
    period.value = periodList.value[newIndex];
  }
}
// const toggleDropdownClient = () => {
//   if (isDropdownOpenProject.value === true) {
//     isDropdownOpenProject.value = false;
//   }
//   if (isDropdownOpenType.value === true) {
//     isDropdownOpenType.value = false;
//   }
//   isDropdownOpenClient.value = !isDropdownOpenClient.value;
//   if (myMultiselectRefClient.value) {
//     myMultiselectRefClient.value.isOpen = isDropdownOpenClient.value;
//   }
// };

// const toggleDropdownProject = () => {
//   if (isDropdownOpenClient.value === true) {
//     isDropdownOpenClient.value = false;
//   }
//   if (isDropdownOpenType.value === true) {
//     isDropdownOpenType.value = false;
//   }
//   isDropdownOpenProject.value = !isDropdownOpenProject.value;
//   if (myMultiselectRefProject.value) {
//     myMultiselectRefProject.value.isOpen = isDropdownOpenProject.value;
//   }
// };

// const toggleDropdownType = () => {
//   if (isDropdownOpenClient.value === true) {
//     isDropdownOpenClient.value = false;
//   }
//   if (isDropdownOpenProject.value === true) {
//     isDropdownOpenProject.value = false;
//   }
//   isDropdownOpenType.value = !isDropdownOpenType.value;
//   if (myMultiselectRefType.value) {
//     myMultiselectRefType.value.isOpen = isDropdownOpenType.value;
//   }
// };

// function limitText(count) {
//   return `${count} types is selected`;
// }

const currentMonth = dayjs().get("month");
const startOfMonth = dayjs().set("month", currentMonth).set("date", 1);
const endOfMonth = dayjs()
  .set("month", currentMonth + 1)
  .set("date", 0);

const selectedClients = ref<object[]>([]);
const selectedProjects = ref<object[]>([]);
const selectedDropdown = ref<any[]>([]);
const taskTypes = ref([]);
const selectedDropdownIds = computed(() => {
  return selectedDropdown.value.map((option) => option.ttp_id);
});

// const dropdownOptions = ["a", "b", "c"];
// const selectedOptionsMultiselect = ref();

async function getApprovedFilters() {
  const params = { cmp_id: companyStore.getActiveCompany?.cmp_id };
  try {
    const resClients = api.approveGetClients(params);
    const res2 = api.approveGetUsers(params);
    const res = api.approveGetProjects(params);
    const promises = await Promise.all([res2, res, resClients]);

    userList.value = promises[0].data.data;
    userList.value.unshift(
      { usr_id: "all", usr_displayname: "All users" },
      { usr_id: null, usr_displayname: "My hours" }
    );
    projectList.value = promises[1].data.data;
    projectList.value.unshift({ tsk_id: 0, tsk_name: "All project" });
    clientList.value = promises[2].data.clients;
    clientList.value.unshift({ cli_id: 0, cli_name: "All clients" });
  } catch (error) {
    console.log(error);
  }
}

const modalStore = ModalStore();
const companyStore = CompanyStore();
const hours = ref([]);
const end = ref(
  dayjs()
    .set("hour", 23)
    .set("minutes", 59)
    .set("seconds", 59)
    .format("YYYY-MM-DD HH:mm:ss")
);
const start = ref(
  dayjs()
    .set("hour", 0)
    .set("minutes", 0)
    .set("seconds", 0)
    .format("YYYY-MM-DD HH:mm:ss")
);

const isChecked = ref<boolean>(true);
watch(
  isChecked,
  (val) => {
    resetPagination(val ? getHoursToApprove : undefined);
    const column = options.columns.find((c) => c.type === "created-by");
    if (column) {
      column.label = val ? "Performed by" : "Created by";
    }
  },
  { flush: "post" }
);

async function getUserEfficiencyPoints(params: any) {
  try {
    const resE = await api.getEP(params);
    ep.value = resE.data.data;
  } catch (error) {
    console.log(error);
  }
}

async function getHoursToApprove() {
  const params: any = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    end: dayjs(end.value).utc().format("DD.MM.YYYY HH:mm:ss"),
    start: dayjs(start.value).utc().format("DD.MM.YYYY HH:mm:ss"),
    offset: offset.value - 1,
    limit: perPage.value,
  };
  if (search.value && search.value !== "") {
    params.search = search.value;
  }
  if (all.value) {
    params.view_all = true;
  }

  if (selectedDropdownIds.value) {
    params.ttp_id = selectedDropdownIds.value;
  }

  if (approved_user.value?.usr_id) {
    params.usr_id = approved_user.value?.usr_id;
  }
  if (approved_status_filter.value?.apr_id) {
    params.filter = approved_status_filter.value?.apr_param;
  }
  // if (approved_project.value?.tsk_id) {
  //   params.pro_id = approved_project.value?.tsk_id;
  // }
  if (selectedProjects.value) {
    let tskIds: number[] | null = selectedProjects.value.map(
      (project) => project.tsk_id
    );
    if (tskIds.includes(0)) {
      tskIds = null;
    }
    params.pro_id = tskIds;
  }
  if (selectedClients.value) {
    let cliIds: number[] | null = selectedClients.value.map(
      (client: any) => client.cli_id
    );
    if (cliIds.includes(0)) {
      cliIds = null;
    }
    params.cli_id = cliIds;
  }

  getUserEfficiencyPoints(params);

  try {
    const res = await api.approveGetTable(params);

    hours.value = res.data.data;
    count.value = res.data.meta_info.count;
  } catch (error) {
    console.log(error);
  }
}

const search = ref<string | null>(null);
const all = ref(false);
const userList = ref<any>([
  {
    usr_id: 0,
    usr_displayname: "My hours",
  },
]);
const projectList = ref<any>([]);
const clientList = ref<any>([]);
const approveFilterList = ref<any>([
  { apr_id: 0, apr_name: "All", apr_param: "all" },
  {
    apr_id: 1,
    apr_name: "Approved",
    apr_param: "approved",
  },
  {
    apr_id: 2,
    apr_name: "Not Approved",
    apr_param: "not-approved",
  },
]);
const periodList = ref<Period[]>([
  {
    label: "Hours created today",
    key: "today",
    start: dayjs()
      .set("hour", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .format("YYYY-MM-DD HH:mm:ss"),
    end: dayjs()
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "Hours created yesterday",
    key: "yesterday",
    start: dayjs()
      .subtract(1, "day")
      .set("hour", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .format("YYYY-MM-DD HH:mm:ss"),
    end: dayjs()
      .subtract(1, "day")
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "Hours created in last 7 days",
    key: "week",
    start: dayjs()
      .subtract(7, "day")
      .set("hour", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .format("YYYY-MM-DD HH:mm:ss"),
    end: dayjs()
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "Hours created this month",
    key: "month",
    start: startOfMonth.startOf("month").format("YYYY-MM-DD HH:mm:ss"),
    // start: startOfMonth.format("YYYY-MM-DD HH:mm:ss"),
    end: endOfMonth
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "All hours",
    key: "all",
  },
  {
    label: "Custom",
    key: "custom",
  },
]);
const period = ref<Period | null>(periodList.value[0]);
// const approved_project = ref<any | null>(projectList.value[0]);
const approved_user = ref<any | null>(userList.value[0]);
const approved_status_filter = ref<any>(approveFilterList.value[0]);

const { count, offset, onChange, resetPagination, page, perPage } =
  usePagination(getHoursToApprove);

function setPeriod(newPeriod: Period): void {
  period.value = newPeriod;
  if (newPeriod.key === "all") {
    all.value = true;
  } else {
    all.value = false;
  }
  if (newPeriod.end) {
    end.value = newPeriod.end;
  }
  if (newPeriod.start) {
    start.value = newPeriod.start;
  }
  resetPagination(isChecked.value ? getHoursToApprove : undefined);
}

function setProject(newProject: any): void {
  // approved_project.value = newProject;

  resetPagination(isChecked.value ? getHoursToApprove : undefined);
}
function setClient(newProject: any): void {
  // approved_project.value = newProject;

  resetPagination(isChecked.value ? getHoursToApprove : undefined);
}
function setUser(newUser: any): void {
  approved_user.value = newUser;
  resetPagination(isChecked.value ? getHoursToApprove : undefined);
}
function setTaskType() {
  // if (selectedDropdown.value.length) {
  resetPagination(getHoursToApprove);
  // }
}

function setApprovedFilterStatus(newApprovedStatus: any): void {
  approved_status_filter.value = newApprovedStatus;
  resetPagination(isChecked.value ? getHoursToApprove : undefined);
}

function setCustomPeriod(): void {
  period.value = {
    label: "Custom",
    key: "custom",
  };
  resetPagination(isChecked.value ? getHoursToApprove : undefined);
}

onMounted(() => {
  // getUserHours();
  getTaskTypes();
  getApprovedFilters();
  // getHoursToApprove();
});

onActivated(() => {
  getHoursToApprove();
  // getUserHours();
});

async function getTaskTypes() {
  const params = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    view_all: true,
  };
  try {
    const res = await api.getTaskTypes(params);
    taskTypes.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}

const ep = ref();
async function getPoints(params: any) {
  try {
    const resE = await api.getEP(params);
    ep.value = resE.data.data;
  } catch (error) {
    console.log(error);
  }
}
// async function getUserHours() {
//   try {
//     const params: any = {
//       cmp_id: companyStore.getActiveCompany?.cmp_id,
//       end: dayjs(end.value).utc().format("DD.MM.YYYY HH:mm:ss"),
//       start: dayjs(start.value).utc().format("DD.MM.YYYY HH:mm:ss"),
//       offset: offset.value - 1,
//       limit: perPage.value,
//     };
//     if (search.value && search.value !== "") {
//       params.search = search.value;
//     }
//     if (all.value) {
//       params.view_all = true;
//     }
//     // if (approved_user.value) {
//     //   params.usr_id = approved_user.value.usr_id;
//     // }
//     getPoints(params);
//     const res = await api.getUserHours(params);
//     hours.value = res.data.data;
//     count.value = res.data.meta_info.count;
//   } catch (error) {
//     console.log(error);
//   }
// }

const onSearch = debounce(
  function () {
    resetPagination(isChecked.value ? getHoursToApprove : undefined);
  },
  500,
  false
);

function openConfirmModal(hour: Hour) {
  if (hour.tsk_locked) {
    return;
  }
  //hour.user = JSON.parse(hour.user)
  const data: Modal = {
    component: "confirmModal",
    title: "Delete hours?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `
          Time: <strong>{{setTime(hour.tms_amount)}} </strong><br>
          Date: <strong>{{dayjs
            .utc(hour.tms_date)
            .local()
            .format("DD.MM.YYYY")}} </strong><br>
          Task type: <strong>{{hour.ttp_name}} </strong><br> 
          Description: <strong :title="hour.tms_description">{{
            hour.tms_description?.length && hour.tms_description.length > 30
              ? hour.tms_description.substring(0, 30).concat("...")
              : hour.tms_description
          }} </strong><br>
        `,
        data() {
          return {
            hour,
            dayjs,
          };
        },
        methods: {
          setTime,
        },
      },
      confirm: () => {
        deleteHour(hour);
      },
      cancelText: "Cancel",
      cancel: () => {
        console.log("cancel");
      },
    },
  };
  modalStore.setModalData(data);
}

async function deleteHour(hour: Hour) {
  const params: any = {
    tms_id: hour.tms_id,
  };
  try {
    await api.deleteHours(params);
    openCustomToast("You have successfully deleted the hours.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while deleting hours.", "error");
  } finally {
    // getUserHours();
    getHoursToApprove();
  }
}

function openEditModal(hour: Hour) {
  const h: Hour = JSON.parse(JSON.stringify(hour));
  if (h.tsk_locked) {
    return;
  }
  h.tms_amount = `${Math.floor(parseInt(h.tms_amount as string) / 60)}:${String(
    parseInt(h.tms_amount as string) % 60
  ).padStart(2, "0")}`;

  h.tms_approved = h.tms_approved ? h.tms_approved : "0";
  h.tms_approved = `${Math.floor(parseInt(h.tms_approved) / 60)}:${String(
    parseInt(h.tms_approved) % 60
  ).padStart(2, "0")}`;

  h.tms_billed = h.tms_billed ? h.tms_billed : "0";
  h.tms_billed = `${Math.floor(parseInt(h.tms_billed) / 60)}:${String(
    parseInt(h.tms_billed) % 60
  ).padStart(2, "0")}`;

  // console.log(h)
  const data: Modal = {
    component: "editHoursModal",
    title: "Edit time",
    options: {
      data: h,
      name: `${h.tsk_name}`,
      update: () => {
        // getUserHours();
        getHoursToApprove();
      },
    },
  };
  modalStore.setModalData(data);
}

function openInfoModal(hour: Hour) {
  const data: Modal = {
    component: "infoHoursModal",
    title: "Description",
    options: {
      data: hour,
    },
  };
  modalStore.setModalData(data);
}

const options = reactive({
  title: "",
  noData: "Nemate upisane sate u ovom periodu",
  uniqueProperty: "tms_id",
  columns: [
    {
      key: "tsk_name",
      type: "task-subtask",
      label: "Task",
      style: {
        "max-width": "600px",
      },
    },
    {
      key: "tms_id",
      type: "title",
      label: "ID",
    },
    {
      key: "pro_name",
      type: "client-project",
      label: "client / project",
    },
    {
      key: "ttp_name",
      type: "title",
      label: "Type",
    },

    {
      key: "tst_name",
      type: "title",
      label: "Hour type",
    },
    {
      key: "tms_amount",
      type: "time",
      label: "Time",
    },
    {
      key: "tms_date",
      type: "date",
      label: "Date",
    },
    {
      key: "creator",
      type: "created-by",
      label: "Created by",
    },
    {
      key: "",
      type: "info",
      label: "",
    },
    {
      key: "editable",
      type: "edit",
      label: "",
    },
    {
      key: "deletable",
      type: "delete",
      label: "",
    },
    // {
    //   key: "approved",
    //   type: "approved",
    //   label: "",
    // },
  ],
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  transition: 0.3s;
  height: var(--vt-c-page-height);
  max-width: 1744px;
  margin: 0 auto;

  .page {
    width: 100%;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      .ep {
        padding-top: 20px;
        padding-left: 15px;
        font-family: "Archivo";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 16px;
        width: 260px;

        color: var(--color-text);
        span {
          padding-right: 5px;
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 12px;
          text-transform: uppercase;
          color: var(--label-color);
        }
      }
      .small {
        justify-content: flex-end;
        :deep(input) {
          height: 36px;
        }
      }
      .vue-multiselect {
        width: 100%;
        max-width: 160px;
        display: flex;
        flex-direction: column;
        margin: 0 0 0 20px;
        height: 35px;
        justify-content: end;
        cursor: pointer;
        .multiselect {
          .dropdown-icon {
            transition: transform 0.3s;
          }
          .dropdown-icon.rotated {
            transform: rotate(180deg);
            transition: transform 0.3s;
          }
          img {
            top: 15px;
            right: 10px;
            position: absolute;
            cursor: pointer;
            transition: transform 0.3s;
          }
        }
        .label-multiselect {
          font-size: 12px;
          line-height: 13px;
          font-weight: 600;
          margin-bottom: 18px;
          color: var(--label-color);
        }
        :deep(.multiselect) {
          min-height: 36px;
          .multiselect__tags {
            background-color: #09090c;
            border: 1px solid var(--color-border);
            border-radius: 10px;

            padding: 5px 40px 0 8px;
            height: 36px;
          }
          .multiselect__tags:hover {
            border: 1px solid var(--active-color-modal);
            padding: 5px 40px 0 8px;
            height: 36px;
          }
          .multiselect__content-wrapper {
            border: 2px solid var(--color-border);
            border-radius: 10px;
          }
          .multiselect__single {
            display: none;
          }
        }
        .multiselect__content-wrapper {
          overflow-y: auto;
          max-height: none; /* or any other height you prefer */
        }
        .ms-top-label {
          margin-bottom: 6px;
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;
          color: #98a0b6;
        }
        .multiselect__spinner {
          background-color: transparent;
        }
        .arrow-ms {
          position: absolute;
          right: 20px;
          top: 9px;
        }
        .arrow-ms-wrapped {
          position: absolute;
          right: 20px;
          top: 30px;
        }
        .multiselect__tags,
        .multiselect__content-wrapper {
          border-radius: 20px !important;
        }

        .multiselect__single,
        .multiselect__placeholder {
          color: #acacad;
          font-weight: 400;
          line-height: 14px;
          display: inline-flex;
          align-items: center;
        }
        .multiselect__placeholder {
          color: #acacad;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
        }
        .multiselect__tags {
          padding-left: 23px;
        }
        .multiselect__option {
          padding-left: 25px;
          font-size: 12px;
        }
        .multiselect__option--highlight,
        .multiselect__tag {
          background: #2c75f4;
        }
        .multiselect__tag {
          margin-bottom: 0;
        }
        .multiselect__single,
        .multiselect__input {
          font-weight: 600;
          font-size: 12px;
          color: black;
          line-height: 14px;
        }
      }
      .approve-btn-wrapper {
        max-width: 195px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 20px;

        .approved-label {
          font-size: 12px;
          line-height: 13px;
          font-weight: 600;
          margin-bottom: 6px;
        }

        .approved-content {
          border-radius: 10px;
          border: 1px solid var(red);
          width: 100%;
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 4px 12px;
          color: var(red);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
