import { getLocalStorageReac, setLocalStorageReac } from "@/assets/js/helpers";
import { UserStore } from "@/store/userStore";
import axios from "axios";

// Handles DEV and PROD
export const baseURL = import.meta.env.VITE_APP_ROOT_API;
const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use((config) => {
  handleRequestInterceptor(config);

  return config;
});

function handleRequestInterceptor(config: any) {
  try {
    const jwt = getLocalStorageReac("jwt")?.value;
    if (jwt) config.headers["Authorization"] = jwt;
  } catch (err: any) {
    console.error("ERRor", err.message);
  }
}

instance.interceptors.response.use((response) => {
  if (response.data._new_jwt) {
    const userStore = UserStore();
    setLocalStorageReac("jwt", response.data._new_jwt);
    userStore.fetchPermissions();
  }
  return response;
});

export { instance as axios };
