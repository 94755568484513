<template>
  <div class="confirm-modal-wrapper">
    <!-- <div
      v-if="props.options.subtitle"
      class="subtitle"
      v-html="props.options.subtitle"
    ></div> -->
    <div class="subtitle">
      <div v-if="typeof subtitle_view === 'string'">{{ subtitle_view }}</div>
      <subtitle_view v-else />
    </div>
    <div class="btns-wrapper">
      <basic-button
        :label="props.options.cancelText ? props.options.cancelText : 'Cancel'"
        :inv="true"
        @click="cancel"
      />
      <basic-button
        style="margin-left: 10px"
        :label="
          props.options.confirmText ? props.options.confirmText : 'Confirm'
        "
        @click="confirm"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ModalStore } from "../../store/modalStore";

const modalStore = ModalStore();
const props = defineProps(["options"]);

const subtitle_view = computed(() => {
  if (!props.options.subtitle) {
    return "";
  }
  return typeof props.options.subtitle === "string"
    ? props.options.subtitle
    : defineComponent(props.options.subtitle);
});

function confirm() {
  if (props.options.confirm) {
    props.options.confirm();
  }
  modalStore.setModalData(null);
}
function cancel() {
  if (props.options.cancel) {
    props.options.cancel();
  }
  modalStore.setModalData(null);
}
</script>
<style lang="scss" scoped>
.confirm-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .subtitle {
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    // color: #ecedf2;
    color: var(--label-color);
  }

  .btns-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }
}
</style>
