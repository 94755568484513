<template>
  <div class="avatar-list-wrapper">
    <avatar-image v-for="(user, index) in getUser" :key="index" :user="user" />
    <avatar-image
      v-if="props.users.length > props.maxLength"
      :surplus="props.users.length - props.maxLength"
      :user="{ usr_displayname: '' }"
    />
  </div>
</template>
<script setup lang="ts">
import { type User } from "@/assets/js/types";
type AvatarList = {
  users: User[];
  maxLength: number;
};
const props = defineProps<AvatarList>();
const getUser = computed(() => {
  return props.users.slice(0, props.maxLength);
});
</script>
<style lang="scss" scoped>
.avatar-list-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .avatar {
    margin-left: -15px;
  }

  .avatar:first-child {
    margin-left: 0;
  }
}
</style>
