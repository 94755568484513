<template>
  <div class="inputs-wrapper availability-type">
    <!-- availability -->
    <basic-input
      v-model="data.avt_name"
      type="text"
      label="Availability type name"
      :setFocus="true"
    />
    <div class="input-wrapper">
      <SelectColor
        :old="data.avt_color"
        :profile="true"
        :numberOfcolor="5"
        @color="setColor"
      ></SelectColor>
    </div>
  </div>
  <div class="inputs-wrapper availability-type">
    <basic-button label="Edit" @click="saveChanges" />
  </div>
</template>
<script setup lang="ts">
import { ModalStore } from "../../store/modalStore";
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const props = defineProps(["options"]);
defineEmits(["close"]);

//
const data: any = reactive({
  avt_name: null,
  avt_color: null,
});
const oldData: any = reactive({
  avt_name: null,
  avt_color: null,
});

setOldData(props.options.data);

function setColor(e: string) {
  data.avt_color = e;
}

async function saveChanges() {
  if (!data.avt_name) {
    openCustomToast("The 'Availability type name' field is required", "error");
    return;
  }
  if (!isThereChanges()) {
    openCustomToast("Nothings changed", "error");
    return;
  }

  const params: any = {
    avt_id: props.options.data.avt_id,
    cmp_id: companyStore.getActiveCompany?.cmp_id,
  };

  for (let i in data) {
    if (data[i] !== oldData[i]) {
      if (i === "avt_color") {
        params[i] = data[i].startsWith("#") ? data[i].substring(1) : data[i];
      } else {
        params[i] = data[i];
      }
    }
  }

  try {
    await api.editAvailabilityTypes(params);
    openCustomToast(
      "You have successfully changed the availability type",
      "success"
    );
    if (props.options.update) {
      props.options.update();
    }
    modalStore.setModalData(null);
  } catch (error: any) {
    openCustomToast(
      "An error occurred while changing the availability type",
      "error"
    );
  }
}

function setOldData(propData: any) {
  for (let i in propData) {
    if (i !== "deletable" && i !== "editable") {
      if (i === "avt_color") {
        data[i] = propData[i].startsWith("#") ? propData[i] : "#" + propData[i];
        oldData[i] = propData[i].startsWith("#")
          ? propData[i]
          : "#" + propData[i];
      } else {
        data[i] = propData[i];
        oldData[i] = propData[i];
      }
    }
  }
}

function isThereChanges() {
  let changes = false;
  for (let i in data) {
    if (data[i] !== oldData[i]) {
      changes = true;
    }
  }
  return changes;
}
</script>
<style lang="scss">
.inputs-wrapper.availability-type {
  margin-bottom: 25px;
  .input-wrapper {
    margin-bottom: 15px;
  }
  .btn-wrapper-two {
    margin-left: auto;
    max-width: 100px;
  }
}
</style>
