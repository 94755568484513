<template>
  <div class="wrapp-priority">
    <div
      class="color"
      :style="{
        width: calculatePercent(data) + '%',
        background: setBackground(),
      }"
    ></div>

    <div class="name-priority">{{ setPriorityText() }}</div>
  </div>
</template>

<script setup lang="ts">
import { taskPriorities } from "@/assets/js/values";

const props = defineProps(["data"]);

function setPriorityText(): string | undefined {
  const getObject = taskPriorities.find((el) => el.value === props.data);
  return getObject?.name;
}

function setBackground() {
  let color = "#e74c55";
  // if( props.data <= 4) {
  //   color= "#51C48D";
  // } else if (props.data > 4 && props.data < 8){
  //   color ="#fbb209";
  // }

  switch (props.data) {
    case 0: // IDLE
      color = "#51C48D";
      break;
    case 1: // LOWEST
      color = "#51c466";
      break;
    case 2: // LOWER
      color = "#5ec451";
      break;
    case 3: // LOW
      color = "#7bc451";
      break;
    case 4: // BELOW NORMAL
      color = "#9ac451";
      break;
    case 5: // NORMAL
      color = "#fbb209";
      break;
    case 6: // ABOVE NORMAL
      color = "#fb8a09";
      break;
    case 7: // HIGH
      color = "#fb6209";
      break;
    case 8: // HIGHER
      color = "#e74c4c";
      break;
    case 9: // HIGHEST
      color = "#e74c55";
      break;

    default: // CRITICAL
      color = "#e93740";
      break;
  }

  return ` linear-gradient(
      90deg,
      #f3326000 0%,
      ${color} 102.79%
    )`;
}

function calculatePercent(score) {
  return ((score + 1) / 12) * 100;
}
</script>

<style lang="scss" scoped>
.wrapp-priority {
  width: 75px;
  // width: 62px;
  .color {
    width: 62px;
    // height: 5px;
    height: 4px;
    border-radius: 0 3px 3px 0;
    // background: linear-gradient(
    //   90deg,
    //   rgba(243, 50, 96, 0) 0%,
    //   #e74c55 102.79%
    // );
  }
  .name-priority {
    margin-top: 2px;
    // font-weight: 600;
    // font-size: 10px;
    // line-height: 12px;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    /* identical to box height */
    // text-align: center;
    text-transform: lowercase !important;

    color: var(--label-color);
  }
  .name-priority::first-letter {
    text-transform: uppercase !important;
  }
}
</style>
