<template>
  <div class="input-wrapper">
    <basic-input
      v-model="data.tsk_name"
      placeholder="Title"
      label="Title"
      :color="data.tsk_color"
      :setFocus="true"
    />
  </div>
  <div class="section">
    <div class="part">
      <div class="input-wrapper">
        <div class="datepicker-wrapper" style="margin-left: 0px">
          <div class="datepicker-label">Due date</div>
          <Datepicker
            v-model="data.tsk_due"
            :clearable="false"
            format="dd.MM.yyyy HH:mm"
            :dark="true"
            placeholder="Due date"
            input-class-name="datepicker-input"
            menu-class-name="datepicker-dropdown"
          />
        </div>
      </div>
      <div class="input-wrapper">
        <SelectColor
          :old="data.tsk_color"
          :profile="true"
          @color="setColor"
        ></SelectColor>
      </div>
      <!-- <div class="input-wrapper">
        <basic-input
          v-model="data.hours"
          placeholder="Hours"
          label="Hours"
          :type="'number'"
        />
      </div> -->
    </div>
    <div class="part">
      <div class="input-wrapper">
        <chooser-view
          v-model="data.assign"
          :title="'Assign'"
          :users="users"
          :maxHeight="250"
          :edit="true"
          :changable="false"
          @search="onSearch"
        />
      </div>
    </div>
  </div>
  <div class="btn-wrapper">
    <basic-button
      :inv="true"
      :modal="true"
      style="width: 100px; margin-right: 30px"
      label="Cancel"
      @click="cancel"
    />
    <basic-button
      :modal="true"
      style="width: 100px"
      label="Save"
      @click="saveTask"
    />
  </div>
</template>
<script setup lang="ts">
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api";
import { debounce, openCustomToast } from "@/assets/js/helpers";
import dayjs from "dayjs";
import { type User } from "@/assets/js/types";
import Datepicker from "@vuepic/vue-datepicker";

const props = defineProps(["options"]);
defineEmits(["close"]);
type Data = {
  tsk_name: string | null;
  tsk_due: Date | string | null;
  assign: User[];
  cmp_id?: number;
  tsk_color: string | null;
};

const modalStore = ModalStore();
const companyStore = CompanyStore();
const oldData = reactive<Data>({
  tsk_name: null,
  cmp_id: companyStore.getActiveCompany?.cmp_id,
  tsk_due: null,
  assign: [],
  tsk_color: null,
});
const data = reactive<Data>({
  tsk_name: null,
  cmp_id: companyStore.getActiveCompany?.cmp_id,
  tsk_due: null,
  assign: [],
  tsk_color: null,
});
const users = ref<User[]>([]);

function setupData() {
  const copy = JSON.parse(JSON.stringify(props.options.data));
  oldData.tsk_name = copy.tsk_name;
  oldData.tsk_due = copy.tsk_due
    ? dayjs.utc(copy.tsk_due).local().format("YYYY-MM-DDTHH:mm")
    : null;
  oldData.assign = copy.assigned_users ? copy.assigned_users : [];
  oldData.tsk_color =
    copy.tsk_color !== null
      ? copy.tsk_color.startsWith("#")
        ? copy.tsk_color.toLowerCase()
        : "#" + copy.tsk_color.toLowerCase()
      : null;

  const copy2 = JSON.parse(JSON.stringify(props.options.data));
  data.tsk_name = copy2.tsk_name;
  data.tsk_due = copy2.tsk_due
    ? dayjs.utc(copy2.tsk_due).local().format("YYYY-MM-DDTHH:mm")
    : null;
  data.assign = copy2.assigned_users ? copy2.assigned_users : [];
  data.tsk_color =
    copy2.tsk_color !== null
      ? copy2.tsk_color.startsWith("#")
        ? copy2.tsk_color.toLowerCase()
        : "#" + copy2.tsk_color.toLowerCase()
      : null;
}
setupData();

async function getUsers(search?: string) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search) {
      params.search = search;
    }
    const res = await api.searchUsers(params);
    users.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

const onSearch = debounce(
  function () {
    getUsers(arguments[0]);
  },
  500,
  false
);

async function saveTask() {
  if (!data.tsk_name) {
    openCustomToast('The field "Title" is required!', "error");
    return;
  }
  const params: any = await getParams();
  params.tsk_id = props.options.data.tsk_id;
  try {
    await api.editProject(params);
    modalStore.setModalData(null);
  } catch (error) {
    console.log(error);
  }
  if (props.options.save) {
    props.options.save();
  }
}
function cancel() {
  modalStore.setModalData(null);
}

async function getParams() {
  const params: any = {};

  for (const key of Object.keys(data) as [keyof Data]) {
    if (data[key] !== oldData[key]) {
      if (key === "assign") {
        const assigned_delete = oldData.assign.filter(
          (assigned: any) =>
            data.assign.find((el: any) => el.usr_id === assigned.usr_id) ===
            undefined
        );
        const assigned = data.assign.filter(
          (assigned: any) =>
            oldData.assign.find((el: any) => el.usr_id === assigned.usr_id) ===
            undefined
        );
        if (assigned_delete.length > 0) {
          params.assigned_delete = JSON.stringify(
            assigned_delete.map((el: any) => el.usr_id)
          );
        }
        if (assigned.length > 0) {
          params.assigned = JSON.stringify(
            assigned.map((el: any) => el.usr_id)
          );
        }
      } else if (key === "tsk_due") {
        params[key] = dayjs(data[key]).utc().format("YYYY-MM-DDTHH:mm");
      } else {
        params[key] = data[key];
      }
    }
  }

  return params;
}
function setColor(e: string) {
  data.tsk_color = e;
}
</script>
<style lang="scss" scoped>
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.input-wrapper {
  margin-bottom: 20px;
}

.section {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  .part:first-child {
    margin-right: 40px;
  }

  .part {
    width: 270px;
  }
}

.btn-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
</style>
