<template>
  <div class="validate-page-wrapper"></div>
</template>
<script setup lang="ts">
import api from "@/api";
import { useRouter } from "vue-router";
import { openCustomToast } from "@/assets/js/helpers";

const router = useRouter();

onMounted(() => {
  checkToken();
});

async function checkToken() {
  if (!router.currentRoute.value.query.token) {
    openCustomToast("A validation error occurred", "error");
    router.push({ name: "LoginPage" });
  }
  try {
    await api.validate(String(router.currentRoute.value.query.token));
    openCustomToast(
      "You have successfully validated this email.",
      "success",
      "You can log in."
    );
    router.push({ name: "LoginPage" });
  } catch (error) {
    console.log(error);
    openCustomToast("A validation error occurred", "error");
    router.push({ name: "LoginPage" });
  }
}
</script>
<style lang="scss" scoped></style>
