<template>
  <div class="login-modal-wrapper">
    <div class="inputs-wrapper">
      <basic-input
        v-model="email"
        :type="'email'"
        :name="'email'"
        :label="'Email'"
        :setFocus="true"
        @keyup.enter="login"
      />
    </div>
    <div class="inputs-wrapper">
      <basic-input
        v-model="password"
        label="Password"
        type="password"
        @keyup.enter="login"
      />
    </div>
    <div
      class="forgot-password"
      @click="closeAndNavigate('ForgotPasswordPage')"
    >
      Forgot password?
    </div>
    <div class="inputs-wrapper last">
      <!-- <basic-button label="Login" @click="login" /> -->
      <TurnStile
        v-model="token"
        :resetToken="resetToken"
        btnTxt="Login"
        action="login"
        @send="login"
      ></TurnStile>
    </div>
    <div class="register-link">
      Not registered?
      <span @click="closeAndNavigate('RegisterPage')">Create an account</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { openCustomToast } from "@/assets/js/helpers";
import router from "@/router";
import { ModalStore } from "../../store/modalStore";
import api from "@/api";
import { setLocalStorageReac } from "@/assets/js/helpers";
import { UserStore } from "@/store/userStore";
// import { useReCaptcha } from "vue-recaptcha-v3";
import { CompanyStore } from "@/store/companyStore";
import { transl } from "@/i18n";
import { defaultStore } from "@/store";

const modalStore = ModalStore();
const userStore = UserStore();
const store = defaultStore();
const email = ref<string | null>(null);
const password = ref<string | null>(null);
// const recaptcha = useReCaptcha();
const companyStore = CompanyStore();

const token = ref("");
const resetToken = ref(false);

// function setCaptchaToken(e: any) {
// token.value = e;
// }

function closeAndNavigate(route: string): void {
  modalStore.setModalData(null);
  setTimeout(() => {
    router.push({ name: route });
  }, 300);
}

async function login() {
  // await recaptcha?.recaptchaLoaded();
  // const token = await recaptcha?.executeRecaptcha("login");
  if (!token.value || token.value === "") {
    return;
  }
  const params: any = {
    email: email.value,
    password: password.value,
    google_token: token.value,
  };
  resetToken.value = !resetToken.value;
  try {
    const res = await api.login(params);
    if (res.data.jwt) {
      await setLocalStorageReac("jwt", res.data.jwt);
      store.setJWT(res.data.jwt);
      await companyStore.setupCompanies();
      await userStore.getUserData();

      if (userStore.navigate && userStore.navigate !== "/") {
        router.push(userStore.navigate);
        userStore.setNavigate(null);
      } else {
        router.push({
          name: "MyTasks",
          params: { companyId: companyStore.activeCompany?.cmp_id },
        });
      }
    } else {
      openCustomToast("Bad credentials", "error");
    }
  } catch (error: any) {
    const errData: any = error.response.data;
    if (errData.validation && errData.name) {
      openCustomToast(
        transl(`error.login.${errData.validation}.${errData.name}`),
        "error"
      );
    } else if (errData.message) {
      openCustomToast(transl(`error.login.${errData.message}`), "error");
    }
  }
}
</script>
<style lang="scss" scoped>
.login-modal-wrapper {
  .inputs-wrapper {
    margin-bottom: 15px;
  }
  .inputs-wrapper:nth-of-type(2) {
    margin-bottom: 3px;
  }
  .inputs-wrapper.last {
    margin-bottom: 30px;
  }

  .forgot-password {
    margin-bottom: 30px;
    text-align: right;
    // font-style: italic;
    // font-weight: 400;
    // font-size: 12px;
    // line-height: 13px;
    // color: #acacad;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: var(--label-color);
    cursor: pointer;
  }

  .register-link {
    margin-top: 10px;
    margin-bottom: 28px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: var(--color-text-modal);

    span {
      color: var(--vt-c-active);
      font-weight: 700;
      cursor: pointer;
    }
  }
}
</style>
