<template>
  <div class="company-dropdown-wrapper">
    <div class="label" @click="open = !open">
      <span>{{ companyStore.activeCompany?.cmp_name }}</span>
      <img src="@/assets/icons/dropdown-arrow.png" alt="arrow" />
    </div>
    <transition name="slide-top">
      <div
        v-if="open"
        v-click-outside="
          () => {
            open = false;
          }
        "
        class="dropdown"
      >
        <div
          v-for="company in companyStore.companies"
          :key="company.cmp_id"
          class="option"
          :class="{
            active: company.cmp_id === companyStore.activeCompany?.cmp_id,
          }"
          @click="companyStore.setActiveCompany(company)"
        >
          {{ company.cmp_name }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { CompanyStore } from "@/store/companyStore";

const companyStore = CompanyStore();
const open = ref<boolean>(false);
</script>

<style lang="scss" scoped>
.company-dropdown-wrapper {
  position: relative;

  .label {
    cursor: pointer;
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
    margin-top: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 154px;
    }

    img {
      margin-left: 10px;
    }
  }

  .dropdown {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    padding: 6px 4px 17px 4px;
    z-index: 1;
    background: var(--background-profile-modal);
    border: var(--border-modal);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: max-content;

    .option {
      cursor: pointer;
      // padding: 0 17px;
      padding-top: 7px;
      padding-bottom: 6px;
      padding-left: 25px;
      padding-right: 22px;
    }

    .option:hover {
      transition: 0.5s;
      color: var(--vt-c-active);
      background: var(--background-profile-header-options-hover);
      border-radius: 4px;
    }

    .option.active {
      // background: #2e2e32;
      color: var(--vt-c-active);
      background: var(--background-profile-header-options-hover);
      border-radius: 4px;
    }
  }
}
</style>
