<script setup lang="ts">
import type { Message } from "@/assets/js/types";
import { DateInstance } from "@/assets/js/dateHelper";
defineProps<{
  message: Message;
}>();
function getFormattedDate(date: string | Date) {
  return new DateInstance(date.toString()).formatLocalDate("HH:mm");
}
</script>

<template lang="pug">
.single-message-wrap
  .left-side
    AvatarImage(:user="message.avatar")
  .user-info
    span {{ message.avatar.name }}
    small • {{ getFormattedDate(message.timestamp) }}
    .you(v-if="message.origin") You
  .message
    p {{ message.message }}
</template>

<style lang="scss" scoped>
.single-message-wrap {
  width: 100%;
  display: grid;
  padding-top: 21px;
  grid-template:
    41px 1fr 34px 1fr "left-side user-info"
    "left-side message";
  gap: 0 0;
  grid-auto-flow: row;

  .left-side {
    grid-area: left-side;
  }

  .message {
    grid-area: message;
  }

  .user-info {
    display: flex;
    align-items: center;
    grid-area: user-info;

    span {
      display: block;
      font-weight: 600;
      margin-right: 11px;
    }

    small {
      font-weight: 400;
      color: #acacad;
    }

    .you {
      border-radius: 5px;
      background: var(--vt-c-active);
      padding: 2px;
      font-size: 0.6rem;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}
</style>
