<template>
  <div class="new-password-wrapper"></div>
</template>

<script setup lang="ts">
import { type Modal } from "@/assets/js/types";
import { ModalStore } from "../../../store/modalStore";
const modalStore = ModalStore();
onMounted(() => {
  const params: Modal = {
    title: "New password",
    component: "newPasswordModal",
    options: {
      hideBackdrop: true,
      hideClose: true,
      transparentModal: true,
    },
  };
  modalStore.setModalData(params);
});
onBeforeUnmount(() => {
  modalStore.setModalData(null);
});
</script>

<style lang="scss" scoped>
.login-wrapper {
  padding-top: var(--vt-c-header-height);
}
</style>
