<template>
  <div class="input-wrapper">
    <basic-input
      v-model="data.tsk_name"
      placeholder="Title"
      label="Title"
      :color="data.tsk_color"
      :setFocus="true"
    />
  </div>
  <div class="section">
    <div class="section-wrapper" style="margin-right: 20px">
      <div class="section">
        <div class="part">
          <div class="input-wrapper">
            <!-- <basic-input
              v-model="data.tsk_due"
              placeholder="Due date"
              label="Due date"
              :type="'datetime-local'"
            /> -->
            <div class="datepicker-wrapper" style="margin-left: 0">
              <div class="datepicker-label">Due date</div>
              <Datepicker
                v-model="data.tsk_due"
                :clearable="false"
                format="dd.MM.yyyy HH:mm"
                :dark="true"
                placeholder="Due date"
                input-class-name="datepicker-input"
                menu-class-name="datepicker-dropdown"
              />
            </div>
          </div>
        </div>
        <div class="part">
          <div class="input-wrapper">
            <basic-dropdown
              :list="taskPriorities"
              :selected="data.tsk_priority"
              :searchable="true"
              :labelKey="'name'"
              :title="'Priority'"
              @selected="setPriority"
            >
              <template #default="slotProps">
                <dropdown-item :item="slotProps.item" />
              </template>
            </basic-dropdown>
          </div>
        </div>
      </div>
      <div class="input-wrapper">
        <basic-input
          v-model="data.tsk_description"
          placeholder="Description"
          label="Description"
          :type="'textarea'"
        />
      </div>

      <div class="input-wrapper">
        <SelectColor :old="data.tsk_color" :profile="true" @color="setColor">
        </SelectColor>
        <!-- {{ data.tsk_color }} -->
      </div>
    </div>
    <div class="part">
      <chooser-view
        v-model="data.assigned"
        :title="'Assign'"
        :users="users"
        :edit="true"
        :changable="false"
        @search="onSearch"
      />
    </div>
  </div>

  <!-- <div class="input-wrapper">
    <basic-input
      v-model="data.hours"
      placeholder="Hours"
      label="Hours"
      :type="'number'"
    />
  </div> -->

  <div class="btn-wrapper">
    <basic-button
      :inv="true"
      :modal="true"
      style="width: 100px; margin-right: 30px"
      label="Cancel"
      @click="cancel"
    />
    <basic-button
      :modal="true"
      style="width: 100px"
      label="Save"
      @click="saveTask"
    />
  </div>
</template>
<script setup lang="ts">
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api";
import { debounce, openCustomToast } from "@/assets/js/helpers";
import { taskPriorities } from "@/assets/js/values";
import type { User } from "@/assets/js/types";
import dayjs from "dayjs";
import Datepicker from "@vuepic/vue-datepicker";

const props = defineProps(["options"]);
defineEmits(["close"]);

const modalStore = ModalStore();
const companyStore = CompanyStore();

type Data = {
  tsk_name: string | null;
  tsk_description: string | null;
  cmp_id?: number;
  tsk_due?: Date | string | null;
  assigned: User[];
  tsk_priority?: (typeof taskPriorities)[0] | null;
  tsk_color?: string | null;
};

const data = reactive<Data>({
  tsk_name: null,
  tsk_description: null,
  cmp_id: companyStore.getActiveCompany?.cmp_id,
  tsk_due: null,
  assigned: [],
  tsk_priority: null,
  tsk_color: null,
});
const oldData = reactive<Data>({
  tsk_name: null,
  tsk_description: null,
  cmp_id: companyStore.getActiveCompany?.cmp_id,
  tsk_due: null,
  assigned: [],
  tsk_priority: null,
  tsk_color: null,
});
const users = ref<User[]>([]);

function setupData() {
  const copy = JSON.parse(JSON.stringify(props.options.data));
  oldData.tsk_name = copy.tsk_name;
  oldData.tsk_description = copy.tsk_description;
  oldData.tsk_due = copy.tsk_due
    ? dayjs.utc(copy.tsk_due).local().format("YYYY-MM-DDTHH:mm")
    : null;
  oldData.assigned = copy.assigned_users ? copy.assigned_users : [];
  oldData.tsk_priority = taskPriorities.find(
    (el) => el.value === copy.tsk_priority
  );
  oldData.tsk_color =
    copy.tsk_color !== null
      ? copy.tsk_color.startsWith("#")
        ? copy.tsk_color.toLowerCase()
        : "#" + copy.tsk_color.toLowerCase()
      : null;

  const copy2 = JSON.parse(JSON.stringify(props.options.data));
  data.tsk_name = copy2.tsk_name;
  data.tsk_description = copy2.tsk_description;
  data.tsk_due = copy2.tsk_due
    ? dayjs.utc(copy2.tsk_due).local().format("YYYY-MM-DDTHH:mm")
    : null;
  data.assigned = copy2.assigned_users ? copy2.assigned_users : [];
  data.tsk_priority = taskPriorities.find(
    (el) => el.value === copy2.tsk_priority
  );
  data.tsk_color =
    copy2.tsk_color !== null
      ? copy2.tsk_color.startsWith("#")
        ? copy2.tsk_color.toLowerCase()
        : "#" + copy2.tsk_color.toLowerCase()
      : null;
}
setupData();

const onSearch = debounce(
  function () {
    getUsers(arguments[0]);
  },
  500,
  false
);

async function getUsers(search?: string) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search) {
      params.search = search;
    }
    const res = await api.searchUsers(params);
    users.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

async function saveTask() {
  if (!data.tsk_name) {
    openCustomToast('"Title" field is required!', "error");
    return;
  }
  const params: any = await getParams();

  try {
    await api.editTask(params, props.options.data.tsk_id);
    modalStore.setModalData(null);
  } catch (error: any) {
    console.log(error);
    let err = error?.response?.data?.message;
    if (err === "task-is-locked") {
      openCustomToast("Task is locked.", "error");
    } else if (err === "nothing-changed") {
      openCustomToast("Nothing changed.", "error");
    } else {
      openCustomToast("Error.", "error");
    }
  }
  if (props.options.save) {
    props.options.save();
  }
}

function setColor(e: string) {
  data.tsk_color = e;
}

async function getParams() {
  const params: any = {};

  for (const key of Object.keys(data) as [keyof Data]) {
    if (data[key] !== oldData[key]) {
      if (key === "tsk_priority") {
        params[key] = data.tsk_priority?.value;
      } else if (key === "assigned") {
        const assigned_delete = oldData.assigned.filter(
          (assigned) =>
            data.assigned.find((el) => el.usr_id === assigned.usr_id) ===
            undefined
        );
        const assigned = data.assigned.filter(
          (assigned) =>
            oldData.assigned.find((el) => el.usr_id === assigned.usr_id) ===
            undefined
        );
        if (assigned_delete.length > 0) {
          params.assigned_delete = JSON.stringify(
            assigned_delete.map((el) => el.usr_id)
          );
        }
        if (assigned.length > 0) {
          params.assigned = JSON.stringify(assigned.map((el) => el.usr_id));
        }
      } else if (key === "tsk_due") {
        params[key] = dayjs(data[key]).utc().format("YYYY-MM-DDTHH:mm");
      } else {
        params[key] = data[key];
      }
    }
  }

  return params;
}
function setPriority(priority: any) {
  data.tsk_priority = priority;
}

function cancel() {
  modalStore.setModalData(null);
}
</script>
<style lang="scss" scoped>
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.input-wrapper {
  margin-bottom: 15px;
}

.section {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  .part {
    width: 270px;
  }

  .part:first-child {
    margin-right: 20px;
  }
}

.btn-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
</style>
