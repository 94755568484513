import { ModalStore } from "./modalStore";
import type {
  TaskClipboard,
  Modal,
  Transfer,
  Task,
  HourClipboard,
} from "@/assets/js/types";

import api from "@/api";
import { HoursStore } from "./hoursStore";
import { TasksStore } from "./tasksStore";
import { EstimationsStore } from "./estimationsStore";
import { openCustomToast } from "@/assets/js/helpers";
import router from "@/router";

export const TransferStore = defineStore("TransferStore", () => {
  const modalStore = ModalStore();
  const hoursStore = HoursStore();
  const tasksStore = TasksStore();
  const estimationsStore = EstimationsStore();
  const target = ref<Transfer | null>(null);
  const destination = ref<Transfer | null>(null);
  const refreshMyTasks = ref<boolean>(false);
  const tasksClipboard = ref<TaskClipboard | null>();
  const hoursClipboard = ref<HourClipboard | null>();

  const tasksClipboardNotEmpty = computed(
    () => !!tasksClipboard.value && tasksClipboard.value.tasks.length > 0
  );
  const hoursClipboardNotEmpty = computed(
    () => !!hoursClipboard.value && hoursClipboard.value.hours.length > 0
  );

  function setTarget(tar: Transfer): void {
    target.value = tar;
  }

  function setDestination(des: Transfer): void {
    destination.value = des;
    checkCompatibility();
  }

  function clear(): void {
    target.value = null;
    destination.value = null;
  }

  async function checkCompatibility() {
    if (destination.value?.type === "task" && target.value) {
      let taskName = "";
      try {
        const res = await api.getTaskDetails(
          { cmp_id: router.currentRoute.value.params.companyId },
          destination.value.data.tsk_id
        );
        taskName = res.data.task.tsk_name;
      } catch (error) {
        console.log(error);
      }

      let title = "";
      if (target.value.type === "hour") {
        title = `Are you sure you want to move this hours to task "${taskName}"?`;
      } else if (target.value.type === "estimate") {
        title = `Are you sure you want to move this estimation to task "${taskName}"?`;
      } else {
        title = `Are you sure you want to move this task to task "${taskName}"?`;
      }

      const data: Modal = {
        component: "confirmModal",
        title: "Are you sure?",
        options: {
          subtitle: title,
          confirmText: "Confirm",
          confirm: async () => {
            await completeTransfer();
            clear();
          },
          cancelText: "Cancel",
          cancel: () => {
            clear();
          },
        },
      };
      if (target.value.data.tsk_id === destination.value?.data.tsk_id) {
        // openCustomToast(
        //   "It is not possible to nest a task within itself",
        //   "error"
        // );
        return;
      }
      modalStore.setModalData(data);
    } else {
      clear();
    }
  }

  async function completeTransfer() {
    if (target.value?.type === "hour") {
      const params = {
        tms_id: target.value.data.tms_id,
        tsk_id: destination.value?.data.tsk_id,
      };
      try {
        await api.transferHours(params);
        hoursStore.refresh();
        // tasksStore.getTasks();
        checkPageListRefresh();
      } catch (e: any) {
        writeError(e.response.data.message);
      }
    } else if (target.value?.type === "task") {
      const params = {
        tsk_id: target.value.data.tsk_id,
        tsk_id_new: destination.value?.data.tsk_id,
      };
      try {
        await api.transferSubtask(params);
        // tasksStore.getTasks();
        checkPageListRefresh();
      } catch (e: any) {
        writeError(e.response.data.message);
      }
    } else if (target.value?.type === "estimate") {
      const params = {
        ttp_id: target.value.data.ttp_id,
        tsk_id: target.value.data.tsk_id,
        tsk_id_new: destination.value?.data.tsk_id,
      };
      try {
        await api.transferEstimation(params);
        estimationsStore.refresh();
        // tasksStore.getTasks();
        checkPageListRefresh();
      } catch (e: any) {
        writeError(e.response.data.message);
      }
    }
  }

  // it checks on which page the transfer is performed
  // and executes the logic to refresh the list which is needed
  function checkPageListRefresh() {
    if (router.currentRoute.value.name === "MyTaskSection") {
      refreshMyTasks.value = !refreshMyTasks.value;
    } else {
      tasksStore.getTasks();
    }
  }

  function writeError(message: string) {
    if (message === "invalid-tms_id") {
      openCustomToast("Invalid tms_id", "error");
    } else if (message === "edit-time-expired") {
      openCustomToast("Time for editing hours expired", "error");
    } else if (message === "invalid-tsk_id") {
      openCustomToast("Invalid tsk_id", "error");
    } else if (message === "different-pro_id") {
      openCustomToast("Different pro_id", "error");
    } else if (message === "different-cmp_id") {
      openCustomToast("Different cmp_id", "error");
    } else if (message === "no-privilege") {
      openCustomToast("No privilege", "error");
    } else if (message === "invalid-tsk_id_new") {
      openCustomToast("Invalid tsk_id_new", "error");
    } else if (message === "same-task") {
      openCustomToast("Target and destiantion tasks are same", "error");
    } else if (message === "max-depth") {
      openCustomToast("Max subtask depth reached", "error");
    } else if (message === "invalid-parent-id") {
      openCustomToast("Invalid parent-id", "error");
    } else if (message === "cannot-move-task-to-child") {
      openCustomToast("Cannot move task to child", "error");
    } else if (message === "invalid-ttp_id") {
      openCustomToast("Invalid ttp_id", "error");
    } else if (message === "invalid-ttp_id-or-tsk_id") {
      openCustomToast("Invalid ttp_id or tsk_id", "error");
    } else if (message === "estimation-exists") {
      openCustomToast("Estimation already exists", "error");
    } else if (message === "destination-task-is-locked") {
      openCustomToast("Destination task is locked", "error");
    } else {
      openCustomToast("Transferring error", "error");
    }
  }

  function setTasksCopy(type: "copy" | "cut", tasks: Record<string, any>[]) {
    tasksClipboard.value = {
      type,
      tasks,
    };
  }

  function pasteTasks(new_task: Record<string, any>) {
    const data: Modal = {
      component: "confirmModal",
      title: "Are you sure?",
      options: {
        subtitle: {
          template: `
            <p>Are you sure you want to ${tasksClipboard.value?.type} </p>
            <ul v-for="task in firstTasks" :key="task.tsk_id" style="list-style-type: none">
              <li>
                <strong>{{task.tsk_name}}</strong>
              </li>
            </ul>
            <p><template v-if="tasks.length > 5">and <strong>{{tasks.length - 5}}</strong> more tasks</template> to <strong>{{new_task.tsk_name}}</strong></p>
          `,
          data() {
            return {
              new_task,
              tasks: tasksClipboard.value?.tasks,
            };
          },
          computed: {
            firstTasks() {
              // @ts-ignore
              return this.tasks.slice(0, 5);
            },
          },
        },
        confirmText: "Confirm",
        confirm: async () => {
          await confirmPasteTasks(new_task.tsk_id);
          clearClipboard("task");
        },
        cancelText: "Cancel",
      },
    };
    modalStore.setModalData(data);
  }

  async function confirmPasteTasks(tsk_id_new: number) {
    if (!tasksClipboard.value) {
      return;
    }

    try {
      if (tasksClipboard.value.type === "copy") {
        await api.copyTasks({
          tasks: tasksClipboard.value?.tasks.map((el) => el.tsk_id),
          tsk_id_new,
        });
      }
      if (tasksClipboard.value.type === "cut") {
        await api.cutTasks({
          tasks: tasksClipboard.value?.tasks.map((el) => el.tsk_id),
          tsk_id_new,
        });
      }
    } catch (error: any) {
      writeError(error.response.data.message);
    } finally {
      checkPageListRefresh();
      // tasksStore.getTasks();
    }
  }
  function setHoursCopy(type: "copy" | "cut", hours: Record<string, any>[]) {
    hoursClipboard.value = {
      type,
      hours,
    };
  }

  function pasteHours(new_task: Record<string, any>) {
    const data: Modal = {
      component: "confirmModal",
      title: "Are you sure?",
      options: {
        subtitle: {
          template: `
            <p>Are you sure you want to ${hoursClipboard.value?.type} hours:</p>
            <ul v-for="hour in firstHours" :key="hour.tsk_id" style="list-style-type: none">
              <li>
                ID: <strong>{{hour.tms_id}} - {{hour.tms_shortinfo ? hour.tms_shortinfo.slice(0, 30) : ""}}</strong>
              </li>
            </ul>
            <p>
              <template v-if="hours.length > 5">
                and <strong>{{hours.length - 5}}</strong> more hours
              </template>
              to <strong>{{new_task.tsk_name}}</strong>
            </p>
          `,
          data() {
            return {
              new_task,
              hours: hoursClipboard.value?.hours,
            };
          },
          computed: {
            firstHours() {
              // @ts-ignore
              return this.hours.slice(0, 5);
            },
          },
        },
        confirmText: "Confirm",
        confirm: async () => {
          await confirmPasteHours(new_task.tsk_id);
          clearClipboard("hour");
        },
        cancelText: "Cancel",
      },
    };
    modalStore.setModalData(data);
  }

  async function confirmPasteHours(tsk_id_new: number) {
    if (!hoursClipboard.value) {
      return;
    }

    try {
      if (hoursClipboard.value.type === "copy") {
        await api.copyHours({
          hours: hoursClipboard.value?.hours.map((el) => el.tms_id),
          tsk_id_new,
        });
      }
      if (hoursClipboard.value.type === "cut") {
        await api.cutHours({
          hours: hoursClipboard.value?.hours.map((el) => el.tms_id),
          tsk_id_new,
        });
      }
    } catch (error: any) {
      writeError(error.response.data.message);
    } finally {
      hoursStore.refresh();
      checkPageListRefresh();
    }
  }

  function clearClipboard(type: "task" | "hour" | "both") {
    if (type !== "task") {
      hoursClipboard.value = null;
    }
    if (type !== "hour") {
      tasksClipboard.value = null;
    }
  }

  return {
    target,
    destination,
    refreshMyTasks,
    tasksClipboardNotEmpty,
    setTarget,
    setDestination,
    setTasksCopy,
    pasteTasks,
    setHoursCopy,
    pasteHours,
    hoursClipboardNotEmpty,
    clearClipboard,
  };
});
