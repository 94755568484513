export const ProjectStore = defineStore("ProjectStore", () => {
  type NumOrNull = number | null;

  const selectedProjectId = ref<NumOrNull>(null);
  const selectedProjectTaskId = ref<NumOrNull>(null);
  const selectedProjectTaskSection = ref<string | null>(null);

  function setSelectedProjectTaskId(taskId: NumOrNull) {
    selectedProjectTaskId.value = taskId;
  }

  function setSelectedProjectId(projectId: NumOrNull) {
    selectedProjectId.value = projectId;
  }

  function setSelectedProjectTaskSection(section: string | null) {
    selectedProjectTaskSection.value = section;
  }

  const getSelectedProjectId = computed(() => {
    return selectedProjectId.value;
  });

  const getSelectedProjectTaskId = computed(() => {
    return selectedProjectTaskId.value;
  });

  const getSelectedProjectTaskSection = computed(() => {
    return selectedProjectTaskSection.value;
  });

  return {
    selectedProjectId,
    setSelectedProjectId,
    getSelectedProjectId,
    selectedProjectTaskId,
    setSelectedProjectTaskId,
    getSelectedProjectTaskId,
    selectedProjectTaskSection,
    setSelectedProjectTaskSection,
    getSelectedProjectTaskSection,
  };
});
