import { baseURL } from "@/api/config";
import { type RemovableRef, useStorage } from "@vueuse/core";
import type { JWTPayload, ToastProps, ToastTypes } from "@/assets/js/types";
import { useToast } from "vue-toastification";
const toast = useToast();
import customToast from "@/components/customToast.vue";
import { UserStore } from "@/store/userStore";
import type { Ref, UnwrapRef } from "vue";

export function setTime(data: any) {
  return `${Math.floor(parseInt(data) / 60)}:${String(
    parseInt(data) % 60
  ).padStart(2, "0")}`;
}

// @ts-ignore
export function debounce(func, waitTimer, immediate) {
  // @ts-ignore
  let timeout = null;
  return function () {
    // @ts-ignore
    // eslint-disable-next-line
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    // @ts-ignore
    const callNow = immediate && !timeout;
    // @ts-ignore
    clearTimeout(timeout);
    // @ts-ignore
    timeout = setTimeout(later, waitTimer);
    if (callNow) func.apply(context, args);
  };
}

// @ts-ignore
export function throttle(func, waitTimer = 500) {
  let inThrottle = false;
  return function () {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, waitTimer);
    }
  };
}

export function focusFirstElement(
  parentEl: HTMLElement,
  isFocus = true,
  isDebug = false
) {
  // Don't invoke more than once (Before a component is destroyed)
  if (parentEl) {
    const inputListToIncludeFocusable = [
      "input:not(:disabled):not(.hidden)",
      "textarea:not(:disabled):not(.hidden)",
      "button:not(:disabled):not(.hidden)",
      "span.focusable",
      "div.focusable",
      ".multiselect[tabindex]",
      "*[tabindex]:not(:disabled):not(.hidden)",
    ].join();

    const nodeList = Array.from(
      parentEl.querySelectorAll(inputListToIncludeFocusable)
    ) as HTMLElement[];
    if (nodeList?.length) {
      const addInputTabHandling = (nodeList: HTMLElement[]) => {
        const focusEl = (evt: KeyboardEvent, oppoEl: HTMLElement) => {
          // Only for first / last element
          oppoEl.focus();
          evt.preventDefault();
        };

        // First el
        nodeList[0].addEventListener("keydown", (evt: KeyboardEvent) => {
          if (evt.key === "Tab" && evt.shiftKey) {
            focusEl(evt, nodeList[nodeList.length - 1]);
          }
        });

        // Last el
        nodeList[nodeList.length - 1].addEventListener(
          "keydown",
          (evt: KeyboardEvent) => {
            if (evt.key === "Tab" && !evt.shiftKey) {
              focusEl(evt, nodeList[0]);
            }
          }
        );
      };

      if (isFocus) {
        // console.log("➕ Focusing first el", nodeList[0]);
        nodeList[0].focus();

        if (isDebug) {
          console.error("➕ Focusing first el", nodeList[0]);
        }
      }

      addInputTabHandling(nodeList);
    } else if (isDebug) {
      console.warn("No child element found for focus");
    }
  } else if (isDebug) {
    console.warn("No parent element found for focus");
  }
}

export function getCurrentDomain() {
  let url = new URL(location.href);
  try {
    url = new URL(baseURL);
  } catch (err) {
    // ignored - could break on prod because link is not valid
  }
  return url;
}

export function getLocalStorageReac(
  key: string,
  shouldParse = false,
  defaultVal: any = null
): RemovableRef<any> | any {
  try {
    // eslint-disable-next-line no-storage/no-browser-storage
    const state = useStorage(key, defaultVal, localStorage);
    if (shouldParse && "value" in state) return JSON.parse(state.value);
    return state;
  } catch (err) {
    console.error("Error loading localStorage key", key);
    return defaultVal || null;
  }
}

export function deleteLocalStorageReac(key: string): void {
  const reac = getLocalStorageReac(key);
  reac.value = null;
}

export function getUseStorage<T>(
  key: string,
  shouldParse = false,
  storage: Storage,
  defaultVal?: T
): Ref<T | UnwrapRef<T> | string | null | undefined> {
  const state = useStorage(key, defaultVal, storage);
  if (!shouldParse) return state;

  try {
    return ref(JSON.parse(state.value as unknown as string));
  } catch (err) {
    console.error("Error loading key", key);
    return ref(defaultVal);
  }
}

export async function setLocalStorageReac(
  key: string,
  value: any
): Promise<RemovableRef<any>> {
  let tempValue = value;
  if (typeof value !== "string") {
    tempValue = JSON.stringify(value);
  }

  // GH: https://github.com/vueuse/vueuse/issues/2193
  // eslint-disable-next-line no-storage/no-browser-storage
  const state = useStorage(key, null, localStorage);
  state.value = tempValue;

  // eslint-disable-next-line no-storage/no-browser-storage
  if (localStorage[key] !== tempValue) {
    // eslint-disable-next-line no-storage/no-browser-storage
    localStorage[key] = tempValue;
    return ref(tempValue);
  }
  return state;
}
/** GH: {@link https://github.com/vueuse/vueuse/issues/2193 issue}  */
export function setSessionStorageReac(
  key: string,
  value: any
): RemovableRef<any> {
  let tempValue = value;
  if (typeof value !== "string") {
    tempValue = JSON.stringify(value);
  }

  // GH: https://github.com/vueuse/vueuse/issues/2193
  // eslint-disable-next-line no-storage/no-browser-storage
  const state = useStorage(key, null, sessionStorage);
  state.value = tempValue;

  // eslint-disable-next-line no-storage/no-browser-storage
  if (sessionStorage[key] !== tempValue) {
    // eslint-disable-next-line no-storage/no-browser-storage
    sessionStorage[key] = tempValue;
    return ref(tempValue);
  }
  return state;
}
export function openCustomToast(
  title: string,
  type: ToastTypes,
  message?: string | undefined
): void {
  const props: ToastProps = {
    title: title,
    type: type,
    message: message,
  };
  toast({
    component: customToast,
    props: props,
  });
}

export function validateInteger(number: any, greaterThanZero = true): boolean {
  return (
    number === 0 ||
    (number &&
      typeof number !== "object" &&
      Number.isInteger(Number(number)) &&
      (number >= 0 || (!greaterThanZero && number < 0)))
  );
}

export function validateString(string: any, length = 255): boolean {
  return (
    string &&
    typeof string === "string" &&
    string.trim().length > 0 &&
    string.trim().length <= length
  );
}

export function validateArray(array: any): boolean {
  return Array.isArray(array) && array.length > 0;
}

export function validatePassword(password: any): boolean {
  return (
    password &&
    password.trim().length > 0 &&
    password.length > 6 &&
    password.length < 32
  );
}

export function readJwt(a: string): JWTPayload {
  a = a.slice(6, a.length);
  const arrH = a.split(".");
  return JSON.parse(window.atob(arrH[1]));
}

export async function getJwtData() {
  const rawToken = getLocalStorageReac("jwt", false, "");

  if (!rawToken.value) {
    return null;
  }

  const token: any = readJwt(rawToken.value);
  if (parseInt(token.exp) > new Date().getTime() / 1000) {
    return token;
  } else {
    return null;
  }
}

/**
 *
 * @param permissions List of permissions
 * @param inclusive All permissions required (default = true)
 */
export function checkPermission(
  permissions: string[],
  inclusive = true
): boolean {
  const userStore = UserStore();
  const allPermissions = userStore.getAllPermissions;
  let res = false;

  // if user has no privileges
  if (allPermissions.length < 1) {
    return res;
  }

  // if user has all-privileges return true
  if (allPermissions.find((el) => el.prv_name === "all-privileges")) {
    return true;
  }

  // if all permisions are required (inclusive = true - default)
  if (inclusive) {
    res = true;
    permissions.forEach((permission) => {
      if (
        allPermissions.find((el) => el.prv_name === permission) === undefined
      ) {
        res = false;
        return;
      }
    });
  } else {
    // if only one permission is required (inclusive = false)
    allPermissions.forEach((el) => {
      if (permissions.includes(el.prv_name)) {
        res = true;
        return;
      }
    });
  }

  return res;
}

export function getRandomValueForBoundries(bottom = 2_000, top = 10_000) {
  return bottom + Math.random() * (top - bottom);
}
