<template>
  <div class="inputs-wrapper task-type">
    <!-- <basic-input
      v-model="name"
      type="text"
      label="Task type name"
      @keyup.enter="saveChanges"
    /> -->
    <basic-input
      v-model="data.ttp_name"
      type="text"
      label="Task type name"
      :setFocus="true"
    />
    <basic-input v-model="data.ttp_points" type="number" label="EP" />
    <basic-input
      v-model="data.ttp_defaultepcoef"
      type="number"
      label="Default EP"
    />
    <basic-checkbox v-model="data.ttp_billable" label="Billable" />
  </div>
  <div class="inputs-wrapper task-type">
    <basic-button label="Save changes" @click="saveChanges" />
  </div>
</template>
<script setup lang="ts">
import { ModalStore } from "../../store/modalStore";
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const name = ref<string | null>(null);
const props = defineProps(["options"]);
defineEmits(["close"]);

const oldName = ref<string | null>(null);

//
const data: any = reactive({
  ttp_name: null,
  ttp_points: null,
  ttp_billable: null, //(default je true)
  ttp_defaultepcoef: null,
});
const oldData: any = reactive({
  ttp_name: null,
  ttp_points: null,
  ttp_billable: null, //(default je true)
  ttp_defaultepcoef: null,
});

onMounted(() => {
  oldName.value = props.options.data.ttp_name;
  name.value = props.options.data.ttp_name;
  setOldData(props.options.data);
});

async function saveChanges() {
  if (!data.ttp_name) {
    openCustomToast("The 'Task type name' field is required", "error");
    return;
  }
  if (!isThereChanges()) {
    openCustomToast("Nothings changed", "error");
    return;
  }

  const params: any = {
    ttp_id: props.options.data.ttp_id,
    cmp_id: companyStore.getActiveCompany?.cmp_id,
  };

  for (let i in data) {
    if (data[i] !== oldData[i]) {
      if (i === "ttp_points" || i === "ttp_defaultepcoef") {
        params[i] = Number(data[i]);
      } else {
        params[i] = data[i];
      }
    }
  }

  try {
    await api.editTaskType(params.ttp_id, params);
    openCustomToast("You have successfully changed the task type", "success");
    if (props.options.update) {
      props.options.update();
    }
    modalStore.setModalData(null);
  } catch (error: any) {
    openCustomToast("An error occurred while changing the task type", "error");
  }
}

function setOldData(propData: any) {
  for (let i in propData) {
    if (i !== "deletable" && i !== "editable") {
      data[i] = propData[i];
      oldData[i] = propData[i];
    }
  }
}

function isThereChanges() {
  let changes = false;
  for (let i in data) {
    if (data[i] !== oldData[i]) {
      changes = true;
    }
  }
  return changes;
}
</script>
<style lang="scss">
.inputs-wrapper.task-type {
  margin-bottom: 25px;
  .input-wrapper {
    margin-bottom: 15px;
  }
}
</style>
