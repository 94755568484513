<template>
  <div class="input-wrapper">
    <div class="header-wrapper">
      <div class="label">
        {{ label }}
      </div>
      <!-- <div v-if="errorMessage && !focused && error" class="error">
        {{ errorMessage }}
      </div>
      <div v-if="successMessage && !focused && success" class="success">
        {{ successMessage }}
      </div> -->
    </div>
    <div
      :class="{
        focused: focused,
        disabled: disabled,
        error: !focused && error,
        success: !focused && success,
        padding: type === 'time' || type === 'datetime-local',
        textarea: type === 'textarea',
      }"
      class="input-outer px-5"
      :style="{ height: costumHeight ? costumHeight : '' }"
      @click="focusInput()"
    >
      <div
        v-if="color && !focused"
        class="task-color"
        :style="{
          background: `radial-gradient(50% 50% at 46.88% 50%, ${color} 0%, rgba(221, 53, 204, 0) 100%)`,
        }"
      ></div>
      <textarea
        v-if="type === 'textarea'"
        ref="basicInput"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="modelValue"
        :min="min"
        :max="max"
        :step="step"
        style="padding-left: 20px"
        @focus="changeFocused($event)"
        @blur="changeFocused($event)"
        @input="emitChange($event)"
      ></textarea>
      <input
        v-else
        ref="basicInput"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :value="modelValue"
        :name="name"
        :min="min"
        :max="max"
        :step="step"
        :style="{
          height: costumHeight ? costumHeight : '',
          'font-weight': fontWeight ? fontWeight : '',
          'font-size': fontSize ? fontSize : '',
        }"
        @focus="changeFocused($event)"
        @blur="changeFocused($event)"
        @input="emitChange($event)"
      />
      <img v-if="icon" :draggable="false" class="icon" :src="icon" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "BasicInput",
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    regex: {
      type: String,
      required: false,
      default: null,
    },
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
    successMessage: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      required: false,
      default: "text",
    },
    name: {
      required: false,
      default: "",
    },
    min: {
      required: false,
      default: null,
    },
    max: {
      required: false,
      default: null,
    },
    step: {
      required: false,
      default: null,
    },
    costumHeight: {
      required: false,
      default: null,
    },
    fontSize: {
      require: false,
      default: null,
    },
    fontWeight: {
      require: false,
      default: null,
    },
    color: {
      require: false,
      default: null,
    },
    setFocus: {
      require: false,
      default: false,
    },
  },
  emits: ["update:modelValue", "isFocused"],
  setup(props, { emit, refs }) {
    const focused = ref(false);
    function changeFocused(e) {
      focused.value = e.target === document.activeElement;
      emit("isFocused", focused.value);
    }

    const basicInput = ref();
    function focusInput() {
      basicInput.value.focus();
    }

    const error = ref(false);
    const success = ref(false);
    function testRegex(text) {
      const reg = new RegExp(props.regex);
      if (reg.test(text)) {
        error.value = false;
        success.value = true;
      } else {
        success.value = false;
        error.value = true;
      }
    }

    function emitChange(e) {
      emit("update:modelValue", e.target.value);
      if (props.regex) {
        testRegex(e.target.value);
      }
    }

    onMounted(() => {
      if (props.setFocus) {
        focusInput();
      }
    });

    return {
      focused,
      error,
      success,
      basicInput,
      changeFocused,
      focusInput,
      emitChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--label-color);

  .header-wrapper {
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input-outer {
    // filter: drop-shadow(0px 1px 3px #dfdfe8);
    border: var(--border-input);
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    transition-duration: var(--vt-c-transition-duration);
    cursor: text;
    position: relative;

    .task-color {
      position: absolute;
      top: -3px;
      left: 11px;
      width: 100px;
      height: 5px;
      z-index: 1;
    }

    input {
      font-family: Archivio, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      border: none;
      // background: transparent;
      background: var(--background-input);
      // font-size: 14px;
      // line-height: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;

      border-radius: 11px;
      outline: none;
      width: 100%;
      height: 100%;
      color: var(--color-text-modal);
    }

    input:focus {
      border: none;
    }

    .icon {
      cursor: default;
      max-width: 20px;
      max-height: 20px;
      user-select: none;
    }
  }

  .textarea.input-outer {
    height: 200px;

    textarea {
      border: none;
      // background: transparent;
      background: var(--background-input);
      border-radius: 11px;
      font-size: 14px;
      line-height: 20px;
      outline: none;
      width: 100%;
      height: 100%;
      resize: none;
      padding: 10px 16px !important;
      font-family: Archivio, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      color: var(--color-text-modal) !important;
    }
  }
  input::placeholder,
  .textarea::placeholder {
    color: var(--placeholder-color);
  }

  .input-outer.padding {
    padding: 0 12px;
    box-sizing: border-box;
  }

  .input-outer.focused {
    border: 1px solid var(--vt-c-active);
  }

  .input-outer.disabled {
    cursor: default;
    // background-color: rgb(223 223 232 / 30%);
    background: var(--background-input-disabled);
    border: 1px solid var(--link-border-default-color);
    border-radius: 10px;
    input {
      color: var(--placeholder-color);
    }
  }

  // .input-outer.error {
  //   border: 1px solid $error-color;
  //   background-color: lighten($error-color, 35%);
  // }
  // .input-outer.success {
  //   border: 1px solid $success-color;
  //   background-color: lighten($success-color, 35%);
  // }
  // .error {
  //   font-style: italic;
  //   font-weight: normal;
  //   font-size: 12px;
  //   line-height: 14px;
  //   text-align: right;
  //   color: $error-color;
  // }
  // .success {
  //   font-style: italic;
  //   font-weight: normal;
  //   font-size: 12px;
  //   line-height: 14px;
  //   text-align: right;
  //   color: $success-color;
  // }
}
</style>
