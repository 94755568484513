<template>
  <div :class="{ 'list-wrapper': true, odd: odd }">
    <div
      v-for="(item, index) in props.data"
      :key="index"
      class="list-item"
      :class="{
        active: listKey && active ? item[listKey] === active[listKey] : false,
      }"
      @click="emit('click', item)"
    >
      <div class="span">{{ item[props.label] }}</div>
      <div v-if="item.deletable || item.editable" class="actions">
        <img
          v-if="item.editable"
          src="@/assets/icons/table-edit.svg"
          alt=""
          tabindex="0"
          @keyup.enter="emit('edit', item)"
          @click="emit('edit', item)"
        />
        <img
          v-if="item.deletable"
          src="@/assets/icons/table-delete.svg"
          alt=""
          tabindex="0"
          @keyup.enter="emit('delete', item)"
          @click="emit('delete', item)"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
type ListItem = {
  editable?: boolean;
  deletable?: boolean;
  [k: string]: any;
};

const props = defineProps<{
  data: ListItem[];
  label: string;
  listKey?: string;
  active?: any;
  odd?: boolean;
}>();
const emit = defineEmits(["edit", "delete", "click"]);
</script>
<style lang="scss" scoped>
.list-wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 340px;

  .list-item {
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    padding: 0 48px 0 51px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-right: 20px;
    cursor: pointer;

    .span {
      display: block;
      white-space: nowrap;
      width: calc(100% - 38px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .actions {
      img {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .list-item.active {
    // background-color: #1f2c45;
    // background: linear-gradient(
    //   90deg,
    //   #191922 64.06%,
    //   rgba(25, 25, 34, 0) 100%
    // );
    background: var(--background-row);
    border-radius: 10px;
  }
}
.list-wrapper.odd {
  .list-item:nth-child(odd) {
    // background: #2e2e32;
    // background: linear-gradient(
    //   90deg,
    //   #191922 64.06%,
    //   rgba(25, 25, 34, 0) 100%
    // );
    background: var(--background-row);
    border-radius: 10px;
  }
}
</style>
