<template>
  <div
    v-if="props.company?.img_id"
    class="avatar image"
    :style="{
      backgroundImage: `url(${baseURL + '/images/' + props.company.img_id})`,
      backgroundColor: getStyles.backgroundColor,
      borderColor: getStyles.borderColor,
    }"
  ></div>
  <div v-else class="avatar name" :style="getStyles">
    {{ `${props.company?.cmp_name.substring(0, 1).toUpperCase()}` }}
  </div>
</template>
<script setup lang="ts">
import { type Company } from "@/assets/js/types";
// import { colorMap } from "@/assets/js/values";
import { baseURL } from "@/api/config";
import { newShade } from "@/assets/js/getColorShade";

type AvatarProps = {
  company?: Company | null;
  star?: boolean;
};
const props = defineProps<AvatarProps>();
// const defaultBackground = "#8451C4";
// const defaultBorderColor = "#CEAFF3";

const getStyles = computed(() => {
  return {
    backgroundColor: "#658cd8",
    borderColor: newShade("#658CD8", 100),
  };
});
</script>
<style lang="scss" scoped>
.avatar {
  position: relative;
  margin-right: 13px;

  .status {
    position: absolute;
    bottom: -2px;
    right: -2px;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: green;
    box-sizing: border-box;
    border: 1px solid white;
  }
}

.avatar.name {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  box-sizing: border-box;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: var(--color-text-button);
  cursor: default;
}

.avatar.image {
  height: 38px;
  width: 38px;
  border-radius: 18px;
  box-sizing: border-box;
  // border: 2px solid white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
