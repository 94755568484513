<template>
  <div class="roles-page-wrapper">
    <div class="left">
      <basic-search
        v-model="search"
        style="margin-bottom: 34px"
        :placeholder="'Search...'"
        :width="'340px'"
        :widthInput="'301px'"
        @update:model-value="getRoles"
      />
      <basic-button
        v-if="checkPermission(['roles-add'])"
        :label="'New role'"
        :image="'plus'"
        :imageDisabled="plus"
        style="white-space: nowrap; margin-bottom: 34px"
        @click="setupNewRole"
      />
      <basic-list
        :listKey="'rol_id'"
        :data="listRoles"
        :label="'rol_name'"
        :active="activeRole"
        @delete="openConfirmModal"
        @click="getRoleDetails"
      />
    </div>
    <div class="right">
      <h3>{{ getTitle }}</h3>
      <basic-input
        v-model="name"
        :disabled="!checkPermission(['roles-add'])"
        label="Role name"
        style="margin-bottom: 30px; width: 340px"
      />
      <span class="label">Privileges</span>
      <div class="privileges-wrapper">
        <div v-for="privilege in privileges" :key="privilege.prv_id">
          <basic-checkbox
            :label="privilege.prv_name"
            :modelValue="selectedPrivileges.find((el: Privilege) => el.prv_id === privilege.prv_id) ? true : false"
            @update:model-value="toglePrivilege(privilege)"
          />
        </div>
      </div>
      <basic-button
        v-if="checkPermission(['roles-add'])"
        :label="'Save'"
        style="white-space: nowrap; margin-top: 30px; width: 340px"
        @click="save"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import plus from "@/components/elements/table/plus.svg";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { type Modal, type Role, type Privilege } from "@/assets/js/types";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const roles = ref<Role[]>([]);
const search = ref<string | null>(null);
const privileges = ref<Privilege[]>([]);
const name = ref<string | null>(null);
const selectedPrivileges = ref<Privilege[]>([]);
const activeRole = ref<Role | null>(null);
const oldPrivileges = ref<Privilege[]>([]);

onMounted(() => {
  getRoles();
  getPrivileges();
});

watch(
  () => companyStore.activeCompany,
  () => {
    getRoles();
    getPrivileges();
  }
);

const getTitle = computed(() => {
  if (activeRole.value) {
    return `Edit role "${activeRole.value?.rol_name}"`;
  } else {
    return "Create new role";
  }
});

const listRoles = computed(() => {
  return roles.value.map((el) => {
    const res: any = el;
    if (checkPermission(["roles-add"])) {
      res.deletable = true;
    }

    return res;
  });
});

async function getRoleDetails(role: Role) {
  try {
    const res = await api.getRoleDetails(role.rol_id);
    activeRole.value = res.data.role;
    name.value = res.data.role.rol_name;
    selectedPrivileges.value = JSON.parse(
      JSON.stringify(res.data.role.privileges)
    );
    oldPrivileges.value = JSON.parse(JSON.stringify(res.data.role.privileges));
  } catch (error) {
    console.log(error);
  }
}

async function getPrivileges() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.getPrivileges(params);
    privileges.value = res.data.privileges;
  } catch (error) {
    console.log(error);
  }
}

async function getRoles() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    const res = await api.getRoles(params);
    roles.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}

function openConfirmModal(role: Role) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete role?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Role: <strong>{{role.rol_name}} </strong><br>`,
        data() {
          return { role };
        },
      },
      confirm: () => {
        deleteRole(role);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteRole(role: Role) {
  try {
    await api.deleteRole(role.rol_id);
    setupNewRole();
    openCustomToast("You have successfully deleted the roll.", "success");
  } catch (error) {
    openCustomToast("An error occurred while deleting the roll.", "error");
  } finally {
    getRoles();
  }
}

function toglePrivilege(privilege: Privilege) {
  console.log(
    selectedPrivileges.value.find(
      (el: Privilege) => el.prv_id === privilege.prv_id
    )
  );
  if (
    selectedPrivileges.value.find(
      (el: Privilege) => el.prv_id === privilege.prv_id
    )
  ) {
    const newArr = selectedPrivileges.value.filter(
      (el: Privilege) => el.prv_id !== privilege.prv_id
    );
    selectedPrivileges.value = newArr;
  } else {
    selectedPrivileges.value.push(privilege);
  }
}

function setupNewRole() {
  activeRole.value = null;
  selectedPrivileges.value = [];
  name.value = null;
  oldPrivileges.value = [];
}

function calcPrivileges(params: any) {
  const delete_privileges = oldPrivileges.value.filter(
    (privilege: Privilege) =>
      selectedPrivileges.value.find(
        (el: Privilege) => el.prv_id === privilege.prv_id
      ) === undefined
  );
  const add_privileges = selectedPrivileges.value.filter(
    (privilege: Privilege) =>
      oldPrivileges.value.find(
        (el: Privilege) => el.prv_id === privilege.prv_id
      ) === undefined
  );

  if (delete_privileges.length > 0) {
    params.delete_privileges = JSON.stringify(
      delete_privileges.map((el: any) => el.prv_id)
    );
  }
  if (add_privileges.length > 0) {
    params.add_privileges = JSON.stringify(
      add_privileges.map((el: any) => el.prv_id)
    );
  }

  return params;
}

async function save() {
  if (activeRole.value) {
    let params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      rol_name: name.value,
      rol_id: activeRole.value.rol_id,
    };
    params = await calcPrivileges(params);
    try {
      await api.editRole(params.rol_id, params);
      getRoleDetails(activeRole.value);
      openCustomToast("You have successfully changed the roll.", "success");
    } catch (error) {
      openCustomToast("An error occurred while changing the roll.", "error");
    } finally {
      getRoles();
    }
  } else {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      rol_name: name.value,
      privileges: JSON.stringify(
        selectedPrivileges.value.map((el: Privilege) => el.prv_id)
      ),
    };
    try {
      await api.createRole(params);
      setupNewRole();
      openCustomToast("You have successfully added a new roll.", "success");
    } catch (error) {
      openCustomToast("An error occurred while creating the role.", "error");
    } finally {
      getRoles();
    }
  }
}
</script>
<style lang="scss" scoped>
.roles-page-wrapper {
  padding-top: 55px;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  .right {
    margin-left: 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin-bottom: 20px;
      margin-top: 0px;
    }

    h3::first-letter {
      text-transform: capitalize;
    }

    .label {
      font-size: 14px;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  .left {
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
<style lang="scss">
.privileges-wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  width: 100%;
  max-height: 400px;

  .container {
    height: auto;
    margin-bottom: 20px;
    margin-right: 20px;

    .label {
      text-align: left;
    }
  }

  .container:hover {
    background: none;
  }
}
</style>
