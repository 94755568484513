<script setup lang="ts">
import type { User } from "@/assets/js/types";

const emit = defineEmits(["update:modelValue", "search"]);
const props = withDefaults(
  defineProps<{
    users: User[];
    modelValue: User[];
    maxHeight?: number;
    title?: string;
    edit?: boolean;
    changable?: boolean;
  }>(),
  { maxHeight: 300, edit: false, title: "", changable: true }
);

const search = ref<string>("");
const edit = ref<boolean>(props.edit);
const selectable_users = ref<{ user: User; selected: boolean }[]>([]);

watch(
  [() => props.users, edit],
  () => {
    selectable_users.value = props.modelValue.map((user) => {
      return { user, selected: true };
    });
    if (edit.value) {
      selectable_users.value = selectable_users.value.concat(
        props.users
          .filter(
            (user) =>
              selectable_users.value.findIndex(
                (el) => el.user.usr_id === user.usr_id
              ) === -1
          )
          .map((user) => {
            return { user, selected: false };
          })
      );
    }
  },
  { immediate: true }
);

watchEffect(() => {
  emit("search", search.value);
});

function handleChange() {
  emit(
    "update:modelValue",
    selectable_users.value.filter((el) => el.selected).map((el) => el.user)
  );
}

function changeEdit() {
  search.value = "";
  edit.value = !edit.value;
}
</script>
<template lang="pug">
.chooser-view-wrap
  .title
    label
      span {{ title }}
      img(
        v-if="!edit && changable",
        src="@/assets/icons/edit.png",
        alt="edit image",
        @click="changeEdit"
      )
      img(
        v-else-if="changable",
        src="@/assets/icons/close.png",
        alt="close image",
        @click="changeEdit"
      )
  basic-input.search-input(
    v-if="edit",
    v-model="search",
    :type="'text'",
    placeholder="Search"
  )
  .people-wrap.scrollbar(:style="{ maxHeight: maxHeight + 'px' }")
    template(v-for="user in selectable_users", :key="user.user.id")
      advanced-checkox(
        v-model="user.selected",
        :show-input="edit",
        @change="handleChange"
      )
        template(#custom-label)
          avatar-image.user-picture(:user="user.user")
          span.user-name {{ user.user.usr_displayname }}
</template>
<style lang="scss" scoped>
@import "@/assets/css/scroll";

.title {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  width: fit-content;
  color: var(--label-color);

  label {
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
  }

  img {
    position: absolute;
    left: calc(100% + 7px);
    top: -4px;
  }
}

.people-wrap {
  overflow: auto;
  max-height: 220px !important;
}

.search-input {
  margin-bottom: 10px;
}

.user-picture {
  margin-right: 13px;

  &:nth-child(3) {
    margin-left: 13px;
  }
}

.user-name {
  font-size: 1rem;
  color: var(--color-text-modal);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 158px;
}
</style>
