<template>
  <section class="page-wrapper projects-list-page">
    <div v-if="checkPermission(['projects-view'])" class="page">
      <div class="header">
        <basic-search
          v-model="search"
          placeholder="Search"
          style="align-self: flex-end"
          @update:model-value="onSearch"
        />
        <basic-button
          v-if="checkPermission(['projects-add'])"
          :label="'Project'"
          :image="'plus'"
          :imageDisabled="plus"
          style="white-space: nowrap; margin-left: 10px"
          @click="openCreateModal"
        />
        <basic-checkbox
          v-if="checkPermission(['projects-add'])"
          v-model="showLocked"
          label="Show locked projects"
        />
      </div>
      <table-wrapper
        :data="projects"
        :options="options"
        :sortTo="sortTo"
        @row-click="openProject"
        @search="onSearch"
        @delete-row="openConfirmModal"
        @edit="openEditModal"
        @set-lock="lock"
        @sort="sort"
      />
      <pagination-one
        v-if="count > 0"
        v-model:page="page"
        :perPage="perPage"
        :offset="offset"
        :count="count"
        prevText="Prev"
        nextText="Next"
        style="margin-top: auto"
        @page-changed="onChange"
      />
    </div>
    <div v-else class="page">
      <h1 style="align-self: flex-start">No privileges</h1>
    </div>
  </section>
</template>

<script setup lang="ts">
import plus from "@/components/elements/table/plus.svg";
import { useRouter } from "vue-router";
import { ProjectStore } from "@/store/projectStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import {
  checkPermission,
  debounce,
  openCustomToast,
} from "@/assets/js/helpers";
import { type Modal, type Task } from "@/assets/js/types";
import dayjs from "dayjs";
import { usePagination } from "@/assets/js/composables";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const router = useRouter();
const projectStore = ProjectStore();
const showLocked = ref(false);
const projects = ref<Task[]>([]);
const search = ref<string | null>(null);
const { count, offset, onChange, resetPagination, page, perPage } =
  usePagination(getProjects);

type SortObj = {
  name: string;
  sort: string;
};
const sortTo = ref<SortObj | null>(null);

onMounted(() => {
  getProjects();
});

// watch(
//   () => companyStore.activeCompany,
//   () => {
//     getProjects();
//   }
// );

watch(
  [companyStore.activeCompany, showLocked],
  ([store, locked], [oldstore, oldLocked]) => {
    if (store !== oldstore) {
      getProjects();
    } else if (locked !== oldLocked) {
      resetPagination();
      // getProjects(1);
    }
    // else if (locked !== oldLocked && !locked) {
    //   resetPagination();
    //   // getProjects();
    // }
  }
);

async function lock(params) {
  try {
    await api.editProject(params);
    resetPagination();
  } catch (error) {
    console.log(error);
  }
}

async function getProjects(locked?: number) {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      offset: offset.value - 1,
      limit: perPage.value,
    };
    // if (locked) {
    //   params.locked = locked;
    // }
    if (showLocked.value) {
      params.locked = 1;
    }
    if (search.value && search.value !== "") {
      params.search = search.value;
    }

    if (sortTo.value) {
      // params.sort = JSON.stringify({
      //   name: sortTo.value.name,
      //   to: sortTo.value.sort,
      // });
      params.sort = {
        name: sortTo.value.name,
        to: sortTo.value.sort,
      };
    }

    const res = await api.getProjects(params);
    projects.value = res.data.data;
    count.value = res.data.meta_info.count;
  } catch (error) {
    console.log(error);
  }
}

function sort(e) {
  //  ASC|DESC
  console.log(e);
  //  sortTo.value = {name:'sort_'+e, sort: 'ASC'}
  if (sortTo.value === null) {
    sortTo.value = { name: e, sort: "ASC" };
  } else if (sortTo.value !== null && sortTo.value.name !== e) {
    sortTo.value = { name: e, sort: "ASC" };
  } else if (sortTo.value.name === e) {
    sortTo.value = {
      name: e,
      sort: sortTo.value.sort === "ASC" ? "DESC" : "ASC",
    };
  }
  // getProjects();
  resetPagination();
}

const onSearch = debounce(
  function () {
    resetPagination();
  },
  500,
  false
);

interface OptionsType {
  title: string;
  createLabel: string;
  noData: string;
  uniqueProperty: string;
  columns: {
    key: string;
    type: string;
    label: string;
    sort?: boolean;
  }[];
  contextOptions?: {
    name: string;
    to_do: string;
  }[];
}
const options = ref<OptionsType>({
  title: "",
  createLabel: "New project",
  noData: "Trenutno nema projekata",
  uniqueProperty: "tsk_id",
  columns: [
    {
      key: "tsk_name",
      type: "title",
      label: "Project",
      sort: true,
    },
    {
      key: "cli_name",
      type: "title",
      label: "Client",
      sort: true,
    },
    {
      key: "tsk_due",
      type: "date",
      label: "Due date",
      sort: true,
    },
    {
      key: "progress",
      type: "progress",
      label: "Progress",
    },
    {
      key: "hours",
      type: "hours",
      label: "Hours",
    },
    {
      key: "assigned_users",
      type: "avatar",
      label: "Assigned",
    },
    {
      key: "owner",
      type: "created-by",
      label: "Created",
      // sort: true,
    },
  ],
});

if (checkPermission(["projects-add"])) {
  options.value.columns.push({
    key: "",
    type: "edit",
    label: "",
  });
  options.value.columns.push({
    key: "",
    type: "delete",
    label: "",
  });
  options.value.contextOptions = [
    { name: "Lock/unlock project", to_do: "lock" },
    // { name: "Create task", to_do: "create_task" },
    // { name: "Archive task", to_do: "archive" },
  ];
}

function openProject(project: Task): void {
  if (project.tsk_locked !== null) {
    return;
  }
  projectStore.setSelectedProjectId(project.tsk_id);
  router.push({
    name: "MyProjectTasks",
    params: { projectId: project.tsk_id },
  });
}

async function update(data: any) {
  const params: any = {
    tsk_name: data.tsk_name,
    cli_id: data.client.cli_id,
  };
  if (data.tsk_due) {
    params.tsk_due = dayjs(data.tsk_due).utc().format();
  }
  if (data.assign && data.assign.length > 0) {
    params.assigned = JSON.stringify(
      data.assign.map((user: any) => user.usr_id)
    );
  }
  if (data.tsk_color) {
    params.tsk_color = data.tsk_color;
  }
  try {
    await api.createProject(params);
    openCustomToast("You have successfully created a new project.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while creating the project.", "error");
  } finally {
    getProjects();
  }
}

function openCreateModal() {
  const data: any = {
    title: "New project",
    component: "newProjectModal",
    options: {
      update: (data: any) => {
        update(data);
      },
    },
  };
  modalStore.setModalData(data);
}

function openConfirmModal(project: Task) {
  if (project.tsk_locked !== null) {
    return;
  }
  const data: Modal = {
    component: "confirmModal",
    title: "Delete project?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `
          Project: <strong>{{project.tsk_name}}</strong><br>
          Client: <strong>{{project.cli_name}}</strong><br>
       `,
        data() {
          return { project };
        },
      },
      confirm: () => {
        deleteProject(project);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteProject(project: Task) {
  try {
    const params: any = {
      tsk_id: project.tsk_id,
    };
    await api.deleteProject(params);
    openCustomToast("You have successfully deleted the project.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while deleting the project.", "error");
  } finally {
    getProjects();
  }
}

function openEditModal(project: Task) {
  if (project.tsk_locked !== null) {
    return;
  }
  const data: Modal = {
    component: "editProjectModal",
    title: "Edit project",
    options: {
      data: project,
      save: () => {
        getProjects();
      },
    },
  };
  modalStore.setModalData(data);
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  transition: 0.3s;
  height: var(--vt-c-page-height);
  max-width: 1744px;
  margin: 0 auto;

  .page {
    width: 100%;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin-bottom: 44px;
    }
  }
}
</style>
<style lang="scss">
.page-wrapper {
  .header > .container {
    margin-left: 20px;

    .label {
      padding-left: 30px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #555f76;
    }
  }

  .header > .container:hover {
    background: initial;
    border-radius: 6px;
  }
}
.projects-list-page {
  .page {
    .table-outer-wrapper {
      .table-wrapper {
        .table-row {
          .table-data:first-of-type {
            padding-left: 8px;
          }
        }
      }
    }
  }
}
</style>
