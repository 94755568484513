<template>
  <div class="inputs-wrapper">
    <basic-input
      v-model="name"
      type="text"
      label="Hour type name"
      :setFocus="true"
      @keyup.enter="saveChanges"
    />
  </div>
  <div class="inputs-wrapper">
    <basic-button label="Create hour type" @click="saveChanges" />
  </div>
</template>
<script setup lang="ts">
import { ModalStore } from "../../store/modalStore";
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const name = ref<string | null>(null);
const props = defineProps(["options"]);

async function saveChanges() {
  if (!name.value) {
    openCustomToast("The 'Hour type name' field is required", "error");
    return;
  }
  const params: any = {
    tst_name: name.value,
    cmp_id: companyStore.getActiveCompany?.cmp_id,
  };
  try {
    await api.createHourType(params);
    openCustomToast("You have successfully created a hour type", "success");
    if (props.options.update) {
      props.options.update();
    }
    modalStore.setModalData(null);
  } catch (error: any) {
    openCustomToast("An error occurred while creating the hour type", "error");
  }
}
</script>
<style lang="scss" scoped>
.inputs-wrapper {
  margin-bottom: 15px;
}
</style>
