<template>
  <div class="pagination-one-wrapper">
    <div
      :class="{ disabled: isPrevDisabled }"
      class="prev-wrapper mr-2"
      @click="navigatePrev()"
    >
      <img v-if="isPrevDisabled" class="mr-2" :src="LeftArrowDisabled" alt="" />
      <img v-else class="mr-2" :src="LeftArrow" alt="" />
      <div v-if="prevText" class="prev-text">
        {{ prevText }}
      </div>
    </div>
    <div
      v-for="(arrpage, index) in getPages"
      :key="index"
      class="page"
      :class="{ active: arrpage.page === page, dots: !arrpage.page }"
      @click="emitChange(arrpage.page)"
    >
      {{ arrpage.page ? arrpage.page : "..." }}
    </div>
    <div
      :class="{ disabled: isNextDisabled }"
      class="next-wrapper"
      @click="navigateNext()"
    >
      <div v-if="nextText" class="next-text">
        {{ nextText }}
      </div>
      <img
        v-if="isNextDisabled"
        class="ml-2"
        :src="RightArrowDisabled"
        alt="aaa"
      />
      <img v-else class="ml-2" :src="RightArrow" alt="bbb" />
    </div>
  </div>
</template>

<script>
import leftArrowDisabled from "./leftArrowDisabled.png";
import leftArrow from "./leftArrow.png";
import rightArrowDisabled from "./rightArrowDisabled.png";
import rightArrow from "./rightArrow.png";
import { ref, computed, onMounted } from "vue";
export default {
  name: "PaginationOne",
  props: {
    page: {
      required: true,
      type: Number,
    },
    count: {
      required: false,
      type: Number,
      default: null,
    },
    offset: {
      required: false,
      type: Number,
      default: 1,
    },
    perPage: {
      required: false,
      type: Number,
      default: 10,
    },
    pages: {
      required: false,
      type: Number,
      default: null,
    },
    prevIcon: {
      required: false,
      default: null,
    },
    prevText: {
      required: false,
      default: null,
    },
    nextIcon: {
      required: false,
      default: null,
    },
    nextText: {
      required: false,
      default: null,
    },
  },
  emits: ["update:page", "pageChanged"],
  setup(props, { emit }) {
    const LeftArrowDisabled = ref(leftArrowDisabled);
    const LeftArrow = ref(leftArrow);
    const RightArrowDisabled = ref(rightArrowDisabled);
    const RightArrow = ref(rightArrow);
    const getPages = computed(() => {
      let pages = null;
      const res = [];
      if (props.pages) {
        pages = props.pages;
      } else if (props.count && props.perPage) {
        pages = Math.ceil(props.count / props.perPage);
      }
      if (pages < 7) {
        for (let i = 1; i <= pages; i++) {
          res.push({ page: i });
        }
        return res;
      } else if (props.page < 4) {
        for (let i = 1; i <= 5; i++) {
          res.push({ page: i });
        }
        res.push({});
        res.push({ page: pages });
        return res;
      } else if (props.page > pages - 3) {
        res.push({ page: 1 });
        res.push({});
        for (let i = pages - 4; i <= pages; i++) {
          res.push({ page: i });
        }
        return res;
      } else {
        res.push({ page: 1 });
        res.push({});
        for (let i = props.page - 1; i <= props.page + 1; i++) {
          res.push({ page: i });
        }
        res.push({});
        res.push({ page: pages });
        return res;
      }
    });

    onMounted(() => {
      if (props.page === 0) {
        const page = Math.ceil(props.offset / props.perPage);
        emit("update:page", page);
      }
    });

    const emitChange = (page) => {
      if (!page) {
        return;
      }
      emit("update:page", page);
      const data = {
        page,
      };
      if (props.count && props.offset && props.perPage) {
        data.count = props.count;
        data.perPage = props.perPage;
        data.offset = props.perPage * (page - 1) + 1;
      }
      emit("pageChanged", data);
    };

    const navigatePrev = () => {
      if (props.page - 1 >= 1) {
        emit("update:page", props.page - 1);
        const data = {
          page: props.page - 1,
        };
        if (props.count && props.offset && props.perPage) {
          data.count = props.count;
          data.perPage = props.perPage;
          data.offset = props.perPage * (props.page - 1 - 1) + 1;
        }
        emit("pageChanged", data);
      }
    };
    const navigateNext = () => {
      let pages = null;
      if (props.pages) {
        pages = props.pages;
      } else if (props.count && props.perPage) {
        pages = Math.ceil(props.count / props.perPage);
      }
      if (props.page + 1 <= pages) {
        emit("update:page", props.page + 1);
        const data = {
          page: props.page + 1,
        };
        if (props.count && props.offset && props.perPage) {
          data.count = props.count;
          data.perPage = props.perPage;
          data.offset = props.perPage * props.page + 1;
        }
        emit("pageChanged", data);
      }
    };

    const isPrevDisabled = computed(() => {
      if (props.page - 1 >= 1) {
        return false;
      }
      return true;
    });

    const isNextDisabled = computed(() => {
      let pages = null;
      if (props.pages) {
        pages = props.pages;
      } else if (props.count && props.perPage) {
        pages = Math.ceil(props.count / props.perPage);
      }
      if (props.page + 1 <= pages) {
        return false;
      }
      return true;
    });

    return {
      getPages,
      emitChange,
      LeftArrow,
      LeftArrowDisabled,
      RightArrow,
      RightArrowDisabled,
      navigatePrev,
      navigateNext,
      isPrevDisabled,
      isNextDisabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination-one-wrapper {
  display: flex;
  flex-direction: row;

  .next-wrapper,
  .prev-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: var(--color-text);
    user-select: none;
  }

  .disabled {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #434446;
  }

  .page {
    height: 28px;
    width: 28px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--color-text);
    transition-duration: 0.3s;
    cursor: pointer;
  }

  .page.dots {
    cursor: default;
  }

  .page.active {
    color: var(--vt-c-active);
    background: var(--background-active-pagination);
    border-radius: 7px;
  }

  .page:hover {
    color: var(--vt-c-active);
  }

  .page.dots:hover {
    color: #39383c;
  }

  .page:last-child {
    margin-right: 0;
  }
}

.mr-2 {
  margin-right: 8px;
}

.ml-2 {
  margin-left: 8px;
}
</style>
