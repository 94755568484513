/**
 *
 * @param getFunction Function that is used to fetch new dataset.
 * @param perpage Number of elements on one page (default 12).
 */
export function usePagination(getFunction: Function, perpage: number = 12) {
  const page = ref(0);
  const offset = ref(1);
  const count = ref(0);
  const perPage = ref(perpage);

  function onChange(data: any, fn?: Function) {
    if (data.offset) {
      offset.value = data.offset;
    }
    if (fn) {
      fn();
    } else {
      getFunction();
    }
  }

  function resetPagination(fn?: Function) {
    offset.value = 1;
    page.value = 1;
    if (fn) {
      fn();
    } else {
      getFunction();
    }
  }

  return { page, offset, count, perPage, onChange, resetPagination };
}
