import { createRouter, createWebHistory } from "vue-router";
import MyTasks from "../views/MyTasks.vue";
import MyProjectsList from "../views/MyProjectsList.vue";
import MyProjectTasks from "../views/MyProjectTasks.vue";
import MySettings from "../views/MySettings.vue";
import AuthPage from "../views/Auth/AuthPage.vue";
import LoginPage from "../views/Auth/Sub/LoginPage.vue";
import RegisterPage from "../views/Auth/Sub/RegisterPage.vue";
import ValidatePage from "../views/Auth/Sub/ValidatePage.vue";
import NewPasswordPage from "../views/Auth/Sub/NewPassword.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ForgotPasswordPage from "../views/Auth/Sub/ForgotPassword.vue";
import MyHours from "../views/MyHours.vue";
import MyCoworkers from "../views/MyCoworkers.vue";
import { getJwtData } from "@/assets/js/helpers";
import { UserStore } from "@/store/userStore";

const routes = [
  {
    path: "/",
    name: "Home",
    component: MyTasks,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/my-coworkers",
    name: "MyCoworkers",
    component: MyCoworkers,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/",
    name: "MyTasks",
    component: MyTasks,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/:taskId",
    name: "MyTask",
    component: MyTasks,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/:taskId/:section",
    name: "MyTaskSection",
    component: MyTasks,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/projects",
    name: "MyProjectsList",
    component: MyProjectsList,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/projects/:projectId",
    name: "MyProjectTasks",
    component: MyProjectTasks,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/projects/:projectId/:taskId",
    name: "MyProjectTask",
    component: MyProjectTasks,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/projects/:projectId/:taskId/:section",
    name: "MyProjectTaskSection",
    component: MyProjectTasks,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/:companyId/settings",
    name: "MySettings",
    component: MySettings,
    meta: {
      jwtRequired: true,
    },
  },

  {
    path: "/:companyId/my-hours",
    name: "MyHours",
    component: MyHours,
    meta: {
      jwtRequired: true,
    },
  },
  {
    path: "/auth",
    name: "AuthPage",
    component: AuthPage,
    redirect: { name: "LoginPage" },
    children: [
      {
        path: "login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "register",
        name: "RegisterPage",
        component: RegisterPage,
      },
      {
        path: "forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "new-password",
        name: "NewPasswordPage",
        component: NewPasswordPage,
      },
      {
        path: "validate",
        name: "ValidatePage",
        component: ValidatePage,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = UserStore();
  let isJwtTokenValid = false;
  const getToken = async () => {
    isJwtTokenValid = await getJwtData();

    if (to.meta.jwtRequired) {
      if (isJwtTokenValid) {
        if (!userStore.getUser) {
          // userStore.getUserData();
        }
        return next();
      } else {
        userStore.setNavigate(to.fullPath);

        return next("/auth/login");
      }
    } // check is user logedin and stopp him to go on authorization page
    else if (
      !to.meta.jwtRequired &&
      to.path.startsWith("/auth") &&
      isJwtTokenValid
    ) {
      return next(from.fullPath);
    } else {
      return next();
    }
  };
  getToken();
});

export default router;
