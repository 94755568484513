<template>
  <button
    class="btn-wrapper-two"
    :class="{ disabled: !token || token === '' }"
    :disabled="!token || token === ''"
    @click="token && token !== '' ? emit('send') : ''"
  >
    {{ token && token !== "" ? props.btnTxt : props.disabledTxt }}
  </button>
  <div ref="turnstile" class="turnstile"></div>
</template>

<script lang="ts" setup>
const turnstileSrc = "https://challenges.cloudflare.com/turnstile/v0/api.js";
const turnstileLoadFunction = "cfTurnstileOnLoad";

declare global {
  interface Window {
    turnstile: any;
    [turnstileLoadFunction]: any;
  }
}

declare interface VueTurnstileData {
  resetTimeout?: ReturnType<typeof setTimeout>;
  widgetId?: string;
}

let turnstileState =
  typeof window !== "undefined"
    ? window.turnstile !== undefined
      ? "ready"
      : "unloaded"
    : "unloaded";
let turnstileLoad: {
  resolve: (value?: unknown) => void;
  reject: (value?: unknown) => void;
};

type TTurnStile = {
  modelValue: string;
  siteKey?: string; // Generated from the dashboard
  resetInterval?: number;
  size?: "normal" | "compact";
  theme?: "light" | "dark" | "auto";
  language?: string;
  action?: string;
  appearance?: "always" | "execute" | "interaction-only";
  renderOnMount?: boolean;
  resetToken?: boolean;
  btnTxt?: string;
  disabledTxt?: string;
};

const props = withDefaults(defineProps<TTurnStile>(), {
  modelValue: "",
  siteKey: "0x4AAAAAAAVu7MupHESPFu5y",
  resetInterval: 295_000, // Get a fresh token after reset-interval milliseconds, Turnstile tokens only last for 5 minute
  theme: "light",
  size: "normal",
  language: "auto",
  action: "", // A customer value that can be used to differentiate widgets under the same sitekey in analytics and which is returned upon validation.
  appearance: "always", // Appearance controls when the widget is visible, only if not set "invisible" in the widget type in dashboard
  renderOnMount: true,
  resetToken: false, // serves to reset the token when it is used, as soon as the value is changed, regardless of the value of the property, the token is changed
  btnTxt: "Send",
  disabledTxt: "Initialization...",
});
const emit = defineEmits(["update:modelValue", "error", "unsupported", "send"]);

const data = ref<VueTurnstileData>({
  resetTimeout: undefined,
  widgetId: undefined,
});

const turnstile = ref();
const token = ref<any>();

const turnstileOptions = computed(() => {
  return {
    sitekey: props.siteKey,
    theme: props.theme,
    language: props.language,
    size: props.size,
    callback: callback,
    action: props.action,
    appearance: props.appearance,
    "error-callback": errorCallback,
    "unsupported-callback": unsupportedCallback,
  };
});

function unsupportedCallback() {
  emit("unsupported");
}

function errorCallback(code: string) {
  emit("error", code);
}

function callback(tokenE: string) {
  token.value = tokenE;
  emit("update:modelValue", tokenE);
  startResetTimeout();
}

function reset() {
  if (window.turnstile) {
    token.value = "";
    emit("update:modelValue", "");
    window.turnstile.reset();
  }
}

function remove() {
  if (data.value.widgetId) {
    window.turnstile.remove(data.value.widgetId);
    data.value.widgetId = undefined;
  }
}

function render() {
  token.value = "";
  data.value.widgetId = window.turnstile.render(
    turnstile.value,
    turnstileOptions.value
  );
}

function startResetTimeout() {
  data.value.resetTimeout = setTimeout(() => {
    reset();
  }, props.resetInterval);
}
watch(
  () => props.resetToken,
  () => {
    // reset();
    render();
  }
);

onMounted(async () => {
  const turnstileLoadPromise = new Promise((resolve, reject) => {
    turnstileLoad = { resolve, reject };
    if (turnstileState === "ready") resolve(undefined);
  });

  window[turnstileLoadFunction] = () => {
    turnstileLoad.resolve();
    turnstileState = "ready";
  };

  const ensureTurnstile = () => {
    if (turnstileState === "unloaded") {
      turnstileState = "loading";
      const url = `${turnstileSrc}?onload=${turnstileLoadFunction}&render=explicit`;
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.addEventListener("load", function () {
        // on refresh page
        render();
      });
      script.addEventListener("error", () => {
        turnstileLoad.reject("Failed to load Turnstile.");
      });
      document.head.appendChild(script);
    }
    return turnstileLoadPromise;
  };

  await ensureTurnstile();

  if (props.renderOnMount) {
    render();
  }
});
onBeforeUnmount(() => {
  remove();
  clearTimeout(data.value.resetTimeout);
});
</script>
<style lang="scss" scoped>
.btn-wrapper-two {
  cursor: pointer;
  // background-color: var(--vt-c-active);
  // background: linear-gradient(
  //   180deg,
  //   #2c75f4 0%,
  //   rgba(44, 117, 244, 0.78) 53.02%,
  //   rgba(44, 117, 244, 0.6) 100%
  // );
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  flex-grow: 0;
  border-radius: 8px;
  align-self: flex-start;
  transition-duration: var(--vt-c-transition-duration);
  // color: var(--text-color);
  color: var(--color-text-button);
  // font-weight: 600;
  // font-size: 12px;
  // line-height: 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  padding: 0 14px 0 20px;
  width: 100%;
  background: var(--active-color-modal);
  border: transparent;
  // color: var(--vt-c-black);
  // color: var(--color-text-button);
  color: var(--color-background);
}

.btn-wrapper-two.inv {
  background: transparent;
  // background: rgba(70, 73, 81, 0.1);
  color: var(--vt-c-active);
  border: 2px solid var(--vt-c-active);
}
.modal.inv {
  background-color: transparent !important;
  background: var(--button-background-color-transparent) !important;
  // border: 1.5px solid var(--active-color-modal);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: var(--active-color-modal);
  border: 2px solid var(--active-color-modal);
}

.disabled,
.disabled:hover,
.disabled:active {
  background: rgb(70 73 81 / 10%);
  color: var(--placeholder-color);
  cursor: initial;
}
</style>
