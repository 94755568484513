import { Estimation } from "@/assets/js/types";
import api from "@/api";

export const EstimationsStore = defineStore("EstimationsStore", () => {
  const estimations = ref<Estimation[]>([]);
  const lastTaskId = ref<number | null>(null);

  async function getEstimations(id: number) {
    lastTaskId.value = id;
    try {
      const params: any = {
        tsk_id: id,
      };
      const res = await api.getEstimations(params);
      estimations.value = res.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function refresh() {
    if (lastTaskId.value) {
      getEstimations(lastTaskId.value);
    }
  }

  return {
    estimations,
    getEstimations,
    refresh,
  };
});
