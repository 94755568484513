<template>
  <div class="wrapper profileModal">
    <div style="width: 65%" class="left">
      <!-- <div v-for="data in userData" :key="data.label" class="input-helper">
        <basic-input
          v-model="data.value"
          :type="data.type === 'password' ? 'password' : 'text'"
          :label="data.label"
          :placeholder="data.label"
          :disabled="data.disabled"
          :color="data.label === 'Frist Name' ? color : null"
        />
      </div> -->
      <div class="input-helper split">
        <basic-input
          v-model="userData.name.value"
          :type="userData.name.type === 'password' ? 'password' : 'text'"
          :label="userData.name.label"
          :placeholder="userData.name.label"
          :disabled="userData.name.disabled"
          :color="userData.name.label === 'Frist Name' ? color : null"
        />
        <basic-input
          v-model="userData.lastName.value"
          :type="userData.lastName.type === 'password' ? 'password' : 'text'"
          :label="userData.lastName.label"
          :placeholder="userData.lastName.label"
          :disabled="userData.lastName.disabled"
          :color="userData.lastName.label === 'Frist Name' ? color : null"
        />
      </div>
      <div class="input-helper">
        <basic-input
          v-model="userData.displayName.value"
          :type="userData.displayName.type === 'password' ? 'password' : 'text'"
          :label="userData.displayName.label"
          :placeholder="userData.displayName.label"
          :disabled="userData.displayName.disabled"
          :color="userData.displayName.label === 'Frist Name' ? color : null"
        />
      </div>
      <div class="input-helper">
        <basic-input
          v-model="userData.email.value"
          :type="userData.email.type === 'password' ? 'password' : 'text'"
          :label="userData.email.label"
          :placeholder="userData.email.label"
          :disabled="userData.email.disabled"
          :color="userData.email.label === 'Frist Name' ? color : null"
        />
      </div>
      <div class="input-helper">
        <basic-input
          v-model="phone.value"
          :type="phone.type === 'password' ? 'password' : 'text'"
          :label="phone.label"
          :placeholder="phone.label"
          :disabled="phone.disabled"
        />
      </div>
    </div>
    <div class="right">
      <div class="input-helper">
        <div class="input-wrapper">
          <div class="title">Image</div>
          <div
            class="image"
            :style="{
              backgroundImage: `url(${image})`,
              backgroundColor: color,
              borderColor: newShade(color),
            }"
          >
            <!-- backgroundColor: colorMap[color].backgroundColor, -->
            <!-- borderColor: colorMap[color].borderColor, -->
            <div class="edit-wrapper">
              <label class="edit" for="edit">
                <img src="@/assets/images/editImage.svg" alt="" />
              </label>
              <input
                id="edit"
                type="file"
                accept=".jpg, .png, .jpeg"
                @change="setImage($event)"
              />
              <img
                v-if="image"
                src="@/assets/icons/close.png"
                @click="deleteImage"
              />
            </div>
            <span v-if="!image">
              {{
                `${userData.displayName.value?.charAt(0)}${
                  userData.displayName.value ? "." : ""
                }`
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="input-helper" style="margin-bottom: 19px">
        <div class="input-wrapper">
          <SelectColor
            :old="color"
            :numberOfcolor="4"
            :profile="true"
            @color="setColor"
          ></SelectColor>
          <!-- <div class="title">Color</div>
          <div class="options-wrapper">
            <div
              v-for="(c, key) in colorMap"
              :key="key"
              class="option"
              :style="{
                backgroundColor: c.backgroundColor,
                borderColor: c.borderColor,
              }"
              @click="setColor(key)"
            ></div>
          </div> -->
        </div>
      </div>
      <div class="input-helper" style="max-width: 155px">
        <div class="input-wrapper">
          <!-- <basic-input
            v-model="displayName.value"
            :type="displayName.type === 'password' ? 'password' : 'text'"
            :label="displayName.label"
            :placeholder="displayName.label"
            :disabled="displayName.disabled"
          /> -->
        </div>
      </div>
    </div>
  </div>
  <div class="btn-wrapper">
    <basic-button
      :inv="true"
      :modal="true"
      :label="'Reset password'"
      style="margin-right: 20px"
      @click="resetPassword"
    />
    <basic-button :label="'Save'" :modal="true" @click="saveChanges" />
  </div>
</template>
<script setup lang="ts">
import { UserStore } from "@/store/userStore";
import { colorMap } from "@/assets/js/values";
import { type UserColor } from "@/assets/js/types";
import { openCustomToast } from "@/assets/js/helpers";
import { transl } from "@/i18n";
import api from "@/api";
import { baseURL } from "@/api/config";
import { ModalStore } from "@/store/modalStore";
import { newShade } from "@/assets/js/getColorShade";

const modalStore = ModalStore();
const emit = defineEmits(["close"]);
defineProps(["options"]);
const userStore = UserStore();
const userData = reactive<any>({
  name: {
    value: userStore.getUser?.name || "",
    label: "Frist Name",
    type: "text",
    disabled: false,
  },
  lastName: {
    value: userStore.getUser?.lastName || "",
    label: "Last Name",
    type: "text",
    disabled: false,
  },

  displayName: {
    value: userStore.getUser?.usr_displayname || null,
    label: "Display name",
    type: "text",
    disabled: false,
  },
  email: {
    value: userStore.getUser?.email,
    label: "Email",
    type: "email",
    disabled: true,
  },
  // phone: {
  //   value: userStore.getUser?.phone || "",
  //   label: "Phone number",
  //   type: "tel",
  //   disabled: false,
  // },
});
// const displayName = ref({
//   value: userStore.getUser?.usr_displayname || null,
//   label: "Display name",
//   type: "text",
//   disabled: false,
// });
const phone = ref({
  value: userStore.getUser?.phone || "",
  label: "Phone number",
  type: "tel",
  disabled: false,
});
const image = ref<any>(
  userStore.getUser?.img_id
    ? baseURL + "/images/" + userStore.getUser?.img_id
    : null
);
const color = ref<string>(userStore.getUser?.usr_color || "purple");
const newImage = ref<File | null>(null);

async function setImage(event: Event) {
  const el = event.target as HTMLInputElement;
  if (el.files && el.files[0]) {
    newImage.value = el.files[0];
    image.value = await toBase64(el.files[0]);
  }
}

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// function setColor(c: UserColor): void {
//   color.value = c;
// }
function setColor(e: string) {
  color.value = e;
}

function deleteImage(): void {
  if (image && image.value) image.value = null;
}

async function saveChanges() {
  const params: any = {};
  if (image.value === null && userStore.getUser?.img_id !== null) {
    params.usr_img = null;
  }
  if (newImage.value) {
    params.usr_img = newImage.value;
  }
  if (color.value !== userStore.getUser?.usr_color) {
    params.usr_color = color.value;
  }
  if (userData.name.value !== userStore.getUser?.name) {
    params.name = userData.name.value;
  }
  if (userData.lastName.value !== userStore.getUser?.lastName) {
    params.lastname = userData.lastName.value;
  }
  if (userData.displayName.value !== userStore.getUser?.usr_displayname) {
    params.usr_displayname = userData.displayName.value;
  }
  // if (userData.phone.value !== userStore.getUser?.phone) {
  //   params.phone = userData.phone.value;
  // }
  if (phone.value.value !== userStore.getUser?.phone) {
    params.phone = phone.value.value;
  }
  // if (displayName.value.value !== userStore.getUser?.usr_displayname) {
  //   params.usr_displayname = displayName.value.value;
  // }
  if (Object.keys(params).length === 0) {
    openCustomToast(transl(`error.edit-profile.nothing-changed`), "error");
    return;
  }

  const form_data = new FormData();
  for (const key in params) {
    form_data.append(key, params[key]);
  }

  try {
    await api.editProfile(form_data);
    userStore.getUserData();
    openCustomToast("Changes saved", "success");
    emit("close");
  } catch (error: any) {
    const errData: any = error.response.data;
    if (errData.validation && errData.name) {
      openCustomToast(
        transl(`error.edit-profile.${errData.validation}.${errData.name}`),
        "error"
      );
    } else if (errData.message) {
      openCustomToast(transl(`error.edit-profile.${errData.message}`), "error");
    }
  }
}

async function resetPassword() {
  emit("close");
  const modal: any = {
    title: "Reset password",
    component: "resetPasswordModal",
  };
  setTimeout(() => {
    modalStore.setModalData(modal);
  }, 300);
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 650px;

  .right {
    margin-left: 40px;

    .input-wrapper {
      .title {
        margin-bottom: 6px;
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
        color: var(--label-color);
      }

      .image {
        // width: 105px;
        // height: 105px;
        width: 150px;
        height: 150px;
        position: relative;
        border: 2px solid #434446;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        margin-top: 20px;
        margin-bottom: 19px;
        .edit-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 20px;
          line-height: 20px;

          img {
            cursor: pointer;
            margin-left: 5px;
          }

          #edit {
            display: none;
          }
        }

        span {
          text-transform: uppercase;
          color: var(--color-text-button);
        }
      }
    }
  }
}

.input-helper {
  margin-bottom: 15px;

  .options-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .option {
      height: 22px;
      width: 22px;
      border: 2px solid transparent;
      border-radius: 11px;
      box-sizing: border-box;
      margin-right: 9px;
      cursor: pointer;
    }
  }
}
.split2,
.split {
  display: flex;
  justify-content: space-between;
}

.btn-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 20px;
}
@media screen and (max-width: 660px) {
  .wrapper {
    display: initial;
  }
}
</style>
<style lang="scss">
.profileModal {
  .split {
    .input-wrapper {
      width: 48%;
    }
  }
  .split2 {
    .input-wrapper:first-of-type {
      width: 65%;
    }
    .input-wrapper:last-of-type {
      width: 30%;
    }
  }
}
</style>
