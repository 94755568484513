<template>
  <div class="types-page-wrapper">
    <basic-search
      v-model="search"
      style="margin-bottom: 34px"
      :placeholder="'Search...'"
      :width="'340px'"
      :widthInput="'301px'"
      @update:model-value="getHourTypes"
    />
    <basic-button
      v-if="checkPermission(['timesheet-types-add'])"
      :label="'New hour type'"
      :image="'plus'"
      :imageDisabled="plus"
      style="white-space: nowrap; margin-bottom: 34px"
      @click="openCreateModal"
    />
    <basic-list
      :data="listHourTypes"
      :label="'tst_name'"
      style="max-height: calc(100vh - 370px)"
      :odd="true"
      @edit="openEditModal"
      @delete="openConfirmModal"
    />
  </div>
</template>
<script setup lang="ts">
import plus from "@/components/elements/table/plus.svg";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { type Modal, type HourType } from "@/assets/js/types";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const hourTypes = ref<HourType[]>([]);
const search = ref<string | null>(null);

const listHourTypes = computed(() => {
  return hourTypes.value.map((el) => {
    const res: any = el;

    if (checkPermission(["timesheet-types-add"])) {
      res.editable = true;
      res.deletable = true;
    }

    return res;
  });
});

onMounted(() => {
  getHourTypes();
});

watch(
  () => companyStore.activeCompany,
  () => {
    getHourTypes();
  }
);

async function getHourTypes() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    const res = await api.getHourTypes(params);
    hourTypes.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}

function openConfirmModal(taskType: HourType) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete hour type?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Hour type: <strong>{{taskType.tst_name}} </strong><br>`,
      },
      confirm: () => {
        deleteTaskType(taskType);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteTaskType(hourType: HourType) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      tst_id: hourType.tst_id,
    };
    await api.deleteHourType(params.tst_id, params);
    openCustomToast("You have successfully deleted the hour type.", "success");
  } catch (error) {
    openCustomToast(
      "An error occurred while deleting this hour type.",
      "error"
    );
  } finally {
    getHourTypes();
  }
}

function openEditModal(hourType: HourType) {
  const data: Modal = {
    component: "editHourTypeModal",
    title: "Edit hour type",
    options: {
      data: hourType,
      update: () => {
        getHourTypes();
      },
    },
  };
  modalStore.setModalData(data);
}

function openCreateModal() {
  const modal: any = {
    title: "Add hour type",
    component: "newHourTypeModal",
    options: {
      update: () => {
        getHourTypes();
      },
    },
  };
  modalStore.setModalData(modal);
}
</script>
<style lang="scss" scoped>
.types-page-wrapper {
  padding-top: 55px;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
