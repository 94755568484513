<template>
  <div class="wrapp-client-modal">
    <div class="wrap-main-flex">
      <div class="input-wrapper-wrp">
        <basic-input
          v-model="params.name"
          placeholder="Client"
          label="Client"
          fontWeight="500"
          :color="defaultInputColor"
          :setFocus="true"
        />
        <div class="wrapNum">
          <basic-input
            v-model="params.regnumber"
            placeholder="Reg. number"
            label="Registration number (MB)"
          />

          <basic-input
            v-model="params.taxnumber"
            placeholder="Tax number"
            label="Tax number (PIB)"
          />
        </div>
      </div>
      <!-- <basic-input
          v-model="params.name"
          placeholder="Client"
          label="Client"
          costumHeight="77px"
          fontSize="30px"
          fontWeight="500"
        /> -->

      <image-add
        labelText="Logo"
        costumHeight="108px"
        @deleteimg="deleteImg"
        @setimg="setImg"
      />
    </div>
    <!--ADDERSS-->
    <div class="input-wrapper">
      <basic-input
        v-model="params.cli_address"
        placeholder="Address"
        label="Address"
        :color="defaultInputColor"
      />
    </div>

    <div class="wrap-flex">
      <div class="content3">
        <basic-input
          v-model="params.cli_city"
          placeholder="City"
          label="City"
        />

        <basic-input
          v-model="params.postal_code"
          placeholder="Postal code"
          label="Postal code"
        />

        <div class="input-wrapper">
          <basic-dropdown
            class="types"
            :list="countries"
            :selected="params.cli_country"
            :searchable="true"
            :labelKey="'cnt_name'"
            :title="'Country'"
            @selected="setCountry"
          />
        </div>
      </div>

      <div class="content">
        <div class="wrappHalf">
          <basic-input
            v-model="params.contactname"
            placeholder="Contact name"
            label="Contact name"
            :color="defaultInputColor"
          />

          <basic-input
            v-model="params.contactphone"
            placeholder="Contact phone"
            label="Contact phone"
          />
        </div>

        <basic-input
          v-model="params.contactemail"
          placeholder="Contact email"
          label="Contact email"
        />
      </div>
    </div>
    <basic-button :modal="true" label=" Save " @click="addClient" />
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { openCustomToast } from "@/assets/js/helpers";

defineEmits(["close"]);
const props = defineProps(["options"]);
const companyStore = CompanyStore();
const modalStore = ModalStore();
const defaultInputColor = "#5190FF";

// params:
const params = reactive<any>({
  name: null,
  contactname: null,
  contactphone: null,
  contactemail: null,
  country: null,
  taxnumber: null,
  regnumber: null,
  cmp_id: companyStore.getActiveCompany?.cmp_id,
  cli_country: null,
  cli_address: null,
  cli_city: null,
  postal_code: null,
});
const image = ref<any>(null);

function setImg(e) {
  image.value = e;
}

function deleteImg() {
  image.value = null;
}

// get countries
const countries = ref([]);
async function getCountries() {
  try {
    const res = await api.getCountries();
    countries.value = res.data.countries;
  } catch (e) {
    console.log(e);
  }
}
getCountries();
function setCountry(e) {
  console.log(e);
  params.cli_country = e;
}

async function addClient() {
  if (params.name) {
    if (params.cli_country) {
      params.cnt_code = params.cli_country.cnt_code;
    }

    const form_data = new FormData();
    for (const key in params) {
      if (key !== "cli_country" && params[key] !== null) {
        form_data.append(key, params[key]);
      }
    }
    if (image.value) {
      form_data.append("image", image.value);
    }

    try {
      await api.addClient(form_data);
      if (props.options.update) {
        props.options.update();
      }
      modalStore.setModalData(null);
      openCustomToast("You have successfully added a client.", "success");
    } catch (error) {
      console.log(error);
      openCustomToast("An error occurred while adding the client.", "error");
    }
  } else {
    openCustomToast('The field "Client" is required.', "error");
  }
}
</script>
<style lang="scss" scoped>
.wrapp-client-modal {
  box-sizing: border-box;
  // width: 720px;
  width: 814px;
  padding: 0 12px;
}

.wrap-main-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 77px;
  height: fit-content;
  margin-bottom: 35px;

  .input-wrapper-wrp {
    width: 80%;
    height: 100%;
    .wrapNum {
      display: flex;
      justify-content: space-between;
      .input-wrapper {
        width: 48%;
      }
    }

    .input-outer {
      input {
        height: 108px !important;
      }
    }
  }
}

.wrap-flex {
  .content3,
  .content {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .wrappHalf {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .input-wrapper {
      margin-bottom: 20px !important;
      width: 47%;
    }
  }
  .content3 {
    margin-bottom: 40px;
    .input-wrapper {
      margin-bottom: 20px !important;
      width: 31%;
    }
  }

  .content:first-of-type {
    //
    margin-bottom: 50px;
  }
}
@media screen and (width <= 820px) {
  .wrapp-client-modal {
    width: unset;
    padding: unset;
  }
}
</style>
<style lang="scss">
.wrapp-client-modal {
  .input-wrapper {
    margin-bottom: 15px !important;
  }

  .btn-wrapper-two {
    width: fit-content !important;
    margin-left: auto;
  }
}
</style>
