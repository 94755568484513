<template>
  <div class="wrappAddFile">
    <label
      for="file"
      @drop.prevent="addFile"
      @dragover.prevent
      @click="fileInputOpen"
    >
      <img src="../../assets/icons/addFile.svg" alt="" />
      <div class="txt">
        <strong>Drag &amp; drop</strong> or <strong>add files here</strong>
      </div>
    </label>
    <input
      id="file"
      ref="mainImg"
      type="file"
      accept=".png, .jpg, .jpeg, .pdf"
      style="display: none"
      @change="processFile($event)"
    />

    <p>{{ file?.name }}</p>

    <basic-button
      :label="options.createLabel ? options.createLabel : 'Upload files'"
      :width="'140px'"
      :image="'plus'"
      :imageDisabled="plus"
      style="margin-left: auto; margin-top: 50px"
      :disabled="!file"
      @click="sendFile"
    />
  </div>
</template>

<script setup lang="ts">
import api from "@/api";
import { ModalStore } from "@/store/modalStore";
import { openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";
import { type TaskType } from "@/assets/js/types";

defineEmits(["close"]);
const props = defineProps(["options"]);
const router = useRouter();

const modalStore = ModalStore();
const companyStore = CompanyStore();
const file = ref<any>(null);

/*     tsk_id: router.currentRoute.value.params.taskId
        ? router.currentRoute.value.params.taskId
        : router.currentRoute.value.params.projectId */
async function sendFile() {
  try {
    // const params = new FormData();
    // params.append("cmp_id", companyStore.activeCompany?.cmp_id)
    // params.append("tsk_id", props.options.data.tsk_id)
    // params.append("file", file.value)
    // const params: any = {
    //   cmp_id: companyStore.activeCompany?.cmp_id,
    //   tsk_id: props.options.data.tsk_id,
    //   file: file.value,
    // };

    if (props.options.save) {
      props.options.save();
    }
    modalStore.setModalData(null);
  } catch (error) {
    console.log(error);
    openCustomToast("Error", "error");
  }
}

const showMainImg = ref<any>(null);

async function addFile(e: any) {
  let setFile = getImgByDrop(e);
  if (!getImgByDrop(e)) {
    openCustomToast("Error", "error");
    return;
  } else {
    file.value = setFile;
    // showMainImg.value = await getThumb(
    //     file.value
    // );
  }
}
async function processFile(e: any) {
  if (e.target.files[0]) {
    file.value = e.target.files[0];
    // showMainImg.value = await getThumb(
    //   file.value
    // );
  }
}

const mainImg = ref<any>(null);
function fileInputOpen() {
  mainImg.value.value = null;
}
function fileInputClick() {
  mainImg.value.value = null;
  file.value = null;
}
function deleteFile() {
  fileInputClick();
}

function getImgByDrop(e: any) {
  let droppedFiles = e.dataTransfer.files;
  if (checkIsBadType(droppedFiles[0].type)) {
    openCustomToast("Error type file", "error");
    return false;
  }
  if (!droppedFiles) return;
  return droppedFiles[0];
  // [...droppedFiles].forEach((f) => {
  //   return f;
  // });
}

function getThumb(inputFile: any) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(inputFile);
  });
}

function checkIsBadType(type) {
  return (
    type != "application/pdf" &&
    type != "image/jpeg" &&
    type != "image/png" &&
    type != "image/jpg"
  );
}
</script>

<style lang="scss" scoped>
.wrappAddFile {
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 54px;
    }
    .txt {
      margin-top: 8px;
      font-style: normal;
      font-size: 12px;
      line-height: 13px;
    }
  }
  p {
    // margin-top: 8px;
    text-align: center;
    color: var(--label-color);
    font-size: 0.9em;
  }
}
</style>
