<template>
  <div class="row-wrapper">
    <td colspan="100%" @resize="setupWidth">
      <div class="content-wrapper">
        <span class="title">Description:</span>
        <div class="text">
          {{ row.tms_description }}
        </div>
      </div>
    </td>
  </div>
</template>
<script setup lang="ts">
defineProps(["row"]);
onMounted(() => {
  document
    .querySelector(".row-wrapper td")
    ?.addEventListener("resize", setupWidth);
  setupWidth();
});
onBeforeUnmount(() => {});
function setupWidth() {
  //   let el: any = document.querySelector(".row-wrapper td");
  //   let target: any = document.querySelector(".row-wrapper .content-wrapper");
  //   if (el) {
  //     console.log(el.clientWidth);
  //     target.style.width = el.clientWidth + "px";
  //     target.style.whiteSpace = "nowrap";
  //   }
}
</script>
<style lang="scss" scoped>
.row-wrapper {
  display: table-row-group;
}

.content-wrapper {
  position: relative;
  padding: 15px;

  //   padding-left: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #2e2e32;
  text-overflow: ellipsis;

  .title {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    color: #acacad;
    margin-bottom: 15px;
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
