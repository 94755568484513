import { axios } from "./config.js";

export default {
  // Auth
  login: async (params: any) => axios.post("login", params),
  logout: async () => axios.post("logout"),
  register: async (params: any) => axios.post("signup", params),
  validate: async (token: string) => axios.post("validate", { token }),
  forgotPassword: async (params: any) =>
    axios.get("forgot-password", { params }),
  newPassword: async (params: any) => axios.post("forgot-password", params),
  refreshJWT: async (signal: AbortSignal) =>
    axios.post("refresh-jwt", {}, { signal }),

  // company:
  getUsers: async (params: any) => axios.get("companies/users", { params }),
  searchUsers: async (params: any) =>
    axios.post("companies/users/search", params),
  addUser: async (params: any) => axios.post("companies/users", params),

  // members:
  deleteMember: async (params: any) => axios.post("members/remove", params),
  editMember: async (params: any) => axios.post("members/edit", params),

  // projects:
  getProjects: async (params: any) => axios.post("projects/view", params),
  createProject: async (params: any) => axios.post("projects/add", params),
  deleteProject: async (params: any) => axios.post(`projects/delete`, params),
  editProject: async (params: any) => axios.post("projects/edit", params),

  // tasks:
  getTasks: async (params: any) => axios.post("tasks/view", params),
  getTasksSearch: async (params: any) => axios.post("tasks/search", params),
  createTask: async (params: any) => axios.post("tasks/add", params),
  getTaskDetails: async (params: any, tsk_id: number) =>
    axios.get(`tasks/${tsk_id}`, { params }),
  getTaskHours: async (tsk_id: number) => axios.get(`tasks/${tsk_id}/hours`),
  editTask: async (params: any, tsk_id: number) =>
    axios.put(`tasks/${tsk_id}`, params),
  deleteTask: async (tsk_id: number) => axios.delete(`tasks/${tsk_id}`),
  getParentTree: async (params: any) => axios.post(`tasks/parent-tree`, params),
  transferSubtask: async (params: any) =>
    axios.post("tasks/change-parent", params),

  // task types
  getTaskTypes: async (params: any) => axios.post("tasktypes/view", params),
  createTaskType: async (params: any) => axios.post(`tasktypes/add`, params),

  deleteTaskType: async (id: number, params: any) =>
    axios.delete(`tasktypes/${id}`, { params }), // params.ttp_id
  editTaskType: async (id: number, params: any) =>
    axios.put(`tasktypes/${id}`, params), // params.ttp_id

  // statuses:
  getStatuses: async (params: any) => axios.post("status/view", params),
  createStatus: async (params: any) => axios.post("status/add", params),
  deleteStatus: async (id: number) => axios.delete(`status/${id}`),
  editStatus: async (params: any, id: number) =>
    axios.put(`status/${id}`, params),

  // hour type
  getHourTypes: async (params: any) =>
    axios.post("timesheettypes/view", params),
  createHourType: async (params: any) =>
    axios.post("timesheettypes/add", params),
  deleteHourType: async (id: number, params: any) =>
    axios.delete(`timesheettypes/${id}`, { params }), // params.tst_id
  editHourType: async (id: number, params: any) =>
    axios.put(`timesheettypes/${id}`, params), // params.tst_id

  // hours:
  addHours: async (params: any) => axios.post("timesheets/add", params),
  getHours: async (params: any) => axios.post("timesheets/view", params),
  editHours: async (params: any) => axios.post("timesheets/edit", params),
  deleteHours: async (params: any) => axios.post("timesheets/delete", params),
  transferHours: async (params: any) =>
    axios.post("timesheets/change-task", params),
  // approved hours
  approveHours: async (id: number, params: any) =>
    axios.put(`/users/hours/approve/${id}`, params), // params.tms_id
  approveGetProjects: async (params: any) =>
    axios.get(`users/hours/approve/projects`, { params }),
  approveGetUsers: async (params: any) =>
    axios.get(`users/hours/approve/users`, { params }),
  // approveGetTable: async (params: any) =>
  //   await axios.post("/users/hours/approve", params),
  approveGetTable: async (params: any) => axios.post("/users/hours", params),
  approveGetClients: async (params: any) => axios.post("clients/view", params),
  // estimations:
  getEstimations: async (params: any) => axios.post("estimations/view", params),
  deleteEstimation: async (params: any) =>
    axios.post("estimations/delete", params),
  editEstimation: async (params: any) => axios.post("estimations/edit", params),
  createEstimation: async (params: any) =>
    axios.post("estimations/add", params),
  transferEstimation: async (params: any) =>
    axios.post("estimations/change-task", params),

  // clients:
  getClients: async (params: any) => axios.post("clients/view", params),
  addClient: async (params: any) => axios.post("clients/add", params),
  deleteClient: async (params: any) => axios.post("clients/delete", params),
  editClient: async (params: any) => axios.post("clients/edit", params),

  // User:
  getCompanies: async () => axios.get("users/companies"),
  getUserData: async (params: any) => axios.get(`users/profile`, { params }),
  editProfile: async (params: any) => axios.put(`users/profile`, params),
  fetchPermissions: async (params: any) =>
    axios.get("privileges/byUserByOneCompany", { params }),
  getUserHours: async (params: any) => axios.get("users/hours", { params }),
  getUserTasks: async (params: any) => axios.get("users/tasks", { params }),
  changePassword: async (params: any) =>
    axios.post("users/change-password", params),

  // Messages
  getOlderMessages: async (task_id: number, params: any) =>
    axios.get(`messages/${task_id}`, { params }), // params.task_id

  // Roles
  getRoles: async (params: any) => axios.get("companies/roles", { params }),
  deleteRole: async (rol_id: number) => axios.delete(`roles/${rol_id}`),
  getRoleDetails: async (rol_id: number) => axios.get(`roles/${rol_id}`),
  createRole: async (params: any) => axios.post("roles/add", params),
  editRole: async (rol_id: number, params: any) =>
    axios.put(`roles/${rol_id}`, params),

  // Privileges
  getPrivileges: async (params: any) => axios.post("privileges/get", params),

  // Countries
  getCountries: async () => axios.post("countries/view"),

  // EP
  getEP: async (params: any) => axios.get(`users/ep`, { params }),

  // reports
  getViewReports: async (params: any) => axios.post("reports/view", params),
  getViewReportsID: async (id: any, params: any) =>
    axios.post(`reports/view/${id}`, params),

  copyTasks: async (params: { tasks: number[]; tsk_id_new: number }) =>
    axios.post("tasks/copy", params),
  cutTasks: async (params: { tasks: number[]; tsk_id_new: number }) =>
    axios.post("tasks/cut", params),

  copyHours: async (params: { hours: number[]; tsk_id_new: number }) =>
    axios.post("timesheets/copy", params),
  cutHours: async (params: { hours: number[]; tsk_id_new: number }) =>
    axios.post("timesheets/cut", params),
  // availability status
  getAvailabilityTypes: async (params: { cmp_id: number }) =>
    axios.post("availabilitytypes/view", params),
  getCoworker: async (params: any) =>
    axios.post("availability/view-coworker", params),
  addAvailability: async (params: any) =>
    axios.post("availability/add", params),
  viewAvailbility: async (params: any) =>
    axios.post("availability/view", params),
  deleteAvailbility: async (params: any) =>
    axios.post("availability/delete", params),
  editAvailability: async (params: any) =>
    axios.post("availability/edit", params),
  // availability types
  addAvailabilityTypes: async (params: any) =>
    axios.post("availabilitytypes/add", params),
  deleteAvailabilityTypes: async (params: any) =>
    axios.post("availabilitytypes/delete", params),
  editAvailabilityTypes: async (params: any) =>
    axios.post("availabilitytypes/edit", params),

  //seniority
  getSeniority: (params: any) => axios.post("seniority/view", params),
  getUserSeniority: (params: any) =>
    axios.post("companies/users/" + params.usr_id + "/seniority", params),
  editUserSeniority: (params: any) =>
    axios.put("companies/users/" + params.usr_id + "/seniority", params),
};
