<template>
  <div class="outer-dropdown-wrapper">
    <div v-if="props.title" class="title">{{ props.title }}</div>
    <div
      class="label"
      :class="{ disabled: disabled }"
      tabindex="0"
      @keyup.enter="openDropdown"
      @click="openDropdown"
    >
      <input
        v-if="open && searchable"
        ref="searchInput"
        v-model="search"
        type="text"
        :placeholder="'Search'"
        @click="handleInputClick"
      />
      <span v-else>
        {{ props.selected ? props.selected[props.labelKey] : props.title }}
      </span>
      <img
        v-if="!disabled"
        :class="{ active: open }"
        src="@/components/elements/dropdownTwo/arrow.png"
        alt=""
      />
    </div>
    <div
      v-if="open"
      v-click-outside="
        () => {
          open = false;
        }
      "
      class="dropdown-wrapper"
    >
      <div v-if="list && list.length > 0" class="scrollbar dropdown">
        <div
          v-for="(item, index) in list"
          :key="index"
          tabindex="0"
          :class="{
            active:
              props.selected &&
              item[props.labelKey] === props.selected[props.labelKey],
          }"
          class="item"
          @click="emitSelect(item)"
          @keyup.enter="emitSelect(item)"
        >
          <slot :item="item">
            {{ item[props.labelKey] }}
          </slot>
        </div>
      </div>
      <div v-else class="dropdown empty">nothing here...</div>
    </div>
  </div>
</template>
<script setup lang="ts">
type DropdownProps = {
  selected?: any;
  list?: any[];
  labelKey: string;
  title?: string;
  searchable?: boolean;
  disabled?: boolean;
  focusInput?: boolean;
};
const props = defineProps<DropdownProps>();
const emit = defineEmits(["selected"]);
const open = ref<boolean>(false);
const search = ref<string | null>(null);
const searchInput = ref<any>(null);

const list = computed(() => {
  if (search.value) {
    return props.list?.filter((el) => {
      return el[props.labelKey]
        .toLowerCase()
        .includes(search.value?.toLowerCase());
    });
  } else {
    return props.list;
  }
});
onMounted(() => {
  if (props.focusInput) {
    focusInDropdown();
  }
});
function focusInDropdown() {
  open.value = true;
  searchInput.value?.focus();
}

watch(open, async () => {
  await nextTick();
  if (open.value) {
    searchInput.value?.focus();
  }
});

function openDropdown() {
  if (!props.disabled) {
    open.value = !open.value;
  }
}

function emitSelect(item: any) {
  emit("selected", item);
  search.value = null;
  open.value = false;
}

function handleInputClick(event: any) {
  event.stopPropagation();
  event.preventDefault();
}
</script>
<style lang="scss" scoped>
.outer-dropdown-wrapper {
  position: relative;
  .title {
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
    margin-bottom: 6px;
    color: var(--label-color);
  }
}

.label {
  height: 36px;
  border: var(--border-input);
  background: var(--background-input) !important;
  border-radius: 10px;
  padding: 0 16px;
  box-sizing: border-box;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;

  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: var(--color-text-modal);
  span {
    color: var(--color-text-modal);
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .placeholder {
    color: $text-placeholder;
    font-weight: 300;
  }

  img {
    transition-duration: 0.1s;
    margin-left: 10px;
    transform: rotate(180deg);
  }

  img.active {
    transform: rotate(0deg);
  }

  input {
    // background: transparent !important;
    background: var(--background-input) !important;
    padding-left: 0 !important;
    max-width: calc(100% - 30px);
  }
}
.label:hover {
  border: 1px solid var(--active-color-modal);
}
.label:focus-visible {
  outline: none;
  border: 1px solid var(--label-color);
}
.label.disabled {
  user-select: none;
  cursor: not-allowed;
  // background-color: rgb(223 223 232 / 30%);
  background: var(--background-input-disabled);
}

.dropdown-wrapper {
  z-index: 3;

  .dropdown {
    position: absolute;
    top: calc(100% + 1px);
    background: var(--color-background);
    border: 1px solid var(--vt-c-divider-dark-2);
    border-radius: 10px;
    padding: 7px 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: fit-content;
    overflow: auto;
    max-height: 200px;
    height: fit-content;
    min-height: 100px;
    z-index: 3;

    .item {
      padding: 0 12px;
      width: 100%;
      cursor: pointer;
      border-radius: 4px;
      height: 26px;
      line-height: 26px;
      white-space: nowrap;
      color: var(--color-text-modal);
    }

    .item:hover {
      background: var(--background-profile-header-options-hover);
      color: white;
    }
    .item.active {
      background: var(--background-profile-header-options-hover);
      color: white;
    }
  }

  .empty {
    padding-left: 15px;
  }
}
</style>
