<template>
  <div class="img-add">
    <div class="header-wrapper">
      <div v-if="labelText" class="label">
        {{ labelText }}
      </div>
    </div>

    <label
      :class="{ setImg: true }"
      :style="{ height: costumHeight ? costumHeight : '' }"
      for="image"
      @drop.prevent="addFile"
      @dragover.prevent
      @click="fileInputClick"
    >
      <div v-show="!imageProp && !showMainImg">Click or drop</div>
      <div class="roundPen" @click="fileInputClick">
        <!-- <img src="@/assets/icons/close.png" alt="" /> -->
        <img src="@/assets/images/editImage.svg" alt="" />
      </div>
      <div v-show="imageProp || showMainImg" class="roundX" @click="deleteImg">
        <img src="@/assets/icons/close.png" alt="" />
        <!-- <img src="@/assets/images/editImage.svg" alt="" /> -->
      </div>

      <div v-show="imageProp && !showMainImg" id="setimg">
        <img :src="imageProp" />
      </div>
      <div v-show="showMainImg" id="setimg">
        <img :src="showMainImg ? showMainImg : ''" />
      </div>
    </label>
    <input
      id="image"
      ref="mainImg"
      type="file"
      accept=".png, .jpg, .jpeg"
      style="display: none"
      @change="processFile($event)"
    />
  </div>
</template>

<script setup lang="ts">
defineProps(["imageProp", "labelText", "costumHeight"]);
const emit = defineEmits(["deleteimg", "setimg"]);

const main_image = ref<any>(null);
const showMainImg = ref<any>(null);

async function addFile(e: any) {
  const setImg = getImgByDrop(e);
  if (!getImgByDrop(e)) {
    console.log(e);
  } else {
    main_image.value = setImg;
    emit("setimg", setImg);
    showMainImg.value = await getThumb(main_image.value);
  }
}

async function processFile(e: any) {
  if (e.target.files[0]) {
    main_image.value = e.target.files[0];
    emit("setimg", e.target.files[0]);
    showMainImg.value = await getThumb(e.target.files[0]);
  }
}

const mainImg = ref<any>(null);
function fileInputClick() {
  mainImg.value.value = null;
  main_image.value = null;
  showMainImg.value = null;
}
function deleteImg(e) {
  e.preventDefault();
  emit("deleteimg");
  fileInputClick();
}
// end  set image

// helper fun
function getImgByDrop(e) {
  const droppedFiles = e.dataTransfer.files;
  if (checkIsBadType(droppedFiles[0].type)) {
    return false;
  }
  if (!droppedFiles) return;
  return droppedFiles[0];

  // [...droppedFiles].forEach((f) => {
  //   console.log(f);
  //   return f;
  // });
}
function getThumb(inputFile: any) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(inputFile);
  });
}

function checkIsBadType(type: any) {
  return type != "image/jpeg" && type != "image/png" && type != "image/jpg";
}
</script>

<style lang="scss" scoped>
.img-add {
  max-width: 222px;
  margin: 0;
  padding: 0;

  .header-wrapper {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
  }
}

.setImg {
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  width: 100%;
  aspect-ratio: 1/1;
  background: var(--background-input);
  border: 1px solid var(--vt-c-divider-dark-2);
  border-radius: 10px;
  font-size: 12px;

  color: var(--placeholder-color);

  .roundPen {
    position: absolute;
    top: -3px;
    right: -25px;
    font-size: 18px;
    line-height: 25px;
    z-index: 10;

    img {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .roundX {
    position: absolute;
    top: 25px;
    right: -25px;
    font-size: 18px;
    line-height: 25px;
    z-index: 10;

    img {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  #setimg {
    box-sizing: border-box;
    position: relative;

    //max-width: 200px;
    width: 100%;

    // min-height: 100%;
    aspect-ratio: 1/1;

    img {
      width: 100% !important;
      height: 100%;
      object-fit: cover !important;
      min-width: 100%;
      background-position: center;
    }
  }
}
</style>
