<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { TasksStore } from "../store/tasksStore";
import { UserStore } from "@/store/userStore";
import { checkPermission } from "@/assets/js/helpers";
// import { usePagination } from "@/assets/js/composables";
import { type Member, type Modal } from "@/assets/js/types";
import { ModalStore } from "@/store/modalStore";
import dayjs from "dayjs";

const modalStore = ModalStore();
const users = ref<Member[]>([]);
// const { count, offset, onChange, resetPagination, page, perPage } =
//   usePagination(getUsers, 11);
const companyStore = CompanyStore();
const search = ref<string | null>(null);

onMounted(() => {
  getUsers();
});

const tasksStore = TasksStore();
const userStore = UserStore();
const router = useRouter();
function navigateBack() {
  if (tasksStore.getSelectedTaskId && tasksStore.getSelectedTaskSection) {
    router.push({
      name: "MyTaskSection",
      params: {
        taskId: tasksStore.getSelectedTaskId,
        section: tasksStore.getSelectedTaskSection,
      },
    });
    return;
  }
  if (tasksStore.getSelectedTaskId) {
    router.push({
      name: "MyTask",
      params: {
        taskId: tasksStore.getSelectedTaskId,
      },
    });
    return;
  }
  router.push({
    name: "MyTasks",
    params: { companyId: companyStore.activeCompany?.cmp_id },
  });
}
// end navigation

// table
watch(
  () => companyStore.activeCompany,
  () => {
    // getUsers();
    // resetPagination();
  }
);

async function getUsers() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      // offset: offset.value - 1,
      // limit: perPage.value,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    const res = await api.getCoworker(params);
    users.value = res.data.data;
    // count.value = res.data.meta_info.count;
    await userStore.getUserData();
  } catch (error) {
    console.log(error);
  }
}

function onSearch(data: string) {
  search.value = data;
  // resetPagination();
}

const options = ref({
  title: "",
  searchable: true,
  customSearch: true,
  noData: "There are currently no users",
  uniqueProperty: "id",
  columns: [
    {
      key: "user",
      type: "coworker",
      label: "Korisnik",
    },
    // {
    //   key: "usr_name",
    //   type: "avatar-name",
    //   label: "First name",
    // },
    // {
    //   key: "usr_lastname",
    //   type: "title",
    //   label: "Last name",
    // },
    {
      key: "rol_name",
      type: "title",
      label: "Rola",
    },
    // {
    //   key: "rol_name", // izmeni kljuc
    //   type: "title",
    //   label: "Seniority",
    // },
    {
      key: "avt_name", // izmeni kljuc
      type: "availability",
      label: "Status",
    },
  ],
});

function openModal() {
  const data: Modal = {
    component: "editStatus",
    title: "Change status",
    options: {
      update: () => {
        // set function update
        getUsers();
      },
      data: {
        // set data
      },
    },
  };
  modalStore.setModalData(data);
}
</script>
<template>
  <div class="page-wrapper">
    <div class="navigate-back">
      <img
        src="@/assets/icons/navigateBack.svg"
        alt="navigate back"
        @click="navigateBack"
      />
      <span @click="navigateBack">
        {{ "Back to my tasks" }}
      </span>
    </div>
    <div class="page">
      <div class="wrapp-table-pagination">
        <table-wrapper
          :data="users"
          :options="options"
          upSpaceDown="63px"
          @search="onSearch"
        />
        <!-- <pagination-one
                    v-if="count > 0"
                    v-model:page="page"
                    :perPage="perPage"
                    :offset="offset"
                    :count="count"
                    prevText="Prev"
                    nextText="Next"
                    style="margin-top: auto"
                    @page-changed="onChange"
                    /> -->
      </div>
      <div class="wrapp-actions">
        <div class="day">
          <div class="bold">{{ dayjs.utc().local().format("MMMM DD ") }}</div>
          <div class="day-in-week">
            {{ dayjs.utc().local().format("dddd") }}
          </div>
        </div>
        <div class="my-status">
          <div
            class="status"
            :style="{
              color: userStore.getUser?.status?.avt_color
                ? '#' + userStore.getUser?.status?.avt_color
                : '',
            }"
          >
            <!--#51C38D-->
            {{
              userStore.getUser?.status?.avt_name
                ? userStore.getUser?.status?.avt_name
                : "Working"
            }}
          </div>
          <div class="txt">
            If you wish to change your status, you can do so by clicking the
            button below.
          </div>
          <basic-button
            label="Change status"
            :width="'140px'"
            :disabled="!checkPermission(['availability-add'])"
            @click="openModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page-wrapper {
  // display: flex;
  // flex-direction: row;
  // align-items: stretch;
  box-sizing: border-box !important;
  transition: 0.3s;
  min-height: var(--vt-c-page-height);
  max-width: 1744px;
  margin: 0 auto;
  padding: 20px 40px 20px 20px;

  .navigate-back {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 20px;

    span {
      color: var(--vt-c-active);
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      margin-left: 13px;
      cursor: pointer;
    }

    img {
      height: 20px;
      cursor: pointer;
    }
  }
  .page {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .wrapp-table-pagination {
      display: flex;
      max-width: 60%;
      flex-direction: column;
      align-items: center;
    }

    .wrapp-actions {
      min-width: 40%;
      .day {
        color: red;
        display: flex;
        align-items: flex-end;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid var(--link-border-default-color);
        margin-bottom: 30px;
        .bold {
          font-family: Archivo;
          font-size: 20px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--vt-c-text-dark-2);
          margin-right: 10px;
        }
        .day-in-week {
          font-family: Lato;
          font-size: 14px;
          font-weight: 800;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: center;
          color: var(--vt-c-active);
        }
      }
      .my-status {
        .status {
          font-family: Archivo;
          font-size: 15px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }
        .txt {
          font-family: Archivo;
          font-size: 13px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--label-color);
          margin-top: 4px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .page-wrapper {
    .page {
      flex-direction: column-reverse;
      .wrapp-table-pagination {
        max-width: 100%;
        margin-bottom: 100px;
      }
      .wrapp-actions {
        margin-top: 30px;
        margin-bottom: 60px;
        max-width: 100%;
      }
    }
  }
}
</style>
