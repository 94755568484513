<template>
  <div
    :style="{ width: width }"
    :class="{
      focused: focused,
      'search-one-result': arrSearch && arrSearch !== [] && focused,
    }"
    class="search-one"
  >
    <!-- @click="emitClick" -->
    <img :src="image ? image : defaultImage" />
    <form autocomplete="off" @keypress.enter="emitClick">
      <input
        :placeholder="placeholder"
        :value="modelValue"
        autocomplete="off"
        :style="{ width: widthInput }"
        @input="emitChange($event)"
        @focus="changeFocused($event)"
        @blur="changeFocused($event)"
      />
    </form>
  </div>
</template>

<script>
import defaultImage from "./Search.svg";
import { ref } from "vue";
export default {
  name: "SearchOne",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: String,
      required: false,
      default: "auto",
    },
    widthInput: {
      type: String,
      required: false,
      default: "",
      // must be 39px smaller from parent width
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      // the key by which a search is performed in a array of objects
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ["update:modelValue", "clicked"],
  setup(props, { emit, refs }) {
    const arrSearch = ref(null);

    const focused = ref(false);
    function changeFocused(e) {
      if (e.target === document.activeElement)
        focused.value = e.target === document.activeElement;
      else {
        setTimeout(() => {
          focused.value = e.target === document.activeElement;
        }, 200);
      }
    }

    //  search for change of value - first search
    function emitChange(e) {
      emit("update:modelValue", e.target.value);

      if (!props.options) {
        return;
      }

      if (e.target.value) {
        //  this search props.label is the key by which a search is performed in a array of objects

        if (props.label) {
          arrSearch.value = props.options.filter((item) => {
            return e.target.value
              .toLowerCase()
              .split(" ")
              .every((v) => item[props.label].toLowerCase().includes(v));
          });
        } else {
          arrSearch.value = props.options.filter((item) => {
            return e.target.value
              .toLowerCase()
              .split(" ")
              .every((v) => item.toLowerCase().includes(v));
          });
        }
      } else {
        arrSearch.value = props.options;
      }
    }

    function clicked(e) {
      emit("clicked", e);
      emit("update:modelValue", e[props.label]);
      arrSearch.value = null;
    }

    function emitClick(e) {
      e.preventDefault();
    }

    return {
      emitClick,
      emitChange,
      changeFocused,
      focused,
      clicked,
      arrSearch,
      defaultImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100px;
  flex-grow: 0;
  align-self: flex-start;
  transition-duration: var(--vt-c-transition-duration);
  // color: var(--color-background);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  height: 36px;
  border-radius: 10px;
  position: relative;
  padding: 0 16px;
  background: var(--background-input) !important;

  input {
    background: transparent;
    width: calc(100% - 23px);
    height: 100%;
    border: none;
    outline: none;
    font-size: 15px;
    line-height: 18px;
    box-sizing: border-box;
    // color: var(--color-text);
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: var(--color-text-modal);
  }

  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: var(--placeholder-color);
  }

  img {
    margin-right: 10px;
  }
}
.search-one:hover {
  border: 1px solid var(--active-color-modal);
}

.disabled,
.disabled.focused,
.disabled:active {
  background: rgb(70 73 81 / 10%);
  color: #9498a2;
  cursor: initial;
}
</style>
