<template>
  <div ref="wrapper" class="dropdown-two-wrapper" :style="dropdownStyle">
    <div v-if="title" class="title">{{ title }}</div>
    <div v-if="slots['customLabel']" @click="dropdown = !dropdown">
      <slot name="customLabel"></slot>
    </div>
    <div
      v-else
      :class="{ disabled: disabled }"
      class="input-wrapper"
      :style="styleInputWrapper"
      @click="dropdown = !dropdown"
    >
      <div
        v-if="modelValue && modelValue.length === 0"
        class="label"
        :style="styleLabel"
      >
        {{ label }}
      </div>
      <!-- <div v-else class="selected scrollbar"> -->
      <div v-else class="selected" :style="styleLabel">
        <div
          v-for="(item, index) in modelValue"
          :key="index"
          class="selected-item"
        >
          <classic-tags
            :tagsData="
              item[dropdownLabel] !== undefined ? item[dropdownLabel] : item
            "
            :stylesTxt="{
              width: '45px',
              display: 'block',
              'white-space': 'nowrap',
              'text-overflow': 'ellipsis',
              overflow: 'hidden',
              color: red,
            }"
            :close="close"
            @click="removeItem(item, $event)"
          />
        </div>
      </div>
      <div class="icon-wrapper">
        <img v-if="icon" :class="{ active: dropdown }" :src="icon" alt="" />
        <img v-else :class="{ active: dropdown }" :src="arrow" alt="" />
      </div>
    </div>
    <div
      v-if="optionsSort.length > 0"
      :class="{ active: dropdown }"
      class="dropdown-wrapper pa-2 box"
    >
      <div class="scrollbar">
        <div
          v-for="(item, index) in optionsSort"
          :key="index"
          class="dropdown-item"
          @click="selectOption(item)"
        >
          <basic-checkbox
            :label="
              item[dropdownLabel] !== undefined ? item[dropdownLabel] : item
            "
            :modelValue="isChecked(item)"
            @click="$event.preventDefault()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import arrow from "./arrow.png";
import close from "./close.png";
export default {
  name: "DropdownTwo",
  props: {
    modelValue: {
      required: false,
      default: [],
    },
    title: {
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: false,
      default: null,
    },
    dropdownLabel: {
      required: false,
      default: null,
    },
    dropdownKey: {
      required: false,
      default: null,
    },
    placeholder: {
      required: false,
      default: "",
    },
    label: {
      required: false,
      default: null,
    },
    icon: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      default: false,
    },
    styleInputWrapper: {
      required: false,
    },
    styleLabel: {
      required: false,
    },
    dropdownStyle: {
      required: false,
    },
  },
  emits: ["update:modelValue", "option-selected"],
  setup(props, { emit, slots }) {
    const dropdown = ref(false);
    const input = ref("");
    const wrapper = ref(null);
    const optionsSort = ref([]);
    // const getSlots = computed(() => {
    //   return slots;
    // });
    function handleClick(event) {
      const isClickInsideWrapper = wrapper.value.contains(event.target);
      if (!isClickInsideWrapper) {
        dropdown.value = false;
      }
    }
    onMounted(() => {
      optionsSort.value = props.options ? props.options : [];
      document.addEventListener("click", handleClick);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClick);
    });
    watch(
      () => dropdown.value,
      () => {
        sortOptions();
      }
    );
    function updateValue(e) {
      emit("update:modelValue", e.target.value);
    }
    function selectOption(option) {
      let res = [];
      if (props.dropdownKey) {
        const el = props.modelValue.find(
          (element) => element[props.dropdownKey] === option[props.dropdownKey]
        );
        if (el) {
          res = props.modelValue.filter(
            (element) =>
              element[props.dropdownKey] !== option[props.dropdownKey]
          );
        } else {
          res = props.modelValue;
          res.push(option);
        }
      } else {
        const el = props.modelValue.find((element) => element === option);
        if (el) {
          res = props.modelValue.filter((element) => element !== option);
        } else {
          res = props.modelValue;
          res.push(option);
        }
      }

      emit("update:modelValue", res);
      emit("option-selected", option);
    }
    function removeItem(item, e) {
      e.stopPropagation();
      const arr = [];
      for (let i = 0; i < props.modelValue.length; i++) {
        if (props.modelValue[i] !== item) {
          arr.push(props.modelValue[i]);
        }
      }
      emit("update:modelValue", arr);
      emit("option-remove", item);
    }
    function isChecked(item) {
      let res = false;
      if (props.dropdownKey) {
        for (let i = 0; i < props.modelValue.length; i++) {
          if (
            props.modelValue[i][props.dropdownKey] === item[props.dropdownKey]
          ) {
            res = true;
          }
        }
      } else {
        for (let i = 0; i < props.modelValue.length; i++) {
          if (props.modelValue[i] === item) {
            res = true;
          }
        }
      }
      return res;
    }

    function sortOptions() {
      optionsSort.value = [];
      for (let i = 0; i < props.options.length; i++) {
        if (isChecked(props.options[i])) {
          // Add to the start of an array if is checked
          optionsSort.value.unshift(props.options[i]);
        } else {
          // Add to the end of an array if is not checked
          optionsSort.value.push(props.options[i]);
        }
      }
    }

    return {
      wrapper,
      input,
      updateValue,
      dropdown,
      arrow,
      close,
      selectOption,
      removeItem,
      isChecked,
      slots,
      optionsSort,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/scroll.scss";
.dropdown-two-wrapper {
  width: 270px;
  position: relative;

  // width: 410px;

  .title {
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .input-wrapper {
    height: 36px;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    border-radius: 11px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    .label {
      font-size: 14px;
      line-height: 15px;
      font-weight: 300;
      color: $text-placeholder;
      padding-left: 13px;
      // min-width: 200px;
      width: 200px;
      padding-right: 13px;
    }

    .selected {
      display: flex;
      flex-direction: row;
      width: 230px;
      padding: 0 8px;
      // overflow: auto;
      overflow: hidden;
      align-items: center;
    }

    .icon-wrapper {
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        transition-duration: 0.1s;
        transform: rotate(180deg);
      }

      img.active {
        transform: rotate(0deg);
      }
    }

    input {
      outline: none;
      padding-left: 22px;
      border: none;
      border-radius: 11px;
      flex-grow: 1;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: var(--color-text);
      // color:var(--color-text-modal);
    }

    input:disabled {
      background: transparent;
    }
  }

  .input-wrapper.disabled {
    cursor: default;
    pointer-events: n;
    user-select: none;
  }

  .dropdown-wrapper {
    position: absolute;
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 11px;
    height: 0;
    transition: 0.1s;
    opacity: 0;
    padding: 0;
    background: var(--color-background);
    z-index: 3;
    overflow: hidden;

    .scrollbar {
      overflow: auto;
      max-height: 160px;
    }
  }

  .dropdown-item {
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    padding: 0 5px;
    box-sizing: border-box;
    border-radius: 7px;
    transition-duration: 0.1s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .container {
    margin: 0 !important;
    height: 27px;
  }

  .dropdown-item:hover {
    // background: #1f2c45;
    background: var(--background-profile-header-options-hover);
  }

  .active.dropdown-wrapper {
    height: auto;
    border: 1px solid var(--color-border);
    opacity: 1;
    margin-top: 4px;
    padding: 8px;
  }
}

.disabled {
  background: #dfdfe84d;
}
</style>
<style lang="scss">
.dropdown-item {
  .container {
    width: 100% !important;
    .label {
      width: 100% !important;
      font-weight: 400;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .checkmark {
      border-radius: 50%;
    }
  }

  .container:hover {
    background: none;
  }
}
</style>
