<template>
  <div class="page-switcher-wrapper">
    <div
      v-for="page in allowedPages"
      :key="page.id"
      :class="{ active: page.id === modelValue?.id }"
      class="link"
      @click="updateModelValue(page)"
    >
      {{ page.label }}
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Page } from "@/assets/js/types";

const props = defineProps<{
  pages: Page[];
  modelValue: Page | null;
}>();

const allowedPages = computed(() => {
  return props.pages.filter((page: Page) => {
    if (page.show === undefined || page.show) {
      return true;
    } else {
      return false;
    }
  });
});

const emit = defineEmits(["update:modelValue"]);

function updateModelValue(page: Page) {
  emit("update:modelValue", page);
}
</script>
<style lang="scss" scoped>
.page-switcher-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .link {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 8px 10px;
    border-bottom: 2px solid var(--link-border-default-color);
    text-align: center;
    cursor: pointer;
    transition-duration: 0.3s;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: var(--switcher-text-color);
  }

  .link.active {
    border-bottom: 2px solid var(--vt-c-active);
    color: var(--vt-c-active);
  }
}

.vertical.page-switcher-wrapper {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;

  .link {
    margin-bottom: 20px;
    border-bottom: 2px solid transparent;
    color: var(--switcher-text-color);
    position: relative;
  }

  // .link.active {
  //   border-bottom: 2px solid var(--vt-c-active);
  //   color: var(--vt-c-active);
  // }

  .link.active::after {
    content: "";
    width: 100%;
    border-bottom: 2px solid var(--vt-c-active);
    position: absolute;
    left: 0;
    bottom: 0px;
    z-index: 0;
    animation-name: line;
    animation-duration: 1s;
  }
  @keyframes line {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
}
</style>
