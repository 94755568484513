<template>
  <div class="clients-page-wrapper">
    <table-wrapper
      :data="clients"
      :options="options"
      upSpaceDown="63px"
      @create="openCreateModal"
      @search="onSearch"
      @delete-row="openConfirmModal"
      @edit="openEditModal"
      @sort="sort"
    />
    <pagination-one
      v-if="count > 0"
      v-model:page="page"
      :perPage="perPage"
      :offset="offset"
      :count="count"
      prevText="Prev"
      nextText="Next"
      style="margin-top: auto"
      @page-changed="onChange"
    />
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { type Modal, type Client } from "@/assets/js/types";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";
import { usePagination } from "@/assets/js/composables";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const clients = ref<Client[]>([]);
const { count, offset, onChange, resetPagination, page, perPage } =
  usePagination(getClients, 11);
const search = ref<string | null>(null);

onMounted(() => {
  getClients();
});

watch(
  () => companyStore.activeCompany,
  () => {
    // getClients();
    resetPagination();
  }
);

type SortObj = {
  name: string;
  sort: string;
};
const sortTo = ref<SortObj | null>(null);

function sort(e) {
  //  ASC|DESC
  console.log(e);
  //  sortTo.value = {name:'sort_'+e, sort: 'ASC'}
  if (sortTo.value === null) {
    sortTo.value = { name: e, sort: "ASC" };
  } else if (sortTo.value !== null && sortTo.value.name !== e) {
    sortTo.value = { name: e, sort: "ASC" };
  } else if (sortTo.value.name === e) {
    sortTo.value = {
      name: e,
      sort: sortTo.value.sort === "ASC" ? "DESC" : "ASC",
    };
  }

  resetPagination();
}

async function getClients() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      offset: offset.value - 1,
      limit: perPage.value,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    if (sortTo.value) {
      // params[sortTo.value.name] = sortTo.value.sort
      params.sort = {
        name: sortTo.value.name,
        to: sortTo.value.sort,
      };
    }
    const res = await api.getClients(params);
    clients.value = res.data.clients;
    count.value = res.data.meta_info.count;
  } catch (error) {
    console.log(error);
  }
}

function openConfirmModal(client: Client) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete client?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Client: <strong>{{client.cli_name}} </strong><br>`,
        data() {
          return { client };
        },
      },
      confirm: () => {
        deleteClient(client);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteClient(client: Client) {
  try {
    const params: any = {
      cli_id: client.cli_id,
    };
    await api.deleteClient(params);
    openCustomToast("You have successfully deleted the client.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while selecting the client.", "error");
  } finally {
    getClients();
  }
}

function openEditModal(client: Client) {
  const data: Modal = {
    component: "editClientModal",
    title: "Edit client",
    options: {
      data: client,
      update: () => {
        getClients();
      },
    },
  };
  modalStore.setModalData(data);
}

function onSearch(data: string) {
  search.value = data;
  resetPagination();
}

const options = ref({
  title: "",
  searchable: true,
  customSearch: true,
  editable: true,
  deletable: true,
  creatable: checkPermission(["clients-add"]),
  noData: "There are currently no clients",
  uniqueProperty: "id",
  columns: [
    {
      key: "cli_name",
      type: "title",
      label: "Client",
      sort: true,
    },
    {
      key: "cli_contactname",
      type: "title",
      label: "Contact",
    },
    {
      key: "cli_contactphone",
      type: "title",
      label: "Phone number",
    },
    {
      key: "cli_contactemail",
      type: "title",
      label: "Email",
    },
    {
      key: "user",
      type: "created-by",
      label: "Created by",
    },
    {
      key: "cli_created",
      type: "date",
      label: "Date created",
    },
  ],
});

if (checkPermission(["clients-add"])) {
  options.value.columns.push({
    key: "",
    type: "edit",
    label: "",
  });
  options.value.columns.push({
    key: "",
    type: "delete",
    label: "",
  });
}

function openCreateModal() {
  const modal: any = {
    title: "Add client",
    component: "newClientModal",
    options: {
      update: () => {
        getClients();
      },
    },
  };
  modalStore.setModalData(modal);
}
</script>
<style lang="scss" scoped>
.clients-page-wrapper {
  box-sizing: border-box;
  padding-top: 55px;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
</style>
