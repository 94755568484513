<template>
  <div class="task-estimate-wrapper">
    <div class="upper-wrapper">
      <!-- <table-wrapper
        :data="topData"
        :options="topOptions"
        style="margin-right: 20px"
      /> -->
      <table>
        <thead>
          <tr>
            <th>DUE DATE</th>
            <th>PROGRESS / HOURS</th>
            <th>STATUS</th>

            <th>CREATED</th>
            <!-- <th style="text-align: center">HOURS</th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="table-cell date">
                <template v-if="topData.tsk_due">
                  <span class="day">{{
                    `${dayjs
                      .utc(topData.tsk_due)
                      .local()
                      .format("DD.MM.YYYY")}.`
                  }}</span>
                  <span class="time">
                    {{
                      `${dayjs.utc(topData.tsk_due).local().format("HH:mm")}`
                    }}
                  </span>
                </template>
                <span v-else></span>
              </div>
            </td>
            <td>
              <div class="table-cell progress">
                <progress-task
                  :data="topData.progress"
                  :hours="hours"
                  :showHours="true"
                />
              </div>
            </td>
            <td>
              <div class="table-cell">
                <basic-dropdown
                  v-if="props.details.editable && props.details.deletable"
                  :list="statuses"
                  :selected="topData.status"
                  :labelKey="'sta_name'"
                  @selected="setStatus"
                />
                <span v-else>
                  {{ topData.status?.sta_name }}
                </span>
              </div>
            </td>

            <td>
              <div class="table-cell date">
                <template v-if="topData.tsk_created">
                  <span class="day">{{
                    `${dayjs
                      .utc(topData.tsk_created)
                      .local()
                      .format("DD.MM.YYYY")}.`
                  }}</span>
                  <span class="time">
                    {{
                      `${dayjs
                        .utc(topData.tsk_created)
                        .local()
                        .format("HH:mm")}`
                    }}
                  </span>
                </template>
              </div>
            </td>
            <!-- <td>
              <div
                v-if="hours && (hours.spent || hours.estimate)"
                class="table-cell hours"
                :class="{
                  red: hours?.estimate < hours?.spent && hours?.estimate > 0,
                }"
              >
                <span class="spent"> {{ hours?.spent }} </span
                >{{ hours?.estimate ? "/" + hours.estimate : "" }}
              </div>
              <div v-else class="table-cell"></div>
            </td> -->
          </tr>
        </tbody>
      </table>
      <basic-button
        v-if="checkPermission(['estimations-add']) && !details.tsk_locked"
        :label="'Estimation'"
        :image="'plus'"
        :imageDisabled="'plus'"
        style="margin-top: 22px; white-space: nowrap"
        @click="openModal"
      />
    </div>
    <table-wrapper
      style="max-height: calc(100% - 150px)"
      :data="estimationsStore.estimations"
      :options="options"
      @delete-row="openConfirmModal"
      @edit="openEditModal"
    />
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { type Estimation, type Modal } from "@/assets/js/types";
import { ModalStore } from "@/store/modalStore";
import { EstimationsStore } from "@/store/estimationsStore";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";
import dayjs from "dayjs";

const props = defineProps(["details", "statuses", "hours"]);
const emit = defineEmits(["update"]);
const modalStore = ModalStore();
const estimationsStore = EstimationsStore();
const topData = ref<any>({});

function setupTopData() {
  const data: any = {
    tsk_due: props.details.tsk_due,
    progress: props.details.progress,
    tsk_id: props.details.tsk_id,
    tsk_created: props.details.tsk_created,
    status: props.details.status,
  };
  topData.value = data;
}

watch([() => props.details, () => props.hours], setupTopData);

// const topOptions = ref({
//   title: "",
//   passive: true,
//   columns: [
//     {
//       key: "due_date",
//       type: "date",
//       label: "Due date",
//     },
//     {
//       key: "progress",
//       type: "progress",
//       // label: "Progress",
//       label: "Progress / Hours",
//       showHours: true,
//     },
//     // {
//     //   key: "hours",
//     //   type: "hours",
//     //   label: "Hours",
//     //   style: {
//     //     "justify-content": "center",
//     //     "text-align": "center",
//     //   },
//     // },
//   ],
// });

const options = ref({
  title: "",
  noData: "No estimation available.",
  passive: true,
  sortable: true,
  type: "estimate",
  id: ["ttp_id", "tsk_id"],
  columns: [
    {
      key: "ttp_name",
      type: "title",
      label: "Type",
    },
    {
      key: "est_amount",
      type: "title",
      label: "Estimation",
    },
    {
      key: "user",
      type: "created-by",
      label: "Created by",
    },
    {
      key: "est_created",
      type: "date",
      label: "Date created",
    },
  ],
});

if (checkPermission(["estimations-add"]) && !props.details.tsk_locked) {
  options.value.columns.push({
    key: "",
    type: "edit",
    label: "",
  });
  options.value.columns.push({
    key: "",
    type: "delete",
    label: "",
  });
}

onMounted(() => {
  estimationsStore.getEstimations(props.details.tsk_id);
  setupTopData();
});

watch(
  () => props.details,
  () => estimationsStore.getEstimations(props.details.tsk_id)
);

function openModal() {
  const data: Modal = {
    component: "addEstimationModal",
    title: "Add estimation",
    options: {
      update: () => {
        update();
      },
      data: {
        tsk_name: props.details?.tsk_name || "",
      },
    },
  };
  modalStore.setModalData(data);
}

function openConfirmModal(estimation: Estimation) {
  const data: Modal = {
    component: "confirmModal",
    title: "Do you want to delete this estimate?", // "Are you sure ?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `
          Task type: <strong>{{estimation.ttp_name}} </strong><br>
          Estimated time: <strong>{{estimation.est_amount}} </strong>
        `,
        data() {
          return {
            estimation,
          };
        },
      },
      confirm: () => {
        deleteEstimation(estimation);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteEstimation(estimation: Estimation) {
  const params: any = {
    ttp_id: estimation.ttp_id,
    tsk_id: estimation.tsk_id,
  };
  try {
    await api.deleteEstimation(params);
    openCustomToast("You have successfully deleted the estimate.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while selecting an estimate.", "error");
  } finally {
    update();
  }
}
function openEditModal(estimation: Estimation) {
  const data: Modal = {
    component: "editEstimationModal",
    title: "Edit estimation",
    options: {
      data: estimation,
      name: `${props.details.tsk_name}`,
      update: () => {
        update();
      },
    },
  };
  modalStore.setModalData(data);
}

async function update() {
  estimationsStore.getEstimations(props.details.tsk_id);
  emit("update");
}
async function setStatus(status: any) {
  try {
    const params: any = {
      sta_id: status.sta_id,
    };
    const res = await api.editTask(params, props.details.tsk_id);
  } catch (error) {
    console.log(error);
  } finally {
    emit("update");
  }
}
</script>
<style lang="scss" scoped>
.task-estimate-wrapper {
  width: 100%;
  position: relative;
  height: calc(100% - 75px);

  .upper-wrapper {
    padding-top: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
  }
  table {
    width: 100%;
    overflow: auto;

    th {
      text-align: left;
      font-weight: 700;
      font-size: 11px;
      line-height: 12px;
      text-transform: uppercase;
      color: var(--label-color);
      padding-right: 15px;
      box-sizing: border-box;
      min-height: 28px;
      cursor: pointer;
      padding-bottom: 8px;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    .table-cell {
      padding-right: 15px;
    }

    .table-cell.hours {
      white-space: nowrap;
      text-align: center;

      .spent {
        font-weight: bold;
      }
    }

    .table-cell.hours.red {
      color: #dd3554;
    }

    th:first-child,
    td:first-child .table-cell {
      padding-left: 0;
    }

    th:last-child,
    td:last-child .table-cell {
      padding-right: 0;
    }

    .table-cell.date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .day {
        // font-weight: 700;
        // font-size: 15px;
        // line-height: 16px;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        white-space: nowrap;
      }

      .time {
        // font-weight: 400;
        // font-size: 11px;
        // line-height: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        white-space: nowrap;
        color: var(--label-color);
      }
    }

    .table-cell.progress {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;

      span {
        font-weight: 700;
        font-size: 11px;
        line-height: 12px;
        color: #7b7e8f;
        width: 100%;
        margin-bottom: 5px;
      }

      progress {
        height: 9px;
        width: 75px;
        appearance: none;
        appearance: none;
        border-radius: 5px;
        overflow: hidden;
        border: none;
        box-sizing: border-box;
      }

      progress::-webkit-progress-value {
        transition: var(--vt-c-transition-duration);
        background-color: var(--vt-c-active);
        border-radius: 5px;
      }

      progress.full::-webkit-progress-value {
        background-color: var(--vt-c-error);
      }

      progress::-webkit-progress-bar {
        background: rgb(236 237 242 / 10%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style>
.task-estimate-wrapper .upper-wrapper .table-row .table-data {
  border-bottom: none;
}

.task-estimate-wrapper .table-head .column-head:first-child {
  padding-left: 0;
}
.column-head:first-child {
  color: red;
}
.task-estimate-wrapper .table-row .table-data:first-child {
  padding-left: 0;
}
</style>
