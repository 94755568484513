<template>
  <div
    :style="{ width: width }"
    :class="{ disabled: disabled, modal: true, inv: inv }"
    class="btn-wrapper-two"
    tabindex="0"
    @click="emitClick"
    @keyup.enter="emitClick"
  >
    <!-- set modal:modal => modal:true because now the styling is the same everywhere -->
    <span
      v-if="image && image === 'plus'"
      style="padding-right: 3px; font-size: 14px"
      >+</span
    >
    <img v-else-if="image" :src="image" />
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "BasicButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: "auto",
    },
    inv: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      required: false,
      default: null,
    },
    imageDisabled: {
      required: false,
      default: null,
    },
    modal: {
      required: false,
      default: false,
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    function emitClick() {
      if (!props.disabled) {
        emit("click");
      }
    }

    return {
      emitClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-wrapper-two {
  cursor: pointer;
  // background-color: var(--vt-c-active);
  background: linear-gradient(
    180deg,
    #2c75f4 0%,
    rgba(44, 117, 244, 0.78) 53.02%,
    rgba(44, 117, 244, 0.6) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  flex-grow: 0;
  border-radius: 8px;
  align-self: flex-start;
  transition-duration: var(--vt-c-transition-duration);
  // color: var(--text-color);
  color: var(--color-text-button);
  // font-weight: 600;
  // font-size: 12px;
  // line-height: 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  padding: 0 14px 0 20px;
  img {
    margin-right: 10px;
  }
}
.modal {
  background: var(--active-color-modal);
  // color: var(--vt-c-black);
  // color: var(--color-text-button);
  color: var(--color-background);
}

.btn-wrapper-two.inv {
  background: transparent;
  // background: rgba(70, 73, 81, 0.1);
  color: var(--vt-c-active);
  border: 2px solid var(--vt-c-active);
}
.modal.inv {
  background-color: transparent !important;
  background: var(--button-background-color-transparent) !important;
  // border: 1.5px solid var(--active-color-modal);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: var(--active-color-modal);
  border: 2px solid var(--active-color-modal);
}

.disabled,
.disabled:hover,
.disabled:active {
  background: rgb(70 73 81 / 10%);
  color: var(--placeholder-color);
  cursor: initial;
}
</style>
