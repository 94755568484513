import api from "@/api";
import { getLocalStorageReac, setLocalStorageReac } from "@/assets/js/helpers";
import { type Company } from "@/assets/js/types";
import router from "@/router";
import { UserStore } from "./userStore";
import { ProjectStore } from "./projectStore";

export const CompanyStore = defineStore("CompanyStore", () => {
  const userStore = UserStore();
  const projectStore = ProjectStore();

  const companies = ref<Company[] | null>(null);
  const activeCompany = ref<Company | null>(null);

  const getActiveCompany = computed(() => {
    return activeCompany.value;
  });

  async function getCompanies() {
    try {
      const res = await api.getCompanies();
      companies.value = res.data.companies;
    } catch (error) {
      console.log(error);
    }
  }

  async function replaceActiveCompany(company: Company) {
    activeCompany.value = company;
  }

  async function setActiveCompany(company?: Company) {
    if (company) {
      activeCompany.value = company;
      setLocalStorageReac("activeCompany", activeCompany.value.cmp_id);
      await userStore.getUserData();
      await userStore.fetchPermissions();
      projectStore.setSelectedProjectTaskId(null);
      projectStore.setSelectedProjectId(null);
      projectStore.setSelectedProjectTaskSection(null);
      navigate();
      return;
    } else if (router.currentRoute.value.params.companyId) {
      const comp = companies.value?.find(
        (el) => el.cmp_id === Number(router.currentRoute.value.params.companyId)
      ) as Company;
      if (comp) {
        activeCompany.value = comp;
        await userStore.fetchPermissions();
        return;
      }
    } else if (getLocalStorageReac("activeCompany").value) {
      const comp = companies.value?.find(
        (el) =>
          el.cmp_id === parseInt(getLocalStorageReac("activeCompany").value)
      ) as Company;
      if (comp) {
        activeCompany.value = comp;
        await userStore.fetchPermissions();
        navigate();
        return;
      }
    }

    const comp: Company = companies.value?.find(
      (el) => el.cmp_name === "Private tasks"
    ) as Company;
    if (comp) {
      activeCompany.value = comp;
      setLocalStorageReac("activeCompany", comp.cmp_id);
      await userStore.fetchPermissions();
    }

    function navigate() {
      if (userStore.getUser) {
        const params: any = router.currentRoute.value.params;
        params.companyId = activeCompany.value?.cmp_id;
        router.push({ name: "MyTasks", params: params });
      }
    }
    navigate();
  }

  async function setupCompanies() {
    await getCompanies();
    await setActiveCompany();
    return true;
  }

  return {
    companies,
    activeCompany,
    getCompanies,
    setActiveCompany,
    getActiveCompany,
    setupCompanies,
    replaceActiveCompany,
  };
});
