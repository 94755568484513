import type { User, Privilege } from "@/assets/js/types";
import { deleteLocalStorageReac } from "@/assets/js/helpers";
import api from "@/api";
import router from "@/router";
import { getJwtData } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";
import { TransferStore } from "./transferStore";

export const UserStore = defineStore("UserStore", () => {
  const companyStore = CompanyStore();
  const transferStore = TransferStore();

  const user = ref<User | null>(null);
  const navigate = ref<string | null>(null);
  const privileges = ref<Privilege[]>([]);

  const getAllPermissions = computed(() => {
    return privileges.value;
  });

  function setNavigate(path: string | null): void {
    navigate.value = path;
  }

  const getUser = computed(() => {
    return user.value;
  });

  async function logout() {
    try {
      await api.logout();
    } catch (error) {
      console.log(error);
    } finally {
      transferStore.clearClipboard("both");
      deleteLocalStorageReac("jwt");
      router.push({ name: "LoginPage" });
    }
  }

  async function getUserData() {
    const jwt = await getJwtData();

    if (!jwt && router.currentRoute.value.meta.jwtRequired) {
      router.push({ name: "LoginPage" });
    }

    const params = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    console.log(params);
    try {
      const res = await api.getUserData(params);
      const userData = res.data.userData;
      const newUser: User = {
        name: userData.name,
        lastName: userData.lastname,
        email: userData.email,
        img_id: userData.img_id,
        usr_displayname: userData.usr_displayname,
        id: userData.usr_id,
        usr_color: userData.usr_color,
        phone: userData.phone === "null" ? null : userData.phone,
        status: userData.status,
      };
      user.value = newUser;
    } catch (error) {
      router.push({ name: "LoginPage" });
    }
  }

  async function fetchPermissions() {
    const cmp_id = companyStore.getActiveCompany?.cmp_id;

    try {
      const params: any = {
        cmp_id: cmp_id,
      };
      const res = await api.fetchPermissions(params);
      privileges.value = res.data.privileges;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    user,
    getUser,
    logout,
    navigate,
    setNavigate,
    getUserData,
    fetchPermissions,
    privileges,
    getAllPermissions,
  };
});
