<template>
  <div class="task-hours-wrapper">
    <div class="upper-wrapper">
      <basic-button
        v-if="checkPermission(['hours-add']) && !details?.tsk_locked"
        :label="'Add hours'"
        :image="'plus'"
        :imageDisabled="plus"
        style="align-self: center; white-space: nowrap"
        @click="openModal"
      />
    </div>
    <table-wrapper
      style="max-height: calc(100% - 80px)"
      :data="hoursStore.hours"
      :options="options"
      @delete-row="openConfirmModal"
      @edit="openEditModal"
      @info="openInfoModal"
    >
      <template #dropdown="slotProps">
        <table-dropdown-hour-details :row="slotProps.row" />
      </template>
    </table-wrapper>
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import plus from "@/components/elements/table/plus.svg";
import { ModalStore } from "@/store/modalStore";
import { checkPermission, openCustomToast, setTime } from "@/assets/js/helpers";
import { type Hour, type Modal } from "@/assets/js/types";
import { HoursStore } from "@/store/hoursStore";
import dayjs from "dayjs";

const hoursStore = HoursStore();
const modalStore = ModalStore();
const props = defineProps(["details"]);
const emit = defineEmits(["update"]);
const router = useRouter();

function openModal() {
  const data: Modal = {
    component: "addHoursModal",
    title: "Add hours",
    options: {
      update: () => {
        update();
      },
      data: {
        tsk_name: props.details?.tsk_name || "",
        tsk_id: router.currentRoute.value.params.taskId
          ? router.currentRoute.value.params.taskId
          : router.currentRoute.value.params.projectId,
      },
    },
  };
  modalStore.setModalData(data);
}

function openConfirmModal(hour: Hour) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete hours?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `
          Time: <strong>{{setTime(hour.tms_amount)}} </strong><br>
          Date: <strong>{{dayjs
            .utc(hour.tms_date)
            .local()
            .format("DD.MM.YYYY")}} </strong><br>
          Task type: <strong>{{hour.ttp_name}} </strong><br> 
          Description: <strong :title="hour.tms_description">{{
            hour.tms_description?.length && hour.tms_description.length > 30
              ? hour.tms_description.substring(0, 30).concat("...")
              : hour.tms_description
          }} </strong><br>
        `,
        data() {
          return {
            hour,
            dayjs,
          };
        },
        methods: {
          setTime,
        },
      },
      confirm: () => {
        deleteHour(hour);
      },
    },
  };
  modalStore.setModalData(data);
}

async function deleteHour(hour: Hour) {
  const params: any = {
    tms_id: hour.tms_id,
  };
  try {
    await api.deleteHours(params);
    openCustomToast(
      "You have successfully deleted the logged hours.",
      "success"
    );
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while dialing the hours.", "error");
  } finally {
    hoursStore.getHours(props.details.tsk_id);
  }
}
function openEditModal(hour: Hour) {
  const h: Hour = JSON.parse(JSON.stringify(hour));
  if (h.tsk_locked) {
    return;
  }
  // h.tms_amount = `${Math.floor(parseInt(h.tms_amount as string) / 60)}:${String(
  //   parseInt(h.tms_amount as string) % 60
  // ).padStart(2, "0")}`;

  // h.tms_approved = h.tms_approved ? h.tms_approved : "0";
  // h.tms_approved = `${Math.floor(parseInt(h.tms_approved) / 60)}:${String(
  //   parseInt(h.tms_approved) % 60
  // ).padStart(2, "0")}`;

  // h.tms_billed = h.tms_billed ? h.tms_billed : "0";
  // h.tms_billed = `${Math.floor(parseInt(h.tms_billed) / 60)}:${String(
  //   parseInt(h.tms_billed) % 60
  // ).padStart(2, "0")}`;

  const data: Modal = {
    component: "editHoursModal",
    title: "Edit time",
    options: {
      data: h,
      name: `${props.details.tsk_name}`,
      update: () => {
        update();
      },
    },
  };
  modalStore.setModalData(data);
}

function openInfoModal(hour: Hour) {
  const data: Modal = {
    component: "infoHoursModal",
    title: "Description",
    options: {
      data: hour,
    },
  };
  modalStore.setModalData(data);
}

onMounted(() => {
  hoursStore.getHours(props.details.tsk_id);
});

watch(
  () => props.details,
  () => {
    hoursStore.getHours(props.details.tsk_id);
    setColumn();
  }
);

async function update() {
  hoursStore.getHours(props.details.tsk_id);
  emit("update");
}

const options: Record<string, any> = ref({
  title: "",
  noData: "No hours logged.",
  deletable: true,
  editable: true,
  dropdown: true,
  sortable: true,
  type: "hour",
  id: ["tms_id"],
  columns: [
    {
      key: "ttp_name",
      type: "title",
      label: "Type",
    },
    {
      key: "tst_name",
      type: "title",
      label: "HOUR TYPE",
    },
    {
      key: "tms_shortinfo",
      type: "title",
      label: "keywords / short desc.",
      style: {
        width: "120px",
        display: "block",
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
        overflow: "hidden",
      },
    },
    {
      key: "tms_amount",
      type: "title",
      label: "Time",
    },
    {
      key: "tms_date",
      type: "date",
      label: "Date created",
    },
    {
      key: "user",
      type: "created-by",
      label: "Performed by",
    },
    // {
    //   key: "",
    //   type: "info",
    //   label: "",
    // },
    // {
    //   key: "editable",
    //   type: "edit",
    //   label: "",
    // },
    // {
    //   key: "deletable",
    //   type: "delete",
    //   label: "",
    // },
  ],
});

if (checkPermission(["hours-manage"])) {
  options.value.contextOptions = [
    {
      name: "Copy Hour",
      to_do: "copy-hour",
    },
    {
      name: "Cut Hour",
      to_do: "cut-hour",
    },
  ];
}
function setColumn() {
  options.value.columns = [
    {
      key: "ttp_name",
      type: "title",
      label: "Type",
    },
    {
      key: "tms_amount",
      type: "title",
      label: "Time",
    },
    {
      key: "tms_shortinfo",
      type: "title",
      label: "keywords / short desc.",
      style: {
        width: "120px",
        display: "block",
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
        overflow: "hidden",
      },
    },
    {
      key: "tms_date",
      type: "date",
      label: "Date",
    },
    {
      key: "tst_name",
      type: "title",
      label: "HOUR TYPE",
    },
    {
      key: "user",
      type: "created-by",
      label: "Performed by",
    },
  ];
  if (!props.details.tsk_locked) {
    options.value.columns.push({
      key: "",
      type: "edit",
      label: "",
    });
    options.value.columns.push({
      key: "",
      type: "delete",
      label: "",
    });
  }
}
setColumn();
</script>
<style lang="scss" scoped>
.task-hours-wrapper {
  width: 100%;
  // position: relative;
  height: calc(100% - 95px);

  .upper-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 22px;
  }
}
</style>
