<template>
  <div class="time-btn-wrapper">
    <div class="up" @click="changeValue('up')">
      <img src="../../assets/images/new-up.png" alt="up" />
    </div>
    <div class="time-content">
      <input
        id=""
        type="text"
        name=""
        :placeholder="placeholder"
        :value="modelValue"
        :disabled="Boolean(disable)"
        @input="changeValueInput"
        @keydown="keyDown"
      />
      <!-- @keypress="validateStack" -->
    </div>
    <div class="down" @click="changeValue('down')">
      <img src="../../assets/images/new-bottom.png" alt="down" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { type ValueTimeButton } from "@/assets/js/types";

const props = defineProps<{
  modelValue: ValueTimeButton;
  maxValueCharacter: number;
  min: number;
  max: number;
  step: number;
  placeholder?: string;
  disable?: boolean;
}>();
const emit = defineEmits(["update:modelValue", "changeValue"]);

function validation(newValue: any) {
  if (newValue >= props.max) {
    emit("update:modelValue", props.max - 1);
  } else if (newValue <= props.min) {
    emit("update:modelValue", props.min);
  } else {
    emit("update:modelValue", newValue);
  }
}
function changeValue(upOrDown: string) {
  let currentValue = Number(props.modelValue);

  if (isNaN(currentValue)) {
    currentValue = upOrDown === "up" ? 1 : 0;
  } else {
    if (upOrDown === "up") {
      currentValue += props.step;
    } else {
      currentValue -= props.step;
    }
  }
  validation(currentValue);
}
function changeValueInput(evt: any) {
  let newValue = parseInt(evt.target.value, 10);
  if (isNaN(newValue)) newValue = props.min;

  validation(newValue);
}
function keyDown(e: any) {
  const newValue = e.target.value;
  const reg = /^\d+$/;
  if (reg.test(newValue) && newValue.length <= props.maxValueCharacter) {
    validation(newValue);
  } else {
    validation(0);
  }
}
// function validateStack(evt: KeyboardEvent) {
//   const theEvent = evt || window.event;
//   // Handle paste
//   let key;
//   if (theEvent.type === "paste") {
//     key = theEvent.clipboardData?.getData("text/plain");
//   } else {
//     // Handle key press
//     key = theEvent.code || theEvent.which;
//     key = String.fromCharCode(key);
//   }
//   const regex = /[0-9]|\./;
//   // let firstCharOfText = key.charAt(0);
//   // validation(newValue);
//   if (!regex.test(key)) {
//     if (theEvent.preventDefault) theEvent.preventDefault();
//   }
// }
</script>
<style lang="scss" scoped>
.time-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .up,
  .down {
    cursor: pointer;
    padding: 1px 0 3px 0;
    ::selection {
      background: rgb(0 0 0 / 0%);
    }
  }
  .up:hover,
  .down:hover img {
    filter: brightness(2.2);
  }
  .up:active,
  .down:active img {
    filter: brightness(6.2);
  }

  .time-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    // border: 1px solid #434446;
    // border-radius: 10px;
    max-width: 70px;

    input {
      max-width: 40px;
      background: transparent;
      text-align: center;
      color: var(--color-text-modal);
      padding: 0 !important;
    }

    input:focus {
      background: #1b1b1e;
    }
  }
}
</style>
