<template>
  <section class="page-wrapper">
    <div class="page">
      <page-switcher
        v-model="activePage"
        :pages="pages"
        style="margin-top: 55px; min-width: 200px"
        class="vertical"
      />
      <component :is="pageMap[activePage.value]" v-if="activePage?.value" />
    </div>
  </section>
</template>
<script setup lang="ts">
import ClientsPage from "./MySettingsPages/ClientsPage.vue";
import ReportPage from "./MySettingsPages/ReportsPage.vue";
import UsersPage from "./MySettingsPages/UsersPage.vue";
import RolesPage from "./MySettingsPages/RolesPage.vue";
import StatusPage from "./MySettingsPages/StatusPage.vue";
import TaskTypesPage from "./MySettingsPages/TaskTypesPage.vue";
import HourTypesPage from "./MySettingsPages/HourTypesPage.vue";
import AvailabilityStatusPage from "./MySettingsPages/AvailabilityStatusPage.vue";
import { checkPermission } from "@/assets/js/helpers";
import { type Page } from "@/assets/js/types";
import { CompanyStore } from "@/store/companyStore";

const companyStore = CompanyStore();
const privateTasks = ref(true);

watch(
  () => companyStore.activeCompany,
  (newVal, oldVal) => {
    checkIsPrivate(newVal);
  }
);

function checkIsPrivate(v: any) {
  privateTasks.value = v.cmp_name === "Private tasks";
}

const pageMap: any = {
  clientsPage: ClientsPage,
  reportsPage: ReportPage,
  usersPage: UsersPage,
  rolesPage: RolesPage,
  statusPage: StatusPage,
  taskTypesPage: TaskTypesPage,
  hourTypesPage: HourTypesPage,
  availabilityStatusPage: AvailabilityStatusPage,
};

const pages = computed(() => {
  return [
    {
      id: 1,
      label: "Clients",
      value: "clientsPage",
      section: "clientsPage",
      show: checkPermission(["clients-view"]),
    },
    {
      id: 2,
      label: "Reports",
      value: "reportsPage",
      section: "reportsPage",
      show: checkPermission(["report-view"]),
    },
    {
      id: 3,
      label: "Users",
      value: "usersPage",
      section: "usersPage",
      show: privateTasks.value ? false : checkPermission(["members-view"]),
    },
    {
      id: 4,
      label: "Roles",
      value: "rolesPage",
      section: "rolesPage",
      show: privateTasks.value ? false : checkPermission(["roles-view"]),
    },
    {
      id: 5,
      label: "Status",
      value: "statusPage",
      section: "statusPage",
      show: checkPermission(["status-view"]),
    },
    {
      id: 6,
      label: "Task types",
      value: "taskTypesPage",
      section: "taskTypesPage",
      show: checkPermission(["tasktypes-view"]),
    },
    {
      id: 7,
      label: "Hour types",
      value: "hourTypesPage",
      section: "hourTypesPage",
      show: checkPermission(["timesheet-types-view"]),
    },
    {
      id: 8,
      label: "Availability status",
      value: "availabilityStatusPage",
      section: "availabilityStatusPage",
      show: checkPermission(["availabilitytypes-view"]),
    },
  ];
});

// const pages = ref([
//   {
//     id: 1,
//     label: "Clients",
//     value: "clientsPage",
//     section: "clientsPage",
//     show: checkPermission(["clients-view"]),
//   },
//   {
//     id: 2,
//     label: "Reports",
//     value: "reportsPage",
//     section: "reportsPage",
//     show: checkPermission(["reports-view"]),
//   },
//   {
//     id: 3,
//     label: "Users",
//     value: "usersPage",
//     section: "usersPage",
//     show: privateTasks.value ? false : checkPermission(["members-view"]) ,
//   },
//   {
//     id: 4,
//     label: "Roles",
//     value: "rolesPage",
//     section: "rolesPage",
//     show: privateTasks.value ? false : checkPermission(["roles-view"]),
//   },
//   {
//     id: 5,
//     label: "Status",
//     value: "statusPage",
//     section: "statusPage",
//     show: checkPermission(["status-view"]),
//   },
//   {
//     id: 6,
//     label: "Task types",
//     value: "taskTypesPage",
//     section: "taskTypesPage",
//     show: checkPermission(["tasktypes-view"]),
//   },
//   {
//     id: 7,
//     label: "Hour types",
//     value: "hourTypesPage",
//     section: "hourTypesPage",
//     show: checkPermission(["timesheet-types-view"]),
//   },
// ]);

const activePage = ref<Page | null>(null);
onMounted(() => {
  init();
});
onActivated(() => {
  init();
});

function init() {
  checkIsPrivate(companyStore.activeCompany);
  const aP: Page = pages.value.find(
    (page: Page) => page.show || page.show === undefined
  ) as Page;
  activePage.value = aP || null;
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  transition: 0.3s;
  height: var(--vt-c-page-height);
  max-width: 1744px;
  margin: 0 auto;

  .page {
    width: 100%;
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;

    .page-switcher-wrapper {
      max-width: 783px;
    }
  }
}
</style>
