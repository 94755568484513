<template>
  <div class="input-wrapper">
    <basic-input
      v-model="data.title"
      :color="data.tsk_color"
      placeholder="Title"
      label="Title"
      :setFocus="true"
    />
  </div>
  <div class="section">
    <div class="section-wrapper" style="margin-right: 20px">
      <div class="section">
        <div class="part">
          <div class="input-wrapper">
            <div class="datepicker-wrapper" style="margin-left: 0">
              <div class="datepicker-label">Due date</div>
              <Datepicker
                v-model="data.due_date"
                :clearable="false"
                format="dd.MM.yyyy HH:mm"
                :dark="true"
                placeholder="Due date"
                input-class-name="datepicker-input"
                menu-class-name="datepicker-dropdown"
              />
            </div>
          </div>
        </div>
        <div class="part">
          <div class="input-wrapper">
            <basic-dropdown
              :list="taskPriorities"
              :selected="data.priority"
              :searchable="true"
              :labelKey="'name'"
              :title="'Priority'"
              @selected="setPriority"
            >
              <template #default="slotProps">
                <dropdown-item :item="slotProps.item" />
              </template>
            </basic-dropdown>
          </div>
        </div>
      </div>
      <div class="input-wrapper">
        <basic-input
          v-model="data.description"
          placeholder="Description"
          label="Description"
          :type="'textarea'"
        />
      </div>

      <div class="input-wrapper">
        <SelectColor :profile="true" @color="setColor"> </SelectColor>
      </div>
    </div>
    <div class="part">
      <chooser-view
        v-model="data.assign"
        :title="'Assign'"
        :users="users"
        :edit="true"
        :changable="false"
        @search="onSearch"
      />
    </div>
  </div>

  <!-- <div class="input-wrapper">
    <basic-input
      v-model="data.hours"
      placeholder="Hours"
      label="Hours"
      :type="'number'"
    />
  </div> -->

  <div class="btn-wrapper __create-task">
    <basic-checkbox v-model="data.hours" label="Аdd hours for this task?" />
    <div class="flex">
      <basic-button
        :inv="true"
        :modal="true"
        style="width: 100px; margin-right: 30px; margin-left: 30px"
        label="Cancel"
        @click="cancel"
      />
      <basic-button
        :modal="true"
        style="width: 100px"
        label="Save"
        @click="saveTask"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api";
import { debounce, openCustomToast } from "@/assets/js/helpers";
import { taskPriorities } from "@/assets/js/values";
import { type User } from "@/assets/js/types";
import Datepicker from "@vuepic/vue-datepicker";

const props = defineProps(["options"]);
defineEmits(["close"]);
const modalStore = ModalStore();
const companyStore = CompanyStore();
type Data = {
  title: string | null;
  description: string | null;
  cmp_id?: number;
  due_date: Date | string | null;
  assign: User[];
  priority: (typeof taskPriorities)[0] | null;
  hours: boolean;
  tsk_color: string | null;
};
const data = reactive<Data>({
  title: null,
  description: null,
  cmp_id: companyStore.getActiveCompany?.cmp_id,
  due_date: null,
  assign: [],
  priority: null,
  hours: false,
  tsk_color: null,
});
const users = ref([]);

async function getUsers(search?: string) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search) {
      params.search = search;
    }
    const res = await api.searchUsers(params);
    users.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

const onSearch = debounce(
  function () {
    getUsers(arguments[0]);
  },
  500,
  false
);

function saveTask() {
  if (!data.title) {
    openCustomToast('The field "Title" is required', "error");
    return;
  }
  if (props.options.save) {
    props.options.save(data);
    modalStore.setModalData(null);
  }
}
function cancel() {
  modalStore.setModalData(null);
}
function setPriority(priority: any) {
  data.priority = priority;
}
function setColor(e: string) {
  data.tsk_color = e;
}
</script>
<style lang="scss" scoped>
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.input-wrapper {
  margin-bottom: 15px;
}

.section {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  .part {
    width: 270px;
  }

  .part:first-child {
    margin-right: 20px;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: flex-end;

  .container:hover {
    background: none;

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      text-align: center;
      // color: #2c75f4;
    }
  }
}
</style>
<style lang="scss">
.btn-wrapper.__create-task {
  .flex {
    display: flex;
  }
  .container:hover {
    background: none;
  }

  .container {
    display: flex;
    align-items: end;
  }

  .label {
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: var(--active-color-modal) !important;
  }
}
</style>
