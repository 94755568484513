<template>
  <div class="availability-page-wrapper">
    <table-wrapper
      :data="listAvailabilityTypes"
      :options="options"
      upSpaceDown="63px"
      style="max-width: fit-content"
      @search="onSearch"
      @delete-row="openConfirmModal"
      @edit="openEditModal"
      @create="openCreateModal"
    />
    <!-- <pagination-one
        v-if="count > 0"
        v-model:page="page"
        :perPage="perPage"
        :offset="offset"
        :count="count"
        prevText="Prev"
        nextText="Next"
        style="margin: auto"
        @page-changed="onChange"
      /> -->
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { type Modal, type AvailabilityType } from "@/assets/js/types";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";

//   import { usePagination } from "@/assets/js/composables";

//   const { count, offset, onChange, resetPagination, page, perPage } =
//     usePagination(getAvailabilityTypes, 11);

const modalStore = ModalStore();
const companyStore = CompanyStore();
const availabilityTypes = ref<AvailabilityType[]>([]);
const search = ref<string | null>(null);

const listAvailabilityTypes = computed(() => {
  return availabilityTypes.value.map((el) => {
    const res: any = el;
    console.log(el);
    if (
      checkPermission(["availabilitytypes-add"]) &&
      el.avt_name.toLowerCase() !== "working"
    ) {
      res.editable = 1;
      res.deletable = 1;
    } else {
      // res.deletable= 0;
      // res.editable= 0;
      res.avt_locked = true; // prevents the display of delete as well as edit for a certain condition
    }

    return res;
  });
});

const options = ref({
  title: "",
  noData: "No avaiability status.",
  passive: true,
  searchable: true,
  creatable: checkPermission(["availabilitytypes-add"]),
  //   editable: checkPermission(["availabilitytypes-add"]),
  //   deletable: checkPermission(["availabilitytypes-add"]),
  sortable: false,
  type: "estimate",
  id: ["avt_name"],
  columns: [
    {
      key: "avt_name",
      type: "title",
      label: "Status",
      style: {
        width: "320px",
      },
    },
    {
      key: "avt_color",
      type: "color",
      label: "color",
      style: {
        "max-width": "150px",
      },
    },
  ],
});

if (checkPermission(["availabilitytypes-add"])) {
  options.value.columns.push({
    key: "",
    type: "edit",
    label: "",
    style: {
      "max-width": "initial",
    },
  });
  options.value.columns.push({
    key: "",
    type: "delete",
    label: "",
    style: {
      "max-width": "initial",
    },
  });
}

// onMounted(() => {
//  getAvailabilityTypes();
// });

watch(
  () => companyStore.activeCompany,
  () => {
    //   resetPagination();
    getAvailabilityTypes();
  }
);

function onSearch(data: string) {
  search.value = data;
  // resetPagination();
  getAvailabilityTypes();
}

async function getAvailabilityTypes() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      // offset: offset.value - 1,
      // limit: perPage.value,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    const res = await api.getAvailabilityTypes(params);
    availabilityTypes.value = res.data.data;
    //   count.value = res.data.meta_info.count;
  } catch (error) {
    console.log(error);
  }
}
getAvailabilityTypes();

function openConfirmModal(avtType: AvailabilityType) {
  if (avtType.avt_locked) {
    return;
  }
  const data: Modal = {
    component: "confirmModal",
    title: "Delete availability type?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Availability type: <strong>${avtType.avt_name} </strong><br>`,
        data() {
          return { avtType };
        },
      },
      confirm: () => {
        deleteAvailabilityTypes(avtType);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteAvailabilityTypes(avtType: AvailabilityType) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      avt_id: avtType.avt_id,
    };
    await api.deleteAvailabilityTypes(params);
    openCustomToast(
      "You have successfully deleted the availability type.",
      "success"
    );
  } catch (error) {
    openCustomToast(
      "An error occurred while deleting this availability type.",
      "error"
    );
  } finally {
    //   resetPagination();
    getAvailabilityTypes();
  }
}

function openEditModal(avtType: AvailabilityType) {
  if (avtType.avt_locked) {
    return;
  }
  const data: Modal = {
    component: "editAvailabilityType",
    title: "Edit availability type", // fbb209
    options: {
      data: avtType,
      update: () => {
        getAvailabilityTypes();
      },
    },
  };
  modalStore.setModalData(data);
}

function openCreateModal() {
  const modal: any = {
    title: "Add availability type",
    component: "addAvailabilityType",
    options: {
      update: () => {
        getAvailabilityTypes();
      },
    },
  };
  modalStore.setModalData(modal);
}
</script>
<style lang="scss" scoped>
.availability-page-wrapper {
  padding-top: 55px;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
