<template>
  <div class="clients-page-wrapper">
    <basic-search
      v-model="search"
      style="margin-bottom: 34px"
      :placeholder="'Search...'"
      :width="'340px'"
      :widthInput="'301px'"
      @update:model-value="getStatuses"
    />
    <basic-button
      v-if="checkPermission(['status-add'])"
      :label="'New status'"
      :image="'plus'"
      :imageDisabled="plus"
      style="white-space: nowrap; margin-bottom: 34px"
      @click="openCreateModal"
    />
    <basic-list
      :data="listStatuses"
      :label="'sta_name'"
      :odd="true"
      @edit="openEditModal"
      @delete="openConfirmModal"
    />
  </div>
</template>
<script setup lang="ts">
import plus from "@/components/elements/table/plus.svg";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { type Modal, type TaskStatus } from "@/assets/js/types";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const statuses = ref<TaskStatus[]>([]);
const search = ref<string | null>(null);

const listStatuses = computed(() => {
  return statuses.value.map((el) => {
    const res: any = el;
    if (!el.sta_code && checkPermission(["status-add"])) {
      res.editable = true;
      res.deletable = true;
    }
    return res;
  });
});

onMounted(() => {
  getStatuses();
});

watch(
  () => companyStore.activeCompany,
  () => {
    getStatuses();
  }
);

async function getStatuses() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    const res = await api.getStatuses(params);
    statuses.value = res.data.status;
  } catch (error) {
    console.log(error);
  }
}

function openConfirmModal(status: TaskStatus) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete status?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Status: <strong>{{status.sta_name}}</strong><br>`,
        data() {
          return { status };
        },
      },
      confirm: () => {
        deleteStatus(status);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteStatus(status: TaskStatus) {
  try {
    await api.deleteStatus(status.sta_id);
    openCustomToast("You have successfully deleted the status.", "success");
  } catch (error) {
    openCustomToast("An error occurred while deleting the status.", "error");
  } finally {
    getStatuses();
  }
}

function openEditModal(status: TaskStatus) {
  const data: Modal = {
    component: "editStatusModal",
    title: "Edit status",
    options: {
      data: status,
      update: () => {
        getStatuses();
      },
    },
  };
  modalStore.setModalData(data);
}

function openCreateModal() {
  const modal: any = {
    title: "Add new status",
    component: "newStatusModal",
    options: {
      update: () => {
        getStatuses();
      },
    },
  };
  modalStore.setModalData(modal);
}
</script>
<style lang="scss" scoped>
.clients-page-wrapper {
  padding-top: 55px;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
