<template>
  <div
    class="table-row"
    :data-id="generateIdObject()"
    :data-type="options.type"
    :data-lock="data.tsk_locked"
    :class="`depth-${depth} ${
      taskId === data.tsk_id && !options.passive ? 'active' : ''
    }`"
    @contextmenu.prevent.stop="emit('contextmenu', [$event, data])"
  >
    <div
      v-for="cell in options.columns"
      :key="cell.key"
      class="table-data"
      :class="{
        icon:
          cell.type === 'delete' ||
          cell.type === 'edit' ||
          cell.type === 'info' ||
          cell.ype === 'edit-ep',
      }"
      @click="handleClick(cell)"
    >
      <table-cell
        v-if="
          !(
            (props.data.tsk_locked || props.data.avt_locked) &&
            (cell.type === 'delete' || cell.type === 'edit')
          )
        "
        :tskId="props.data.tsk_id"
        :data="props.data[cell.key]"
        :createdBy="
          cell.key === 'user' && props.data?.creator ? props.data.creator : null
        "
        :type="cell.type"
        :row="props.data"
        :options="cell"
        :editable="props.options.editable"
        :editableEp="props.options.editableEp"
        :depth="depth"
        :open="open_2"
        @change-value-checkbox="changeValueCheckbox"
      />
    </div>
  </div>
  <template v-if="children && children.length > 0 && open_2 === true">
    <table-row
      v-for="child in children"
      :key="child.tsk_id"
      :data="child"
      :options="options"
      :depth="depth + 1"
      :parentTree="parentTree"
      :open="parentTree.includes(child.tsk_id)"
      @clicked="emit('clicked', $event)"
      @delete="emit('delete', $event)"
      @edit="emit('edit', $event)"
      @edit-ep="emit('edit-ep', $event)"
      @contextmenu="emit('contextmenu', [$event, child])"
    />
  </template>
  <template v-if="open_2 === true">
    <slot></slot>
  </template>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { TasksStore } from "@/store/tasksStore";

const companyStore = CompanyStore();
const emit = defineEmits([
  "clicked",
  "delete",
  "edit",
  "info",
  "contextmenu",
  "change-value-checkbox",
  "edit-ep",
]);
const props = defineProps(["data", "options", "depth", "open", "parentTree"]);
const router = useRouter();
const taskId = computed(() => {
  return Number(router.currentRoute.value.params.taskId);
});

const children: any = ref(null);
const open_2 = ref<boolean>(false);
onMounted(() => {
  if (props.open) {
    setup();
  }
  // removeCell();
});
async function setup() {
  await getChildren();
  open_2.value = true;
}

function handleClick(cell: any) {
  if (
    cell.type === "delete" &&
    (props.data[cell.key] === undefined || props.data[cell.key] === 1)
  ) {
    emit("delete", props.data);
  } else if (
    cell.type === "edit" &&
    (props.data[cell.key] === undefined || props.data[cell.key] === 1)
  ) {
    emit("edit", props.data);
  } else if (cell.type === "info") {
    emit("info", props.data);
  } else if (cell.type === "dropdown") {
    openChildren();
  } else if (cell.type === "edit-ep") {
    emit("edit-ep", props.data);
  } else {
    emit("clicked", props.data);
  }
}
function removeCell() {
  if (props.data?.tsk_locked && props.data?.tsk_locked) {
    const deleteP = props.options.columns.findIndex((object) => {
      return object.type === "delete";
    });
    const editP = props.options.columns.findIndex((object) => {
      return object.type === "edit";
    });
    if (deleteP >= 0) {
      props.options.columns.splice(1, deleteP);
    }
    if (editP >= 0) {
      props.options.columns.splice(1, editP);
    }
  }
}

// watch(() => props.data, removeCell);

function openChildren() {
  open_2.value = !open_2.value;
  if (open_2.value && props.data.has_childen && props.data.has_childen > 0) {
    getChildren();
  }
  emit("clicked", props.data);
}

async function getChildren() {
  try {
    const params: any = {
      tsk_id_parent: props.data.tsk_id,
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (props.options.watchArchived) {
      const taskStore = TasksStore();
      params.archive = taskStore.archived ? 1 : 0;
    }
    const res = await api.getTasks(params);
    children.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
function generateIdObject(): string | null {
  if (!props.options.id) {
    return null;
  } else {
    const res: any = {};
    const ids = props.options.id;
    for (let i = 0; i < ids.length; i++) {
      res[ids[i]] = props.data[ids[i]];
    }
    return JSON.stringify(res);
  }
}

function changeValueCheckbox(e: any) {
  emit("change-value-checkbox", { e: e, data: props.data });
}
</script>
<style lang="scss" scoped>
.table-row {
  display: table-row;
  cursor: pointer;

  .table-data {
    padding: 0 8px;
    box-sizing: border-box;
    height: 50px;
    height: 55px;
    display: table-cell;
    position: relative;
    vertical-align: top;
    .title {
      // max-width: 120px;
    }
  }

  .table-data.icon {
    padding: 0;
  }
}
.table-row:hover {
  background: var(--background-row);
}

.table-row.active {
  background: var(--background-row);
  border-radius: 10px;
  .table-data {
    // background: #424245;
  }

  .table-data:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .table-data:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

@for $i from 0 through 10 {
  $padding-left: (35px * $i) + 15px;

  // .depth-#{$i} .table-data:nth-child(2),
  .depth-#{$i} .table-data:nth-child(1) {
    padding-left: $padding-left;
  }

  // .depth-#{$i} .table-data:nth-child(1)::before {
  //   content: ("-" * $i);
  // }
}

// .depth-1 {
//   background: rgba(121, 116, 116, 0.1);
// }
// .depth-2 {
//   background: rgba(121, 116, 116, 0.13);
// }
// .depth-3 {
//   background: rgba(121, 116, 116, 0.16);
// }
// .depth-4 {
//   background: rgba(121, 116, 116, 0.19);
// }

// .table-row:nth-child(even) {
//   background-color: #2e2e32;
// }
</style>
<style lang="scss">
.table-row {
  .table-data.icon {
    padding: 0;

    .table-cell {
      img {
        width: 11px;
      }
    }
  }
}
</style>
