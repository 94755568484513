<template>
  <div class="senioirity-member-wrap">
    <div v-for="item in task_types" :key="item.ttp_id" class="seniority-item">
      <div class="input-wrapper">
        <span>{{ item.ttp_name }}</span>
      </div>
      <div class="controller-wrap">
        <span>{{ getSelected(item)?.sen_epcoef }}</span>
        <img
          src="@/assets/icons/table-delete.svg"
          @click="clearSeniority(item.ttp_id)"
        />
        <basic-dropdown
          :style="{ width: '120px' }"
          :list="seniority"
          :searchable="true"
          :selected="getSelected(item)"
          :labelKey="'sen_name'"
          @selected="setSeniority($event, item.ttp_id)"
        />
      </div>
    </div>

    <basic-button class="save-button" label="Save" @click="saveChanges" />
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
// import { openCustomToast } from "@/assets/js/helpers";
import type { TaskType, UserSeniorityEditItem } from "@/assets/js/types";
import { openCustomToast } from "@/assets/js/helpers";
import { transl } from "@/i18n";
import { ModalStore } from "@/store/modalStore";
const modalStore = ModalStore();

defineEmits(["close"]);

const props = defineProps(["options"]);
const companyStore = CompanyStore();
const task_types = ref<TaskType[]>([]);
const user_seniority = ref<UserSeniorityEditItem[]>([]);

const user_seniority_original = ref<Map<number, number> | null>(null);

const seniority = ref<UserSeniorityEditItem[]>([]);

const data = ref<any>({});

onMounted(() => {
  setupData();
});

async function setupData() {
  data.value.name =
    props.options.data.usr_name + " " + props.options.data.usr_lastname;
  await getSeniority();
  await getUserSeniority();
  getTaskTypes();
}

async function getTaskTypes() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.getTaskTypes(params);
    task_types.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}

async function getSeniority() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.getSeniority(params);
    seniority.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}

async function getUserSeniority() {
  const params: any = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    usr_id: props.options.data.user.usr_id,
  };
  try {
    const res = await api.getUserSeniority(params);
    user_seniority.value = res.data.data;
    user_seniority_original.value = new Map(
      res.data.data.map((el) => [el.ttp_id, el.sen_id])
    );
  } catch (error) {
    console.log(error);
  }
}

function setSeniority(e: UserSeniorityEditItem, ttp_id: number) {
  for (const [index, el] of user_seniority.value.entries()) {
    if (el.ttp_id === ttp_id) {
      user_seniority.value[index] = { ...e, ttp_id };
      return;
    }
  }

  user_seniority.value.push({ ...e, ttp_id });
  // handleSenioiryChange("add", ttp_id, e);
}

function clearSeniority(ttp_id: number) {
  for (let i = 0; i < user_seniority.value.length; i++) {
    const el = user_seniority.value[i];
    if (el.ttp_id === ttp_id) {
      user_seniority.value.splice(i, 1);
      // handleSenioiryChange("remove", ttp_id, el);
      return;
    }
  }
}

function getSelected(item: TaskType): UserSeniorityEditItem | null {
  for (const seniority of user_seniority.value) {
    if (seniority.ttp_id === item.ttp_id) {
      return seniority;
    }
  }
  return null;
}

async function saveChanges() {
  let seniorities_add: { sen_id: number; ttp_id: number }[] | null = [];
  let seniorities_edit: { sen_id: number; ttp_id: number }[] | null = [];
  let seniorities_delete: number[] | null = [];

  for (const el of user_seniority.value) {
    const item = user_seniority_original.value?.get(el.ttp_id);

    if (!item) {
      seniorities_add.push({ ttp_id: el.ttp_id, sen_id: el.sen_id });
    }
    if (item && item[1] !== el.sen_id) {
      seniorities_edit.push({ ttp_id: el.ttp_id, sen_id: el.sen_id });
    }
  }
  if (user_seniority_original.value) {
    for (const [ttp_id, _] of user_seniority_original.value) {
      if (!user_seniority.value.some((el) => el.ttp_id === ttp_id)) {
        seniorities_delete.push(ttp_id);
      }
    }
  }

  if (seniorities_add.length === 0) {
    seniorities_add = null;
  }
  if (seniorities_delete.length === 0) {
    seniorities_delete = null;
  }
  if (seniorities_edit.length === 0) {
    seniorities_edit = null;
  }
  if (!seniorities_add && !seniorities_edit && !seniorities_delete) {
    return;
  }
  try {
    await api.editUserSeniority({
      usr_id: props.options.data.user.usr_id,
      seniorities_add,
      seniorities_delete,
      seniorities_edit,
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    });

    modalStore.setModalData(null);
    openCustomToast("You have successfully changed the user.", "success");
  } catch (error: any) {
    const errData: any = error.response.data;
    if (errData.validation && errData.name) {
      openCustomToast(
        transl(`error.login.${errData.validation}.${errData.name}`),
        "error"
      );
    } else if (errData.message) {
      openCustomToast(transl(`error.login.${errData.message}`), "error");
    }
  }
}
</script>
<style lang="scss" scoped>
.controller-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 9px;
  img {
    cursor: pointer;
  }
}
.senioirity-member-wrap {
  min-width: 316px;

  .save-button {
    margin-top: 30px;
  }
  .seniority-item {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .input-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
</style>
