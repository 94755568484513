<template>
  <div class="hour-info-modal-wrapper">
    <div v-if="props.options.data.tms_shortinfo" class="input-wrapper">
      <div class="infoLabel">Short info:</div>
      <div class="justifySelfStart">{{ props.options.data.tms_shortinfo }}</div>
    </div>
    <div class="input-wrapper">
      <div class="infoLabel">Description:</div>
      <pre>{{ props.options.data.tms_description }}</pre>
    </div>
    <div class="btn-wrapper">
      <basic-button :label="'Close'" @click="emit('close')" />
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps(["options"]);
const emit = defineEmits(["close"]);
</script>
<style lang="scss" scoped>
.input-wrapper {
  margin-bottom: 20px;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .infoLabel {
    align-self: flex-start;
    color: var(--label-color);
  }
  .justifySelfStart {
    align-self: flex-start;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
    line-height: 1.5;
    max-width: 100%;
    padding: 0 20px;
  }
}

.section {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  .part:first-child {
    margin-right: 20px;
    width: 270px;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
</style>
