<template>
  <div
    v-if="props.user?.img_id"
    class="avatar image"
    :style="{
      backgroundImage: `url(${baseURL + '/images/' + props.user.img_id})`,
      backgroundColor: getStyles?.backgroundColor,
      borderColor: getStyles?.borderColor,
    }"
  >
    <img v-if="star" src="../../../assets/icons/star.svg" class="star" />
    <div
      v-if="props.user?.status"
      class="status"
      :style="{ background: '#' + props.user.status.avt_color }"
    ></div>
  </div>
  <div
    v-else-if="props.surplus"
    class="avatar name"
    :style="{
      backgroundColor: defaultBackground,
      borderColor: defaultBorderColor,
    }"
  >
    {{ `+${props.surplus}` }}
  </div>
  <div v-else class="avatar name" :style="getStyles">
    {{ `${props.user?.usr_displayname.substring(0, 1).toUpperCase()}.` }}
    <div
      v-if="props.user?.status"
      class="status"
      :style="{ background: '#' + props.user.status.avt_color }"
    ></div>
  </div>
</template>
<script setup lang="ts">
import { type User, type Company } from "@/assets/js/types";
import { colorMap } from "@/assets/js/values";
import { baseURL } from "@/api/config";
import { newShade } from "@/assets/js/getColorShade";

type AvatarProps = {
  user?: User;
  company?: Company;
  surplus?: number;
  star?: boolean;
};
const props = defineProps<AvatarProps>();
const defaultBackground = "#8451C4";
const defaultBorderColor = "#CEAFF3";

const getStyles = computed(() => {
  if (
    props.user?.usr_color &&
    Object.keys(colorMap).includes(String(props.user.usr_color))
  ) {
    return colorMap[props.user.usr_color];
  } else if (props.user) {
    return {
      // backgroundColor: defaultBackground,
      // borderColor: defaultBorderColor,
      backgroundColor: props.user?.usr_color,
      borderColor: newShade(props.user?.usr_color),
    };
  } else if (props.company) {
    return {
      backgroundColor: "#658cd8",
      borderColor: newShade("#658cd8"),
    };
  }
});
</script>
<style lang="scss" scoped>
.avatar {
  position: relative;

  .status {
    position: absolute;
    bottom: -2px;
    right: -2px;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid white;
  }
}

.avatar.name {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  box-sizing: border-box;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: var(--color-text-button);
  cursor: default;
}

.avatar.image {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  box-sizing: border-box;
  border: 2px solid white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .star {
    position: absolute;
    top: -5px;
    right: -2px;
  }
}
</style>
