<template>
  <div class="toast-wrapper" :class="props.type">
    <img class="icon" :src="getImg" alt="" />
    <div class="content-wrapper">
      <h2 class="title">{{ props.title }}</h2>
      <p v-if="message" class="message">{{ message }}</p>
    </div>
    <img
      class="close"
      src="@/assets/icons/toastClose.svg"
      alt=""
      @click="emit('close-toast')"
    />
  </div>
</template>

<script setup lang="ts">
import successImg from "../assets/icons/toastSeccess.svg";
import errorImg from "../assets/icons/toastError.svg";
import warnImg from "../assets/icons/toastWarn.svg";
import infoImg from "../assets/icons/toastInfo.svg";
import { type ToastTypes } from "@/assets/js/types";

const props = defineProps<{
  type: ToastTypes;
  title: string;
  message?: string;
}>();

const emit = defineEmits(["close-toast"]);
const iconMap = {
  success: successImg,
  error: errorImg,
  warn: warnImg,
  info: infoImg,
};

const getImg = computed(() => {
  return iconMap[props.type];
});
</script>

<style lang="scss" scoped>
.toast-wrapper {
  height: 100%;
  border-radius: 1px;
  background: #2e2e32;
  background: var(--background-modal);
  padding: 10px 10px 10px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    margin-right: 13px;
    height: 20px;
  }

  .close {
    height: 10px;
    margin-left: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 10px;

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      // color: #ecedf2;
      color: var(--color-text-modal) !important;
    }

    .title::first-letter {
      text-transform: capitalize;
    }

    .message {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #acacad;
    }
  }
}

.toast-wrapper.info {
  border-left: 5px solid #1274ff;
}

.toast-wrapper.success {
  border-left: 5px solid #5dd963;
}

.toast-wrapper.error {
  border-left: 5px solid #ff2e12;
}

.toast-wrapper.warn {
  border-left: 5px solid #ffbd12;
}
</style>
