<template>
  <div class="alt-app-header-wrapper">
    <div class="logo-wrapper">
      <!-- <img src="../assets/images/OmniTaskerLogo.png" alt="logo image" /> -->
    </div>
  </div>
</template>
<script scoped lang="ts"></script>
<style lang="scss" scoped>
.alt-app-header-wrapper {
  width: 100%;
  height: var(--vt-c-header-height);
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  box-sizing: border-box;
  max-width: 1744px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo-wrapper {
    width: 200px;

    img {
      height: 25px;
    }
  }
}
</style>
