import { type Hour } from "@/assets/js/types";
import api from "@/api";

export const HoursStore = defineStore("HoursStore", () => {
  const hours = ref<Hour[]>([]);
  const lastTaskId = ref<number | null>(null);
  const isDescriptionFilled = ref<boolean>(false);

  async function getHours(id: number) {
    lastTaskId.value = id;
    try {
      const params: any = {
        tsk_id: id,
      };
      const res = await api.getHours(params);
      hours.value = res.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function refresh() {
    if (lastTaskId.value) {
      await getHours(lastTaskId.value);
    }
  }
  function setDescriptionFilled() {
    isDescriptionFilled.value = true;
  }

  function clearDescriptionFilled() {
    isDescriptionFilled.value = false;
  }

  return {
    isDescriptionFilled,
    hours,
    getHours,
    refresh,
    setDescriptionFilled,
    clearDescriptionFilled,
  };
});
