<template>
  <div v-if="!task" class="task-wrapper">
    <div class="task-header">
      <span>Error loading task</span>
      <div class="right-wrapper">
        <img
          class="close"
          src="../../assets/icons/toastClose.svg"
          alt=""
          @click="closeTask"
        />
      </div>
    </div>
  </div>
  <div v-else class="scrollbar task-wrapper">
    <div class="task-header">
      <!-- <status-indicator :status="task.status" style="margin-right: 10px" /> -->
      <span>{{ task.tsk_name }}</span>
      <div class="right-wrapper">
        <img
          class="close"
          src="../../assets/icons/toastClose.svg"
          alt=""
          @click="closeTask"
        />
      </div>
    </div>
    <page-switcher
      :model-value="activePage"
      :pages="pages"
      @update:model-value="updateActivePage"
    />
    <component
      :is="pagesMap[activePage.value]"
      :details="task"
      :statuses="statuses"
      :hours="hours"
      @update="
        getTaskDetails();
        getTaskHours();
        emit('getTasks');
      "
    />
  </div>
</template>
<script setup lang="ts">
import TaskDetails from "./taskDetails.vue";
import TaskChat from "./taskChat.vue";
import TaskEstimate from "./taskEstimate.vue";
import TaskHours from "./taskHours.vue";
import { TasksStore } from "@/store/tasksStore";
import { ProjectStore } from "@/store/projectStore";
import { checkPermission } from "@/assets/js/helpers";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { type Page } from "@/assets/js/types";

const tasksStore = TasksStore();
const projectStore = ProjectStore();
const router = useRouter();
const companyStore = CompanyStore();
const props = defineProps(["changeData"]);

const pagesMap: any = {
  taskDetails: TaskDetails,
  taskChat: TaskChat,
  taskEstimate: TaskEstimate,
  taskHours: TaskHours,
};

const pages = ref<Page[]>([
  {
    id: 1,
    label: "Details",
    value: "taskDetails",
    section: "details",
  },
  // {
  //   id: 2,
  //   label: "Chat",
  //   value: "taskChat",
  //   section: "chat",
  // },
  {
    id: 3,
    label: "Estimate",
    value: "taskEstimate",
    section: "estimate",
    show: checkPermission(["estimations-view"]),
  },
  {
    id: 4,
    label: "Hours",
    value: "taskHours",
    section: "hours",
    show: checkPermission(["hours-view"]),
  },
]);
const activePage = ref<Page>(pages.value[0]);
const task: any = ref(null);
const statuses: any = ref(null);
const hours: any = ref(null);

onMounted(() => {
  getStatuses();
  getTaskDetails();
  getTaskHours();
  if (router.currentRoute.value.params.section) {
    const page = pages.value.find(
      (el) => el.section === router.currentRoute.value.params.section
    ) as Page;

    if (page?.show === undefined || page.show) {
      activePage.value = page;
    }
  }
});

async function getStatuses() {
  try {
    const params: any = {
      cmp_id: companyStore.activeCompany?.cmp_id,
    };
    const res = await api.getStatuses(params);
    statuses.value = res.data.status;
  } catch (error) {
    console.log(error);
  }
}

function updateActivePage(page: any) {
  // set active page and update router params:
  activePage.value = page;
  const params = JSON.parse(JSON.stringify(router.currentRoute.value.params));
  params.section = activePage.value.section;
  // check what page we are on:
  let route;
  if (params.projectId) {
    route = "MyProjectTaskSection";
    projectStore.setSelectedProjectTaskSection(activePage.value.section);
  } else {
    route = "MyTaskSection";
    tasksStore.setSelectedTaskSection(activePage.value.section);
  }
  router.push({ name: route, params });
}

const taskId = computed(() => {
  return Number(router.currentRoute.value.params.taskId);
});

watch([taskId, () => props.changeData], async () => {
  await getStatuses();
  getTaskDetails();
  getTaskHours();
});

async function getTaskHours() {
  try {
    if (taskId.value) {
      const res = await api.getTaskHours(taskId.value);
      hours.value = res.data.data;
    }
  } catch (error) {
    console.log(error);
  }
}

async function getTaskDetails() {
  if (taskId.value) {
    try {
      const params: any = {
        cmp_id: companyStore.getActiveCompany?.cmp_id,
      };
      const res = await api.getTaskDetails(params, taskId.value);
      task.value = res.data.task;
      emit("set-task", task.value);
    } catch (error: any) {
      if (error.response.data.message === "redirect") {
        const company: any = companyStore.companies?.find(
          (company) => company.cmp_id === error.response.data.cmp_id
        );
        await companyStore.replaceActiveCompany(company);
        router.go(-1);
      }
    }
  }
}

// const taskStore = TasksStore();
const emit = defineEmits(["close", "getTasks", "set-task"]);

function closeTask() {
  emit("close");
}
</script>
<style lang="scss" scoped>
.task-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  height: 100%;
  // height: calc(100% - 30px);
  overflow: auto;

  .task-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    .right-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      margin-left: auto;
    }

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
    }

    span::first-letter {
      text-transform: capitalize;
    }

    .close {
      height: 15px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
</style>
