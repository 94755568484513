<template>
  <div ref="message_wrap" class="task-chat-wrap">
    <div ref="intersector" class="intersector"></div>
    <div class="task-chat-header"></div>
    <div class="message-wrap">
      <template v-for="message in messageStore.getMessages" :key="message.id">
        <MessageComp
          v-if="isMessage(message)"
          :message="(message as Message)"
        />
        <ChatEvent v-else :chat-event="(message as ChatEventInterface)" />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
// import { openCustomToast } from "@/assets/js/helpers";
import { type ChatEventInterface } from "@/assets/js/types";
import type { ChatItem, Message } from "@/assets/js/types";
import { MessageStore } from "@/store/messageStore";

const router = useRouter();
const messageStore = MessageStore();
// const observer = new IntersectionObserver(addOlderMessages);

// refs
const message_wrap = ref();
const intersector = ref();

const taskId = computed(() => {
  return Number(router.currentRoute.value.params.taskId);
});

onMounted((): void => {
  if (!taskId.value) {
    throw new Error("Invalid task id");
  }
  if (taskId.value !== messageStore.getActiveTaskId) {
    // messageStore.updateTaskId(taskId.value);
    // getOlderMessages();
  }
  scrollToBottom();
  // if (intersector.value) {
  //   observer.observe(intersector.value);
  // }
});

onBeforeUnmount((): void => {
  // if (intersector.value) {
  //   observer.unobserve(intersector.value);
  //   observer.disconnect();
  // }
});

const isMaxScroll = ref(false);

watch(() => messageStore.getMessages, scrollHandler, { flush: "pre" });

function scrollToBottom() {
  message_wrap.value?.scrollTo({
    top: message_wrap.value.scrollHeight,
  });
}

async function scrollHandler() {
  isMaxScroll.value =
    message_wrap.value?.scrollHeight ===
    message_wrap.value?.clientHeight + message_wrap.value?.scrollTop;
  if (!message_wrap.value) {
    return;
  }
  if (isMaxScroll.value) {
    await nextTick();
    scrollToBottom();
  }
}

// function addOlderMessages() {
//   messageStore.oldMessages.unshift(
//     ...messageStore.oldMessages.map((el) => {
//       el.id = Math.floor(Math.random() * 100000);
//       return el;
//     })
//   );
// }

function isMessage(message: ChatItem) {
  return (message as Message).origin !== undefined;
}

// function addMessage() {
//   const new_msg = Object.assign(
//     {},
//     messageStore.oldMessages[messageStore.oldMessages.length - 1]
//   );
//   new_msg.id++;
//   new_msg.message = "asdasd";
//   messageStore.oldMessages.push(new_msg);
// }

// async function getOlderMessages() {
//   try {
//     const res = await messageStore.getOlderMessages();
//     if (res.data.data) {
//       messageStore.updateMessages(res.data.data);
//       messageStore.updateMessageLimit(res.data.message_count);
//     }
//   } catch (error) {
//     openCustomToast("Couldn't get messages", "error");
//   }
// }
</script>
<style lang="scss" scoped>
.task-chat-wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.message-wrap {
  width: 100%;
}
</style>
