import type { Modal } from "../assets/js/types";

export const ModalStore = defineStore("ModalSore", () => {
  const modalData = ref<Modal | null>(null);

  const getModalData = computed(() => {
    return modalData.value;
  });

  function setModalData(data: Modal | null) {
    modalData.value = data;
  }

  return {
    modalData,
    getModalData,
    setModalData,
  };
});
