<template>
  <div class="edit-hours-wrapper">
    <!-- :style="{background: showDateInput? 'red': ''}" -->
    <div class="section task-name">
      <div class="input-wrapper">
        <h1>
          {{ $props.options?.name }}
        </h1>
      </div>
    </div>
    <!-- <div class="section description">
      <div class="input-wrapper">
        <basic-input
          v-model="data.tms_shortinfo"
          placeholder="Title"
          label="Title"
          type="text"
        />
      </div>
    </div> -->
    <div class="section central-content">
      <!-- <div class="central-content"> -->
      <div
        class="input-wrapper space"
        :style="{ 'margin-left': '0px', width: '200px' }"
      >
        <basic-dropdown
          class="types"
          :list="taskTypes"
          :selected="data.taskType"
          :searchable="true"
          :labelKey="'ttp_name'"
          :title="'Task type'"
          :disabled="isApproved"
          @selected="setTaskType"
        />
      </div>
      <!-- </div> -->
      <!-- <div class="time-central-content">
        <span class="cc-desc">Time</span>
        <div class="time-buttons-wrapper">
          <time-button
            v-model="data.hours"
            placeholder="Hours"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            type="number"
            :maxValueCharacter="2"
            style="margin-right: 10px"
            :disable="isApproved"
          />
          :
          <time-button
            v-model="data.minutes"
            placeholder="Minutes"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            type="number"
            :maxValueCharacter="2"
            style="margin-left: 10px"
            :disable="isApproved"
          />
        </div>
      </div> -->
      <div class="time-central-content-new time-tipe input-wrapper types space">
        <span v-if="!showDateInput" class="cc-desc" @dblclick="setDateInput">
          Time
        </span>
        <span
          v-else-if="showDateInput"
          class="cc-desc"
          style="margin-bottom: 7px"
          @dblclick="setDateInput"
          >Date</span
        >

        <div v-show="showDateInput" class="" style="width: 126px; height: 34px">
          <Datepicker
            v-model="data.tms_date"
            :enable-time-picker="false"
            :clearable="false"
            format="dd.MM.yyyy"
            :auto-apply="true"
            :dark="true"
            input-class-name="datepicker-input"
            menu-class-name="datepicker-dropdown"
            style="min-height: 34px"
          />
          <!-- @update:model-value="setCustomPeriod" -->
        </div>
        <div v-show="!showDateInput" class="time-buttons-wrapper">
          <TimeButton
            v-model="data.hours"
            placeholder="HH"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            :maxValueCharacter="2"
            style="margin-right: 10px"
            :disable="isApproved"
          />
          :
          <TimeButton
            v-model="data.minutes"
            placeholder="MM"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            :maxValueCharacter="2"
            style="margin-left: 10px"
            :disable="isApproved"
          />
        </div>
      </div>
      <div
        v-if="
          checkPermission(['hours-approve']) &&
          (data.hours_approved || data.hours_approved === 0)
        "
        class="time-central-content-new time-tipe space"
      >
        <span class="cc-desc">Approved</span>
        <div class="time-buttons-wrapper">
          <TimeButton
            v-model="data.hours_approved"
            placeholder="HH"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            :maxValueCharacter="2"
            style="margin-right: 10px"
            :disable="isApproved"
          />
          :
          <TimeButton
            v-model="data.minutes_approved"
            placeholder="MM"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            :maxValueCharacter="2"
            style="margin-left: 10px"
            :disable="isApproved"
          />
        </div>
      </div>
      <div
        v-if="
          checkPermission(['project-manage']) &&
          (data.hours_billed || data.hours_billed === 0)
        "
        class="time-central-content-new time-tipe space"
      >
        <span class="cc-desc">Billed</span>
        <div class="time-buttons-wrapper">
          <TimeButton
            v-model="data.hours_billed"
            placeholder="HH"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            :maxValueCharacter="2"
            style="margin-right: 10px"
            :disable="isApproved"
          />
          :
          <TimeButton
            v-model="data.minutes_billed"
            placeholder="MM"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            :maxValueCharacter="2"
            style="margin-left: 10px"
            :disable="isApproved"
          />
        </div>
      </div>
      <!-- <div class="central-content" style="margin-right: 0"> -->
      <div class="input-wrapper types space">
        <basic-dropdown
          :list="hoursType"
          :selected="data.hourType"
          :searchable="true"
          :labelKey="'tst_name'"
          :title="'Hour type'"
          :disabled="isApproved"
          :style="{ width: '120px' }"
          @selected="setHourType"
        />
      </div>
      <!-- </div> -->
      <!-- <div
        v-if="checkPermission(['hours-manage'])"
        class="central-content"
      > -->
      <div
        v-if="checkPermission(['hours-manage'])"
        class="input-wrapper types space"
        :style="{ 'margin-right': '0px', width: '220px' }"
      >
        <basic-dropdown
          :list="users"
          :selected="data.user"
          :searchable="true"
          :labelKey="'usr_displayname'"
          :title="'Assigned to'"
          :disabled="isApproved"
          @selected="setUser"
        />
      </div>
      <!-- </div> -->
    </div>
    <div class="section central-content">
      <div style="flex-grow: 1">
        <div style="flex-grow: 1">
          <basic-input
            v-model="data.tms_shortinfo"
            placeholder="keywords / short info"
            label="keywords / short info"
            type="text"
            :disabled="isApproved"
            :setFocus="true"
          />
        </div>
      </div>
    </div>
    <div class="section description">
      <div>
        <basic-input
          v-model="data.tms_description"
          placeholder="Description"
          label="Description"
          type="textarea"
          class="desc-input"
          :disabled="isApproved"
          @input="checkDescription"
        />
      </div>
    </div>
  </div>
  <div class="btn-wrapper">
    <!-- <div class="approved-content">
      <div class="approve-hours">
        <button
          v-if="!isApproved && checkPermission(['hours-approve'])"
          @click="toggleApprovingHours"
        >
          Approve working hours</button
        ><button v-if="isApproved" @click="toggleApprovingHours">Cancel</button>
      </div>
    </div> -->
    <!-- <div v-if="isApproved" class="approve-time">
      <span class="cc-desc">Approve Time</span>
      <div class="time-buttons-wrapper">
        <time-button
          v-model="data.hours"
          placeholder="Hours"
          label="Hours"
          :min="0"
          :max="16"
          :step="1"
          type="number"
          :maxValueCharacter="2"
          style="margin-right: 10px"
        />
        :
        <time-button
          v-model="data.minutes"
          placeholder="Minutes"
          label="Minutes"
          :min="0"
          :max="60"
          :step="15"
          type="number"
          :maxValueCharacter="2"
          style="margin-left: 10px"
        />
      </div>
    </div> -->
    <div class="confirm-buttons">
      <basic-button
        v-if="checkPermission(['hours-approve'])"
        style="width: 200px"
        label="Approve these hours"
        @click="approveHours"
      />
      <basic-button
        v-if="!isApproved"
        style="width: 130px"
        label="Save changes"
        :disabled="disable"
        @click="saveHours"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { ModalStore } from "@/store/modalStore";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";
import { type TaskType, type HourType } from "@/assets/js/types";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import dayjs from "dayjs";
import { HoursStore } from "@/store/hoursStore";

defineEmits(["close"]);
const props = defineProps(["options"]);
const disable = ref<boolean>(false);

const modalStore = ModalStore();
const companyStore = CompanyStore();
const hoursStore = HoursStore();

const taskTypes = ref<TaskType[]>([]);
const hoursType = ref<HourType[]>([]);
const users = ref<any>([]);

// function toggleApprovingHours() {
//   isApproved.value = !isApproved.value;
// }

const isApproved = ref<boolean>(false);

const router = useRouter();
// params:
const oldData: any = reactive({
  tms_shortinfo: null,
  tms_description: null,
  hours: 0,
  minutes: 0,
  taskType: null,
  hourType: null,
  user: null,
  hours_approved: null,
  minutes_approved: null,
  hours_billed: null,
  minutes_billed: null,
  tms_date: null,
});
const data: any = reactive({
  tms_shortinfo: null,
  tms_description: null,
  hours: 0,
  minutes: 0,
  taskType: null,
  hourType: null,
  user: null,
  hours_approved: null,
  minutes_approved: null,
  hours_billed: null,
  minutes_billed: null,
  tms_date: null,
});

function checkDescription() {
  if (data.tms_description !== oldData.tms_description) {
    hoursStore.setDescriptionFilled();
  } else {
    hoursStore.clearDescriptionFilled();
  }
}

onMounted(() => {
  setupData();
  window.addEventListener("keydown", keydownCtrl);
  window.addEventListener("keyup", keyupCtrl);
  // document.addEventListener("keyup", handleEscape);
  // document.removeEventListener("keyup", handleEscape);
});
onUnmounted(() => {
  window.removeEventListener("keydown", keydownCtrl);
  window.removeEventListener("keyup", keyupCtrl);
  hoursStore.clearDescriptionFilled();
});

async function getUsers() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.searchUsers(params);
    users.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

function setUser(u: any) {
  data.user = u;
}

function setupData() {
  oldData.tms_shortinfo = props.options.data.tms_shortinfo;
  oldData.tms_description = props.options.data.tms_description;
  oldData.tms_date = props.options.data.tms_date;
  oldData.hours = parseInt(props.options.data.tms_amount.split(":")[0]);
  oldData.minutes = parseInt(props.options.data.tms_amount.split(":")[1]);
  if (props.options.data.tms_approved) {
    oldData.hours_approved = parseInt(
      props.options.data.tms_approved.split(":")[0]
    );
    oldData.minutes_approved = parseInt(
      props.options.data.tms_approved.split(":")[1]
    );
  }
  if (props.options.data.tms_billed) {
    oldData.hours_billed = parseInt(
      props.options.data.tms_billed.split(":")[0]
    );
    oldData.minutes_billed = parseInt(
      props.options.data.tms_billed.split(":")[1]
    );
  }

  oldData.taskType = {
    ttp_id: props.options.data.ttp_id,
    ttp_name: props.options.data.ttp_name,
  };
  oldData.hourType = {
    tst_id: props.options.data.tst_id,
    tst_name: props.options.data.tst_name,
  };
  oldData.user = props.options.data.user;

  data.tms_shortinfo = props.options.data.tms_shortinfo;
  data.tms_description = props.options.data.tms_description;
  data.tms_date = props.options.data.tms_date;
  data.hours = parseInt(props.options.data.tms_amount.split(":")[0]);
  data.minutes = parseInt(props.options.data.tms_amount.split(":")[1]);
  data.hours_approved = props.options.data.tms_approved
    ? parseInt(props.options.data.tms_approved.split(":")[0])
    : undefined;

  data.minutes_approved = props.options.data.tms_approved
    ? parseInt(props.options.data.tms_approved.split(":")[1])
    : undefined;

  data.hours_billed = props.options.data.tms_billed
    ? parseInt(props.options.data.tms_billed.split(":")[0])
    : undefined;

  data.minutes_billed = props.options.data.tms_billed
    ? parseInt(props.options.data.tms_billed.split(":")[1])
    : undefined;
  data.taskType = {
    ttp_id: props.options.data.ttp_id,
    ttp_name: props.options.data.ttp_name,
  };
  data.hourType = {
    tst_id: props.options.data.tst_id,
    tst_name: props.options.data.tst_name,
  };
  data.user = props.options.data.user;
  disable.value = true;

  setTimeout(() => {
    disable.value = true;
  }, 15);
}

async function getTaskTypes() {
  try {
    const params: any = {
      cmp_id: companyStore.activeCompany?.cmp_id,
      view_all: true,
    };
    const res = await api.getTaskTypes(params);
    taskTypes.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getTaskTypes();

function setTaskType(taskType: TaskType) {
  data.taskType = taskType;
}

async function getHoursType() {
  try {
    const params: any = {
      cmp_id: companyStore.activeCompany?.cmp_id,
    };
    const res = await api.getHourTypes(params);
    hoursType.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getHoursType();

function setHourType(hourType: HourType) {
  data.hourType = hourType;
}

async function saveHours() {
  if (!data.hours && !data.minutes) {
    openCustomToast("The 'Time' field is required", "error");
    return;
  }
  if (
    !data.tms_shortinfo ||
    data.tms_shortinfo?.length <= 0 ||
    data.tms_shortinfo.trim()?.length <= 0
  ) {
    openCustomToast("keywords / short field is required", "error");
    return;
  }
  if (
    oldData.tms_shortinfo !== data.tms_shortinfo &&
    data.tms_shortinfo?.length > 255
  ) {
    openCustomToast("keywords / short info is too big", "error");
    return;
  }
  if (data.taskType && data.hourType) {
    try {
      data.tms_id = props.options.data.tms_id;
      data.ttp_id = data.taskType.ttp_id;
      data.tst_id = data.hourType.tst_id;
      data.tsk_id = router.currentRoute.value.params.taskId
        ? router.currentRoute.value.params.taskId
        : router.currentRoute.value.params.projectId;
      data.usr_id = data.user.usr_id;

      let cleanedParams: any = null;
      // Object.keys(data).forEach((key) => {
      //   if (data[key] !== null) {
      //     cleanedParams[key] = data[key];
      //   }
      // });
      cleanedParams = setParams();

      if (cleanedParams === null) {
        openCustomToast("No changes.", "error");
        return;
      }

      cleanedParams.tms_id = data.tms_id;
      cleanedParams.tst_id = data.tst_id;
      cleanedParams.ttp_id = data.ttp_id;

      await api.editHours(cleanedParams);
      if (props.options.update) {
        props.options.update();
      }
      modalStore.setModalData(null);
      hoursStore.clearDescriptionFilled();
      openCustomToast("You have successfully updated the hours.", "success");
    } catch (error) {
      console.log(error);
      openCustomToast("An error occurred while changing the hours.", "error");
    }
  } else {
    openCustomToast(
      "The fields 'Task type' and 'Hour type' are required",
      "error"
    );
  }
}
async function approveHours() {
  const params = {
    tms_id: props.options.data.tms_id,
    hours_approved: data.hours,
    minutes_approved: data.minutes,
  };
  try {
    await api.editHours(params);
    modalStore.setModalData(null);
    hoursStore.clearDescriptionFilled();
    openCustomToast("Successfully approved hours.", "success");
    if (props.options.update) {
      props.options.update();
    }
  } catch (error) {
    console.log(error);
  }
}

//
function setParams() {
  let params = {};
  Object.keys(data).forEach((key) => {
    if (
      oldData.hasOwnProperty(key) &&
      data[key] !== null &&
      data[key] !== oldData[key]
    ) {
      if (key === "tms_date") {
        params[key] = dayjs.utc(data[key]).local().format("YYYY-MM-DD HH:mm");
      } else if (typeof data[key] === "object" && key !== "tms_date") {
        for (let x in data[key]) {
          if (data[key][x] !== oldData[key][x]) {
            params[key] = data[key];
          }
        }
      } else {
        if (key === "hours" || key === "minutes") {
          params["hours"] = data["hours"];
          params["minutes"] = data["minutes"];
        } else if (key === "hours_approved" || key === "minutes_approved") {
          params["hours_approved"] = data["hours_approved"];
          params["minutes_approved"] = data["minutes_approved"];
        } else if (key === "hours_billed" || key === "minutes_billed") {
          params["hours_billed"] = data["hours_billed"];
          params["minutes_billed"] = data["minutes_billed"];
        } else {
          params[key] = data[key];
        }
      }
    }
  });
  if (Object.keys(params).length === 0) {
    return null;
  }
  return params;
}

// funcionality for change date

const showDateInput = ref(false);
const isCtrlPressed = ref(false);
function keydownCtrl(e: any) {
  if (e.ctrlKey && checkPermission(["project-manage"])) {
    // || evtobj.altKey || evtobj.shiftKey
    isCtrlPressed.value = true;
  }
}
function keyupCtrl(e: any) {
  // if (e.ctrlKey ) // || evtobj.altKey || evtobj.shiftKey
  if (e.key === "Control") {
    isCtrlPressed.value = false;
  }
}
function setDateInput(e: any) {
  if (isCtrlPressed.value) {
    showDateInput.value = true;
  } else {
    showDateInput.value = false;
  }
}
watch(
  () => data,
  () => {
    disable.value = false;
  },
  { deep: true }
);
</script>
<style lang="scss" scoped>
.edit-hours-wrapper {
  display: flex;
  flex-direction: column;

  .task-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0 15px 0 !important;

    .approve-hours {
      button {
        padding: 10px;
        border-radius: 10px;
        background-color: green;
        border: 1px solid var(--vt-c-divider-dark-2);
        cursor: pointer;
      }
    }

    .approve-time {
      text-align: center;

      .time-buttons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .section {
    margin: 30px 0 33px;

    .input-wrapper {
      // margin-bottom: 20px;
      // margin: 0 7px 20px 0px;

      h1 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        // color: #ecedf2;
        color: var(--main-color);
        margin: 5px 0;
      }

      h1::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .central-content {
    display: flex;
    margin: 20px 0 20px;
    .space {
      margin: 0 15px 0 15px;
    }
    .time-central-content-new {
      display: flex;
      flex-direction: column;
      // color: #282D3D;
      align-items: center;
      // margin: 0 5px 0 5px;

      .cc-desc {
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
        margin-bottom: 22px;
        color: var(--label-color);
        align-self: flex-start;
      }

      .time-buttons-wrapper {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        background: var(--background-input) !important;
        color: #555f76;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #282d3d;
        border: 1px solid var(--link-border-default-color);
        border-radius: 10px;
        padding: 0 10px 0 10px;
        height: 36px;
      }
    }

    .central-content {
      // margin: 0 10px 0 0px;
      min-height: 80px;
      min-width: 270px;

      .input-wrapper {
        min-width: 270px;

        .types {
          min-width: 223px;
        }
      }
    }

    .time-central-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 40px 0 0;

      .cc-desc {
        font-family: Archivo;
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
      }
    }

    .time-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -16px;
    }
  }
}
.confirm-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn-wrapper {
  display: flex;
  justify-content: right;
  align-items: center;

  .approved-content {
    display: flex;
    align-items: center;

    .approve-hours {
      margin: 0 30px 0 0;

      button {
        padding: 10px;
        border-radius: 10px;
        background-color: green;
        border: 1px solid var(--vt-c-divider-dark-2);
        cursor: pointer;
      }
    }
  }

  .approve-time {
    text-align: center;

    .time-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<style lang="scss">
.time-central-content-new {
  .dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg.datepicker-input {
    height: 34px !important;
  }
}
</style>
