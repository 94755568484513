<template>
  <div class="add-hours-wrapper">
    <div class="section task-name">
      <h1>
        {{ props.options?.data?.tsk_name }}
      </h1>
    </div>
    <div class="section central-content">
      <div class="left-central-content">
        <div class="input-wrapper">
          <basic-dropdown
            class="types"
            :list="taskTypes"
            :selected="params.taskType"
            :searchable="true"
            :labelKey="'ttp_name'"
            :title="'Task type'"
            :style="{ width: '120px' }"
            :focus-input="true"
            @selected="setTaskType"
          />
        </div>
      </div>
      <div class="right-central-content">
        <span class="cc-desc">Time</span>
        <div class="time-buttons-wrapper">
          <time-button
            v-model="params.hours"
            placeholder="HH"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            :maxValueCharacter="2"
            style="margin-right: 10px"
          />:
          <time-button
            v-model="params.minutes"
            placeholder="MM"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            :maxValueCharacter="2"
            style="margin-left: 10px"
          />
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <basic-button label="Save" style="width: 100px" @click="saveEstimation" />
    </div>
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { ModalStore } from "@/store/modalStore";
import { openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";
import { type TaskType } from "@/assets/js/types";

defineEmits(["close"]);
const props = defineProps(["options"]);

const modalStore = ModalStore();
const companyStore = CompanyStore();

const taskTypes = ref<TaskType[]>([]);
const router = useRouter();
// params:
const params: any = reactive({
  hours: null,
  minutes: null,
  taskType: null,
});

async function getTaskTypes() {
  try {
    const params: any = {
      cmp_id: companyStore.activeCompany?.cmp_id,
      view_all: true,
    };
    const res = await api.getTaskTypes(params);
    taskTypes.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getTaskTypes();

function setTaskType(taskType: TaskType) {
  params.taskType = taskType;
}

async function saveEstimation() {
  if (!params.hours) {
    openCustomToast("The 'Time' field is required", "error");
    return;
  }
  if (params.taskType) {
    try {
      params.ttp_id = params.taskType.ttp_id;
      params.tsk_id = router.currentRoute.value.params.taskId
        ? router.currentRoute.value.params.taskId
        : router.currentRoute.value.params.projectId;
      await api.createEstimation(params);
      if (props.options.update) {
        props.options.update();
      }
      modalStore.setModalData(null);
      openCustomToast("You have successfully added a estimate.", "success");
    } catch (error: any) {
      if (error.response.data.message === "estimation-exists") {
        openCustomToast(
          "An estimate for this task type already exists.",
          "error"
        );
      } else {
        openCustomToast("An error occurred while adding an estimate.", "error");
      }
    }
  } else {
    openCustomToast("The field 'Task type' is required", "error");
  }
}

function setOneHour() {
  params.hours = 1;
  params.minutes = 0;
}

onMounted(() => {
  setOneHour();
});
</script>
<style lang="scss" scoped>
.add-hours-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .section {
    margin: 0;

    .input-wrapper {
      margin-bottom: 20px;

      h1 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        // color: #ecedf2;
        color: var(--color-text);
      }
    }
  }

  .task-name {
    margin-bottom: 40px;

    h1 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #ecedf2;
      color: var(--color-text);
    }

    h1::first-letter {
      text-transform: uppercase;
    }
  }

  .central-content {
    display: flex;
    margin: 0 0 22px;

    .left-central-content {
      margin: 0 67px 0 0;
      min-width: 270px;

      .input-wrapper {
        .types {
          min-width: 223px;
        }
      }
    }

    .right-central-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cc-desc {
        font-family: Archivo;
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
      }
    }

    .time-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -16px;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
