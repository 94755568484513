<template>
  <label
    v-if="getEventForTabele"
    class="container pa-1 pr-2"
    :class="{ containerDisabled: !editable }"
    :title="label"
  >
    <div class="label">{{ label }}</div>
    <input
      v-if="editable"
      type="checkbox"
      :checked="value"
      @change="emitChangeValue($event)"
    />
    <input
      v-else
      type="checkbox"
      class="disabled"
      :checked="value"
      disabled
      readonly
    />

    <span class="checkmark"></span>
  </label>

  <label v-else class="container pa-1 pr-2" :title="label">
    <div class="label">{{ label }}</div>
    <input type="checkbox" :checked="modelValue" @change="emitChange($event)" />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: "BasicCheckbox",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    getEventForTabele: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      required: false,
      default: true,
    },
  },
  emits: ["update:modelValue", "changeValue"],
  setup(props, { emit }) {
    function emitChange(e) {
      emit("update:modelValue", e.target.checked);
    }
    function emitChangeValue(e) {
      emit("changeValue", e.target.checked);
    }

    return {
      emitChange,
      emitChangeValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  height: 25px;
  user-select: none;
  transition-duration: var(--vt-c-transition-duration);
  padding: 4px;

  .label {
    padding-left: 35px;
    // font-weight: 600;
    // font-size: 17px;
    // line-height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    // text-align: center;
    color: var(--color-text);
    // height: 20px;
    padding-top: 3px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    background: var(--background-input) !important;
  }

  input:checked ~ .checkmark {
    background-color: var(--vt-c-active);
    border: 2px solid var(--vt-c-active);
  }

  input:checked ~ .checkmark::after {
    display: block;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 22px;
    width: 22px;
    // background-color: transparent;
    // background: var(--background-input)!important;
    border: 2px solid var(--checkbox-border-color);
    box-sizing: border-box;
    border-radius: 8px;
    transition-duration: var(--vt-c-transition-duration);
  }

  .checkmark::after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid var(--color-background);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transform: rotate(45deg);
  }

  // disabled
  input.disabled {
    background: transparent !important;
  }

  .disabled:checked ~ .checkmark {
    background-color: transparent;
    border: transparent;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }

  .disabled ~ .checkmark {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 22px;
    width: 22px;
    // background-color: transparent;
    // background: var(--background-input)!important;
    // border: 2px solid var(--checkbox-border-color);
    border: transparent;
    box-sizing: border-box;
    border-radius: 8px;
    transition-duration: var(--vt-c-transition-duration);
  }

  .disabled ~ .checkmark::after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid var(--vt-c-active);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transform: rotate(45deg);
  }

  // end disabled
}

.container:hover {
  // background: rgb(222 226 233 / 35%);
  border-radius: 6px;

  .checkmark {
    border: 2px solid var(--vt-c-divider-dark-2);
    // background: transparent;
  }
}

// disabled hover
.containerDisabled:hover {
  background: transparent;
  border-radius: 6px;

  .checkmark {
    border: 2px solid var(--vt-c-divider-dark-2);
    border: transparent;
    // background: transparent;
  }
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
</style>
