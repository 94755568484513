<template>
  <div class="parentWrapp">
    <div class="wrapp-progress">
      <div
        v-if="data > 0"
        class="color"
        :style="{
          width: data + '%',
          background: setBackground(),
        }"
      ></div>
      <div class="name-progress">{{ data + "%" }}</div>
    </div>
    <div v-if="showHours" class="wrapp-hours">
      {{
        hours?.spent && hours.spent > 0
          ? hours.spent
          : hours?.estimate
          ? hours.spent
          : " "
      }}
      <span v-if="hours?.estimate"> / {{ hours.estimate }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps(["data", "hours", "showHours"]);

function setBackground() {
  // setTimeout(() => {
  let color = "#2C75F4";

  if (props.hours) {
    if (
      props.hours?.estimate < props.hours?.spent &&
      props.hours?.estimate > 0
    ) {
      color = "#e93740";
    } else if (
      props.hours?.estimate >= props.hours?.spent &&
      props.data >= 100 &&
      props.hours?.estimate > 0
    ) {
      color = "#51C48D";
    }
  }

  return ` linear-gradient(
      90deg,
      #f3326000 0%,
      ${color} 102.79%
    )`;
  // }, 100);
}

function calculatePercent(score) {
  return ((score + 1) / 12) * 100;
}
</script>

<style lang="scss" scoped>
.parentWrapp {
  display: flex;
}
.wrapp-hours {
  margin-left: 6px;
  white-space: nowrap;
  // font-style: normal;
  // font-weight: 700;
  // font-size: 14px;
  // line-height: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: var(--color-text);
  span {
    color: var(--label-color);
  }
}
.wrapp-progress {
  width: 62px;
  .name-progress {
    margin-top: 2px;
    // font-weight: 600;
    // font-size: 10px;
    // line-height: 12px;
    font-weight: 800;
    font-size: 11px;
    line-height: 12px;
    text-transform: lowercase !important;

    color: var(--label-color);
  }
  .color {
    // height: 5px;
    height: 4px;
    border-radius: 0 3px 3px 0;
    // background: linear-gradient(
    //   90deg,
    //   rgba(44, 117, 244, 0) 0%,
    //   #2c75f4 99.11%
    // );
  }
}
</style>
