<template>
  <div class="created-by-wrapper">
    <avatar-image :user="props.user" :star="star" />
    <span>{{ props.user.usr_displayname }}</span>
  </div>
</template>
<script setup lang="ts">
import { type User } from "@/assets/js/types";
type Props = {
  user: User;
  star?: boolean;
};
const props = defineProps<Props>();
</script>
<style lang="scss" scoped>
.created-by-wrapper {
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: red;
  height: 29px;
  // background: #434446;
  border-radius: 19px;
  padding-right: 16px;
  width: fit-content;

  span {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    white-space: nowrap;
    color: var(--color-text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 135px;
  }
}
</style>
