<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: boolean;
    disabled?: boolean;
    showInput?: boolean;
  }>(),
  {
    showInput: true,
    disabled: false,
  }
);

const emit = defineEmits(["update:modelValue"]);
function emitChange(e: any) {
  emit("update:modelValue", e.target?.checked);
}
</script>

<template>
  <label class="container" :class="{ 'hover-active': !disabled && !showInput }">
    <input
      v-if="showInput"
      :disabled="disabled"
      type="checkbox"
      :checked="modelValue"
      @change="emitChange($event)"
    />
    <span v-if="showInput" class="checkmark"></span>
    <slot name="custom-label"></slot>
  </label>
</template>

<style lang="scss" scoped>
.container {
  position: relative;
  // height: 25px;
  user-select: none;
  user-select: none;
  user-select: none;
  transition-duration: var(--vt-c-transition-duration);
  padding: 4px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  input {
    width: 22px;
    height: 22px;

    // background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    appearance: none;
    outline: none;
    cursor: pointer;
    margin-right: 7px;
    background: var(--background-input) !important;
  }

  input:disabled:checked ~ .checkmark {
    background-color: var(--vt-c-indigo);
    border: 2px solid var(--vt-c-indigo);
  }

  input:checked ~ .checkmark {
    background-color: var(--active-color-modal);
    border: 2px solid var(--active-color-modal);
  }

  input:checked ~ .checkmark::after {
    display: block;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translate(0, -50%);
    height: 22px;
    width: 22px;
    // background-color: transparent;
    border: 1px solid var(--checkbox-border-color);
    border-radius: 50%;
    box-sizing: border-box;
    transition-duration: var(--vt-c-transition-duration);
  }

  .checkmark::after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid var(--color-background);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.container:hover {
  // background: rgba(222, 226, 233, 0.35);
  // background: linear-gradient(90deg, #191922 64.06%, rgba(25, 25, 34, 0) 100%);
  border-radius: 10px;

  .checkmark {
    border: 2px solid var(--vt-c-divider-dark-2);
    background: transparent;
  }
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
</style>
