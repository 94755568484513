import { usePagination } from "@/assets/js/composables";
import { debounce, openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "./companyStore";
import api from "@/api";
import { ProjectStore } from "./projectStore";

export const TasksStore = defineStore("myTasksStore", () => {
  type NumOrNull = number | null;
  const tasks = ref([]);
  const companyStore = CompanyStore();
  const projectStore = ProjectStore();
  const router = useRouter();
  const { count, offset, onChange, resetPagination, page, perPage } =
    usePagination(getTasks);
  const search = ref<string | null>(null);
  const archived = ref<boolean>(false);
  const parentTree = ref([]);

  const onSearch = debounce(
    function () {
      resetPagination();
    },
    500,
    false
  );

  watch(archived, getTasks);

  async function getTasks() {
    tasks.value = [];
    await getParentTree();
    try {
      const params: any = {
        cmp_id: companyStore.getActiveCompany?.cmp_id,
        tsk_id_parent: router.currentRoute.value.params.projectId,
        offset: offset.value - 1,
        limit: perPage.value,
        archive: archived.value ? 1 : 0,
      };
      if (router.currentRoute.value.query["root"]) {
        params.first_parent = router.currentRoute.value.query["root"];
      }
      if (search.value) {
        params.pro_id = router.currentRoute.value.params.projectId;
        params.search = search.value;
        const res = await api.getTasksSearch(params);
        tasks.value = res.data.data;
        count.value = res.data.meta_info.count;
      } else {
        const res = await api.getTasks(params);
        tasks.value = res.data.data;
        count.value = res.data.meta_info.count;
        if (res.data.meta_info.row_offset) {
          const p = Math.floor(res.data.meta_info.row_offset / perPage.value);
          page.value = p + 1;
          onChange({
            offset: p * perPage.value + 1,
          });
          router.replace({
            ...router.currentRoute,
            query: {},
          });
        }
      }
    } catch (error: any) {
      if (error.response.data.message === "redirect") {
        const company: any = companyStore.companies?.find(
          (company) => company.cmp_id === error.response.data.cmp_id
        );
        await companyStore.replaceActiveCompany(company);
        router.go(-1);
      }
      if (error.response.data.message === "no-privilege") {
        openCustomToast("You are not assigned to this project.", "error");
        projectStore.setSelectedProjectId(null);
        projectStore.setSelectedProjectTaskId(null);
        projectStore.setSelectedProjectTaskSection(null);
        router.push({ name: "MyTasks" });
      }
    }
  }

  async function getParentTree() {
    if (router.currentRoute.value.params.taskId) {
      try {
        const params: any = {
          tsk_id: router.currentRoute.value.params.taskId,
        };
        const res = await api.getParentTree(params);
        parentTree.value = res.data.data;
      } catch (error) {
        console.log(error);
      }
    }
  }

  // navigation functions:
  const selectedTaskId = ref<NumOrNull>(null);
  const selectedTaskSection = ref<string | null>(null);

  function setSelectedTaskId(id: NumOrNull): void {
    selectedTaskId.value = id;
  }

  function setSelectedTaskSection(section: string | null): void {
    selectedTaskSection.value = section;
  }

  const getSelectedTaskId = computed(() => {
    return selectedTaskId.value;
  });

  const getSelectedTaskSection = computed(() => {
    return selectedTaskSection.value;
  });

  return {
    tasks,
    selectedTaskId,
    setSelectedTaskId,
    getSelectedTaskId,
    selectedTaskSection,
    setSelectedTaskSection,
    getSelectedTaskSection,
    getTasks,
    onSearch,
    page,
    onChange,
    resetPagination,
    archived,
    getParentTree,
    count,
    perPage,
    offset,
    parentTree,
    search,
  };
});
