<template>
  <div
    v-if="tagsData.constructor === Array && tagsData.length > 0"
    class="classic-tags-wrapper"
  >
    <div
      v-for="(tag, index) in tagsData"
      :key="index"
      class="tag py-2 px-3 mr-3"
      :style="{ backgroundColor: getColor(tag) }"
      @click="clicked(tag)"
    >
      <img
        v-if="getIcon(tag, icon)"
        :src="getIcon(tag, icon)"
        class="mr-2"
        alt="tag-icon"
      />
      <div class="mr-2" :title="getLabel(tag)">
        {{ getLabel(tag) }}
      </div>
      <img v-if="close" :src="close" class="ml-2" alt="close-icon" />
    </div>
  </div>
  <div v-else class="tag py-2 px-3" :style="styles" @click="clicked(tagsData)">
    <img
      v-if="getIcon(tagsData, icon)"
      :src="getIcon(tagsData, icon)"
      class="mr-2"
      alt="tag-icon"
    />
    <div class="mr-2" :style="stylesTxt" :title="getLabel(tagsData)">
      {{ getLabel(tagsData) }}
    </div>
    <img v-if="close" :src="close" class="ml-2" alt="close-icon" />
  </div>
</template>

<script>
import { ref, computed } from "vue";
export default {
  name: "ClassicTags",
  props: {
    tagsData: {
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    close: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    styles: {
      type: Object,
      required: false,
      default: null,
    },
    stylesTxt: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["clicked"],
  setup(props, { emit }) {
    const getLabel = (tag) => {
      if (typeof tag === "string" || tag instanceof String) {
        return tag;
      } else if (tag.label) {
        return tag.label;
      } else if (tag.name) {
        return tag.name;
      } else if (tag.value) {
        return tag.value;
      } else {
        return tag;
      }
    };

    const getIcon = (tag, icon) => {
      if (tag.icon) {
        return tag.icon;
      }
      if (icon) {
        return icon;
      }
      return null;
    };

    const clicked = (data) => {
      emit("clicked", data);
    };

    const getColor = (tag) => {
      if (tag.color) {
        return tag.color;
      }
      if (props.color) {
        return props.color;
      }
      return null;
    };

    return {
      getLabel,
      getIcon,
      clicked,
      getColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.tag {
  background: var(--background-color-tags);
  color: var(--vt-c-active);
  height: 25px;
  padding: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;

  div {
    margin-right: 8px;
  }
}

.classic-tags-wrapper {
  display: flex;
  flex-direction: row;
}
</style>
