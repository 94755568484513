<template>
  <div
    v-if="type === 'title'"
    :style="options.style ? options.style : null"
    :title="data"
    class="table-cell title"
  >
    {{ data }}
  </div>
  <div
    v-else-if="type === 'availability'"
    :style="{ color: row.avt_color ? '#' + row.avt_color : null }"
    :title="data"
    class="table-cell title"
  >
    {{ data }}
  </div>
  <div v-else-if="type === 'time'" :title="data" class="table-cell time">
    <div v-if="data">
      {{
        `${Math.floor(parseInt(data) / 60)}:${String(
          parseInt(data) % 60
        ).padStart(2, "0")}`
      }}
    </div>
  </div>
  <div
    v-else-if="type === 'task-subtask'"
    :style="options.style ? options.style : null"
    class="table-cell task-subtask"
  >
    <!-- <status-indicator
      v-if="row.status"
      :status="row.status"
      style="margin-right: 10px"
    /> -->
    <div>
      <div
        class="name"
        :style="options.style ? options.style : null"
        :title="row.tsk_name"
      >
        {{ row.tsk_name }}

        <div class="color-task" :style="setDivColor(row.tsk_color)"></div>
        <!-- #{{row.tsk_color}} -->
      </div>

      <div
        class="path"
        :style="options.style ? options.style : null"
        :title="row.path"
      >
        {{ row.path }}
      </div>
    </div>
  </div>
  <div v-else-if="type === 'client-project'" class="table-cell task-subtask">
    <div>
      <div class="name" :title="row.pro_name">
        {{ row.pro_name }}
      </div>
      <div
        class="path"
        :title="row.client ? row.client.cli_name : row.cli_name"
      >
        {{ row.client ? row.client.cli_name : row.cli_name }}
      </div>
    </div>
  </div>
  <div
    v-else-if="type === 'hours'"
    class="table-cell hours"
    :class="{ red: hours?.estimate < hours?.spent && hours?.estimate > 0 }"
    :style="options.style ? options.style : null"
  >
    <template v-if="hours?.estimate || hours?.spent">
      <span class="spent"> {{ hours?.spent }} </span>
      {{ hours?.estimate ? "/" + hours.estimate : "" }}
    </template>
  </div>
  <div v-else-if="type === 'priority'" class="table-cell priority">
    <priority-task v-if="data !== null" :data="data" />
    <!-- <img
      v-if="data !== null && data > 5"
      src="@/assets/icons/upArrow.png"
      alt=""
    />
    <img
      v-if="data !== null && data > 7"
      src="@/assets/icons/upArrow.png"
      alt=""
    />
    <img
      v-if="data !== null && data > 9"
      src="@/assets/icons/upArrow.png"
      alt=""
    />
    <img
      v-if="data !== null && data < 5"
      src="@/assets/icons/downArrow.png"
      alt=""
    />
    <img
      v-if="data !== null && data < 3"
      src="@/assets/icons/downArrow.png"
      alt=""
    />
    <img
      v-if="data !== null && data < 1"
      src="@/assets/icons/downArrow.png"
      alt=""
    /> -->
  </div>
  <div v-else-if="type === 'delete'" class="table-cell">
    <img v-if="data" src="@/assets/icons/table-delete.svg" alt="delete icon" />
  </div>
  <div v-else-if="type === 'edit'" class="table-cell">
    <img v-if="data" src="@/assets/icons/table-edit.svg" alt="edit icon" />
  </div>
  <div v-else-if="type === 'info'" class="table-cell">
    <img src="@/assets/icons/table-info.svg" alt="info icon" />
  </div>
  <!-- da li je sat approved ili ne
  <div v-else-if="type === 'approved'" class="table-cell">approved</div> -->
  <div
    v-else-if="type === 'text'"
    :title="data"
    class="table-cell text"
    :style="options.style ? options.style : null"
  >
    {{ data }}
  </div>
  <div v-else-if="type === 'progress'" class="table-cell progress">
    <!-- <span>{{ `${data}%` }}</span>
    <progress
      :class="{ full: data > 100 }"
      :value="data"
      max="100"
      min="0"
    ></progress> -->
    <progress-task
      :data="data"
      :hours="hours"
      :showHours="options?.showHours"
    />
  </div>
  <div v-else-if="type === 'date'" class="table-cell date">
    <template v-if="data">
      <span class="day">{{
        `${dayjs.utc(data).local().format("DD.MM.YYYY")}.`
      }}</span>
      <span class="time">
        {{ `${dayjs.utc(data).local().format("HH:mm")}` }}
      </span>
    </template>
    <template v-else>
      <span></span>
    </template>
  </div>
  <div v-else-if="type === 'date_due_created'" class="table-cell date">
    <template v-if="data || row.tsk_created">
      <due-date-created :data="data" :created="row.tsk_created" />
    </template>
    <template v-else>
      <span></span>
    </template>
  </div>
  <div v-else-if="type === 'avatar-name'" class="table-cell avatar-name">
    <avatar-image :user="row.user" style="margin-right: 10px" />
    <span eclass="title">
      {{ row.usr_name }}
    </span>
  </div>
  <div
    v-else-if="type === 'avatar-date' || type === 'avatar-date-owner'"
    class="table-cell avatar-date"
  >
    <template v-if="data">
      <avatar-image
        v-if="type === 'avatar-date'"
        :user="row.usr_created"
        style="margin-right: 10px"
      />
      <avatar-image
        v-else-if="type === 'avatar-date-owner'"
        :user="row.owner"
        style="margin-right: 10px"
      />
      <div class="date">
        <span class="day">{{
          `${dayjs.utc(data).local().format("DD.MM.YYYY")}.`
        }}</span>
        <span class="time">
          {{ `${dayjs.utc(data).local().format("HH:mm")}` }}
        </span>
      </div>
    </template>
    <div v-else></div>
  </div>
  <div
    v-else-if="type === 'avatar'"
    :title="getUsersTitle(data)"
    class="table-cell avatar"
  >
    <avatar-list
      :users="data"
      :maxLength="options.maxLength ? options.maxLength : 2"
    />
  </div>
  <div v-else-if="type === 'checkbox'" class="table-cell checkbox">
    <basic-checkbox
      :value="data"
      :getEventForTabele="true"
      :editable="editable"
      @change-value="changeValueCheckbox"
    />
  </div>
  <div
    v-else-if="type === 'edit-ep'"
    class="table-cell"
    style="color: #555f76; font-weight: 700"
  >
    ep
  </div>
  <div
    v-else-if="type === 'created-by'"
    class="table-cell created-by"
    @mouseover="hoverCreatedBy = createdBy !== null && data.creator !== 'null'"
    @mouseleave="hoverCreatedBy = false"
  >
    <created-by
      v-if="data"
      :style="{ visibility: hoverCreatedBy ? 'hidden' : '' }"
      :user="data"
      :star="createdBy ? true : false"
    />
    <CreatedByHover
      v-if="hoverCreatedBy"
      :style="{ visibility: !hoverCreatedBy ? 'hidden' : '' }"
      :user="createdBy"
      style="position: absolute; top: 0; left: 0"
    />
    <!-- usr_id_created -->
  </div>
  <div v-else-if="type === 'coworker'" class="table-cell created-by">
    <created-by
      v-if="data"
      :style="{ visibility: hoverCreatedBy ? 'hidden' : '' }"
      :user="row"
      :star="createdBy ? true : false"
    />
  </div>
  <div
    v-else-if="type === 'dropdown'"
    :style="generateStyles()"
    class="table-cell dropdown"
    :title="row.tsk_name ? row.tsk_name : ''"
  >
    <div class="image-wrapper">
      <img
        v-if="data > 0"
        src="./dropdown.png"
        alt="dropdown image"
        :class="{ active: open }"
      />
    </div>
    <!-- <status-indicator
      v-if="row.status"
      :status="row.status"
      style="margin-right: 10px"
    /> -->
    <div class="name">
      {{ row.tsk_name ? row.tsk_name : "" }}
      <div class="color-task" :style="setDivColor(row.tsk_color)"></div>
    </div>
  </div>
  <div v-else-if="type === 'boolean'" :title="data" class="table-cell">
    {{ data ? "yes" : "no" }}
  </div>
  <div v-else-if="type === 'color'" :title="data" class="table-cell">
    <div
      class="color"
      :style="{
        background: `linear-gradient(180deg, #${data} 0%, rgba(138, 134, 138, 0.061) 100%)`,
      }"
    ></div>
  </div>
  <div v-else class="table-cell">
    {{ data }}
  </div>
  <!-- </div> -->
</template>

<script>
import api from "@/api";
import { ref, watchEffect } from "vue";
import dayjs from "dayjs";
import CreatedByHover from "../avatar/createdByHover.vue";
export default {
  name: "TableCell",
  props: {
    data: {
      required: false,
      default: "/",
    },
    type: {
      required: false,
      default: "text",
    },
    tskId: {
      required: false,
      default: null,
    },
    row: {
      required: true,
    },
    options: {
      required: true,
    },
    editable: {
      required: false,
      default: false,
    },
    depth: {
      required: true,
    },
    open: {
      required: true,
    },
    createdBy: {
      default: null,
    },
  },
  emits: ["change-value-checkbox"],
  setup(props, { emit }) {
    const hoverCreatedBy = ref(null);
    const hours = ref(null);
    onMounted(() => {
      setupHours();
      // console.log(props.row)
    });
    function setupHours() {
      if (props.type === "hours" || props.type === "progress") {
        getHours();
      }
    }
    watch(() => props.row, setupHours);
    async function getHours() {
      try {
        const res = await api.getTaskHours(props.tskId);
        hours.value = res.data.data;
        // console.log(JSON.stringify(res.data.data));
      } catch (error) {
        console.log(error);
      }
    }
    function getUsersTitle(users) {
      return users.map((user) => " " + user.usr_displayname);
    }
    function generateStyles() {
      return {
        width:
          parseInt(props.options.style["max-width"]) -
          (props.depth * 30 + 40) +
          "px",
      };
    }
    function setDivColor(colorS) {
      if (colorS) {
        if (colorS.startsWith("#")) {
          return {
            background: ` linear-gradient(90deg, ${colorS} 0%, #51c48d00 100%)`,
          };
        } else {
          // console.log({'background':` linear-gradient(90deg, #${colorS} 0%, #51c48d00 100%)`});
          return {
            background: ` linear-gradient(90deg, #${colorS} 0%, #51c48d00 100%)`,
          };
        }
      }
      return { background: "transparent" };

      // return color ? `
      //     linear-gradient(90deg, #${color} 0%, rgba(81, 196, 141, 0) 100%);`
      //     : 'transparent';
    }

    function changeValueCheckbox(e) {
      emit("change-value-checkbox", e);
    }

    return {
      getUsersTitle,
      hours,
      dayjs,
      generateStyles,
      hoverCreatedBy,
      setDivColor,
      changeValueCheckbox,
    };
  },
};
</script>

<style lang="scss" scoped>
// .table-cell-wrapper {
//   height: 100%;
.table-cell {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: var(--color-text);

  .color {
    margin-left: 3px;
    width: 22px !important;
    height: 22px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    border: var(--border-selected-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.table-cell.text {
  // font-weight: 400;
  // font-size: 13px;
  // line-height: 15px;
  max-width: 100%;
  white-space: nowrap;
  // color:red;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
}

.table-cell.hours {
  .spent {
    font-weight: bold;
  }
}

.table-cell.hours.red {
  color: #dd3554;
}

.table-cell.priority {
  justify-content: center;

  img {
    margin-right: 1px;
  }
}

.table-cell.title,
.table-cell.dropdown {
  color: var(--color-text);
  // font-weight: 700;
  // font-size: 15px;
  line-height: 50px;
  font-weight: 700;
  font-size: 15px;
  // line-height: 16px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;

  .id {
    font-weight: 700;
    font-size: 13px;
    color: #acacad;
    margin-left: 10px;
  }

  .image-wrapper {
    width: 12px;
    margin-right: 7px;

    img {
      align-self: center;
      transform: rotate(-90deg);
      transition-duration: 0.2s;
    }

    img.active {
      transform: rotate(0deg);
    }
  }

  .name::first-letter {
    text-transform: uppercase;
  }

  .name {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    .color-task {
      position: absolute;
      width: 80px;
      height: 2px !important;
      left: 0px;
      top: 35px;
      //  background: #dd3554!important;
      border-radius: 2px;
    }
  }
}

.table-cell.date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  .day {
    // font-weight: 700;
    // font-size: 15px;
    // line-height: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;

    white-space: nowrap;
  }

  .time {
    // font-weight: 400;
    // font-size: 11px;
    // line-height: 15px;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    white-space: nowrap;
    color: var(--color-path-link);
  }
}

.table-cell.avatar-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    font-weight: 700;
    font-size: 15px;
    // line-height: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    padding-bottom: 5px;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.table-cell.avatar-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .day {
    // font-weight: 700;
    // font-size: 15px;
    // line-height: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    white-space: nowrap;
  }

  .time {
    // font-weight: 400;
    // font-size: 11px;
    // line-height: 15px;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    white-space: nowrap;
    color: var(--color-path-link);
  }
}

.table-cell.task-subtask {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .path {
    // font-weight: 400;
    // font-size: 12px;
    // line-height: 13px;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    // color: #acacad;
    color: var(--color-path-link);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 28px;
    // margin-top: 1px;
  }

  .name {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-text);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }

  .name::first-letter {
    text-transform: uppercase;
  }

  .color-task {
    // position: absolute;
    width: 80px;
    height: 2px !important;
    left: 0px;
    top: 20px;
    // background: #dd3554!important;
    border-radius: 2px;
  }
}
.table-cell.progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  span {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: #7b7e8f;
    width: 100%;
    margin-bottom: 5px;
  }

  progress {
    height: 9px;
    width: 75px;
    appearance: none;
    appearance: none;
    border-radius: 5px;
    overflow: hidden;
    border: none;
    box-sizing: border-box;
  }

  progress::-webkit-progress-value {
    transition: var(--vt-c-transition-duration);
    background-color: var(--vt-c-active);
    border-radius: 5px;
  }

  progress.full::-webkit-progress-value {
    background-color: var(--vt-c-error);
  }

  progress::-webkit-progress-bar {
    background: rgb(236 237 242 / 10%);
    border-radius: 5px;
  }
}

// }
</style>
<style>
.column .table-data .checkbox .container:hover {
  background: unset;
  border-radius: 6px;
}
</style>
