<template>
  <div class="wra">
    <div class="input-wrapper">
      <basic-input
        v-model="data.tsk_name"
        placeholder="Title"
        label="Title"
        :color="data.tsk_color"
        :setFocus="true"
      />
    </div>
    <div class="section">
      <div class="part">
        <div class="input-wrapper">
          <basic-dropdown
            :list="clients"
            :selected="data.client"
            :searchable="true"
            :labelKey="'cli_name'"
            :title="'Client'"
            :maxHeight="200"
            @selected="setClient"
          />
        </div>
        <div class="input-wrapper">
          <div class="datepicker-wrapper" style="margin-left: 0px">
            <div class="datepicker-label">Due date</div>
            <Datepicker
              v-model="data.tsk_due"
              :clearable="false"
              format="dd.MM.yyyy HH:mm"
              :dark="true"
              placeholder="Due date"
              input-class-name="datepicker-input"
              menu-class-name="datepicker-dropdown"
            />
          </div>
        </div>
        <div class="input-wrapper">
          <SelectColor :profile="true" @color="setColor"> </SelectColor>
        </div>
      </div>
      <div class="part">
        <div class="input-wrapper">
          <chooser-view
            v-model="data.assign"
            :title="'Assign'"
            :users="users"
            :edit="true"
            :changable="false"
            @search="onSearch"
          />
        </div>
      </div>
    </div>

    <!-- <div class="input-wrapper">
    <basic-input
      v-model="data.hours"
      type="time"
      placeholder="Hours"
      label="Hours"
    />
  </div> -->

    <div class="btn-wrapper">
      <basic-button
        :inv="true"
        :modal="true"
        style="width: 100px; margin-right: 30px"
        label="Cancel"
        @click="cancel"
      />
      <basic-button
        :modal="true"
        style="width: 100px"
        label="Save"
        @click="saveProject"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { debounce, openCustomToast } from "@/assets/js/helpers";
import { type User, type Client } from "@/assets/js/types";
import Datepicker from "@vuepic/vue-datepicker";

type Data = {
  tsk_name: string | null;
  client: Client | null;
  tsk_due: Date | string | null;
  assign: User[];
  tsk_color: string | null;
};

defineEmits(["close"]);
const props = defineProps(["options"]);
const companyStore = CompanyStore();
const modalStore = ModalStore();
const clients = ref<Client[]>([]);
const users = ref<User[]>([]);
const data = reactive<Data>({
  tsk_name: null,
  client: null,
  tsk_due: null,
  assign: [],
  tsk_color: null,
});

async function getClients() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      view_all: true,
    };
    const res = await api.getClients(params);
    clients.value = res.data.clients;
  } catch (error) {
    console.log(error);
  }
}
getClients();

async function getUsers(search?: string) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search) {
      params.search = search;
    }
    const res = await api.searchUsers(params);
    users.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

const onSearch = debounce(
  function () {
    getUsers(arguments[0]);
  },
  500,
  false
);

function saveProject() {
  if (data.tsk_name && data.client) {
    if (props.options.update) {
      props.options.update(data);
      modalStore.setModalData(null);
    }
  } else {
    openCustomToast('The fields "Title" and "Client" are required.', "error");
  }
}
function cancel() {
  modalStore.setModalData(null);
}
function setClient(client: any) {
  data.client = client;
}
function setColor(e: string) {
  data.tsk_color = e;
}
</script>
<style lang="scss" scoped>
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";
.wra {
  width: 640px;
}
.input-wrapper {
  margin-bottom: 15px;
}

.section {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .part:first-child {
    // margin-right: 40px;
    // width: clac(50% - 20px);
    width: 45%;
  }

  .part {
    width: 45%;
    // width: clac(50% - 20px);
  }
}

.btn-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
</style>
