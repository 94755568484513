<template>
  <div class="created_hover">
    <div class="title">Hour created by</div>
    <div class="created-by-wrapper">
      <avatar-image :user="props.user" />
      <span>{{ props.user.usr_displayname }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type User } from "@/assets/js/types";
type Props = {
  user: User;
};
const props = defineProps<Props>();
</script>
<style lang="scss" scoped>
.created_hover {
  box-sizing: border-box;
  // background: /*  */ #434446;
  // background: linear-gradient(90deg, #191922 64.06%, rgba(25, 25, 34, 0) 100%);
  background: linear-gradient(90deg, #333342 64.06%, rgba(25, 25, 34, 0) 100%);
  border-radius: 10px;
  padding: 5px;
  padding-right: 0;
  padding-left: 12px;
  // width: 100%;
  // height: 100%;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;

    /* identical to box height */
    color: var(--label-color);
    font-family: Archivo;
    margin-bottom: 3px;
  }
}

.created-by-wrapper {
  // transform: scale(0.9);
  display: flex;
  flex-direction: row;
  align-items: center;

  // background-color: red;
  height: 29px;
  // background: #434446;
  border-radius: 19px;
  padding-right: 16px;
  width: fit-content;

  span {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    white-space: nowrap;
    color: var(--color-text);
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
