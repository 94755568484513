<template>
  <div>
    <teleport to="head title"> | {{ appMetaTitle }} </teleport>
    <alt-app-header v-if="isAuthPages" />
    <app-header v-else />
    <!-- <app-header-test v-else /> -->
    <router-view v-if="!loading" v-slot="{ Component }">
      <keep-alive :include="['MyHours', 'MySettings']">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <div v-else>Loading</div>
    <transition name="fade">
      <modal-wrapper />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
// import { useReCaptcha } from "vue-recaptcha-v3";
import { CompanyStore } from "./store/companyStore";
import { UserStore } from "./store/userStore";
import { defaultStore } from "./store";

const router = useRouter();
const companyStore = CompanyStore();
const userStore = UserStore();
const loading = ref<boolean>(true);

const store = defaultStore();

const isAuthPages = computed(() => {
  return [
    "AuthPage",
    "LoginPage",
    "RegisterPage",
    "ForgotPasswordPage",
    "NewPasswordPage",
    "ValidatePage",
  ].includes(router.currentRoute.value.name as string);
});

const appMetaTitle = "OmniTasker";

// async function hideRecaptchaBadge() {
//   const recaptcha = useReCaptcha();
//   await recaptcha?.recaptchaLoaded();
//   recaptcha?.instance.value?.hideBadge();
// }

async function setup() {
  // eslint-disable-next-line no-storage/no-browser-storage
  const jwt = localStorage.getItem("jwt");
  if (jwt) {
    store.setJWT(jwt);
  }
  await companyStore.setupCompanies();
  if (router.currentRoute.value.meta.jwtRequired) {
    await userStore.getUserData();
  }

  loading.value = false;
}

function setTheme() {
  // SET FUNCIONALITY FOR LIGHT THEME... global style is set
  let html = document.getElementsByTagName("html");

  // remove dynamically because it might have more classes and we won't know which one to remove
  let classList: string[] = [];
  for (let i = 0; i < html[0].classList.length; i++) {
    // html[0].classList.remove(html[0].classList[i]);
    classList.push(html[0].classList[i]);
  }
  for (let i = 0; i < classList.length; i++) {
    html[0].classList.remove(classList[i]);
  }

  // set

  /* eslint-disable */
  /* prettier-ignore */
  let theme = localStorage.getItem("theme");
  if (theme) {
    html[0].classList.add(theme);
  } else {
    html[0].classList.add("dark");
  }
}

onBeforeMount(() => {
  // hideRecaptchaBadge();
  setup();
  setTheme();
});
</script>

<style lang="scss">
@import "./assets/css/base.css";
@import "./assets/css/scroll.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

#app {
  width: 100vw;
  max-width: 100%; // to not create the bottom scroll
  height: 100%;

  // background: #1b1b1e;
  min-height: 100vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.3s ease;
}

.slide-top-enter-from,
.slide-top-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
