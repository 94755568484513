<template>
  <div class="edit-hours-wrapper">
    <div class="section task-name">
      <h1>
        {{ $props.options?.name }}
      </h1>
    </div>
    <div class="section central-content">
      <div class="left-central-content">
        <div class="input-wrapper">
          <basic-dropdown
            class="types"
            :list="[]"
            :selected="taskType"
            :searchable="true"
            :disabled="true"
            :labelKey="'ttp_name'"
            :title="'Task type'"
          />
        </div>
      </div>
      <div class="right-central-content">
        <span class="cc-desc">Time</span>
        <div class="time-buttons-wrapper">
          <time-button
            v-model="data.hours"
            placeholder="hh"
            label="Hours"
            :min="0"
            :max="10000"
            :step="1"
            type="number"
            :maxValueCharacter="2"
            style="margin-right: 10px"
          />
          :
          <time-button
            v-model="data.minutes"
            placeholder="mm"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            type="number"
            :maxValueCharacter="2"
            style="margin-left: 10px"
          />
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <basic-button label="Save" style="width: 100px" @click="saveHours" />
    </div>
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { ModalStore } from "@/store/modalStore";
import { openCustomToast } from "@/assets/js/helpers";
import { type TaskType } from "@/assets/js/types";

defineEmits(["close"]);
const props = defineProps(["options"]);

const modalStore = ModalStore();

const router = useRouter();
// params:
const taskType = ref<TaskType | null>(null);
const oldData: any = reactive({
  hours: 0,
  minutes: 0,
});
const data: any = reactive({
  hours: 0,
  minutes: 0,
});

onMounted(() => {
  setupData();
});

function setupData() {
  taskType.value = {
    ttp_id: props.options.data.ttp_id,
    ttp_name: props.options.data.ttp_name,
  };

  oldData.hours = parseInt(props.options.data.est_amount.split(":")[0]);
  oldData.minutes = parseInt(props.options.data.est_amount.split(":")[1]);

  data.hours = parseInt(props.options.data.est_amount.split(":")[0]);
  data.minutes = parseInt(props.options.data.est_amount.split(":")[1]);
}

async function saveHours() {
  if (!data.hours) {
    openCustomToast("The 'Time' field is required", "error");
    return;
  }

  try {
    data.ttp_id = props.options.data.ttp_id;
    data.tsk_id = router.currentRoute.value.params.taskId
      ? router.currentRoute.value.params.taskId
      : router.currentRoute.value.params.projectId;
    await api.editEstimation(data);
    if (props.options.update) {
      props.options.update();
    }
    modalStore.setModalData(null);
    openCustomToast("You have successfully changed your estimate.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while modifying the estimate.", "error");
  }
}
</script>
<style lang="scss" scoped>
.edit-hours-wrapper {
  display: flex;
  flex-direction: column;

  .section {
    margin: 0;

    .input-wrapper {
      margin-bottom: 20px;
    }
  }

  .task-name {
    margin-bottom: 40px;

    h1 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #ecedf2;
    }

    h1::first-letter {
      text-transform: uppercase;
    }
  }

  .central-content {
    display: flex;
    margin: 0 0 22px;

    .left-central-content {
      width: 270px;
      margin-right: 40px;

      .input-wrapper {
        .types {
          min-width: 223px;
        }
      }
    }

    .right-central-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cc-desc {
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
      }
    }

    .time-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -16px;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
