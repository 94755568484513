import { createApp } from "vue";
// @ts-ignore
import App from "./App.vue";
import router from "./router";
import { i18n } from "./i18n";
import { createPinia } from "pinia";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// @ts-ignore
import vClickOutside from "click-outside-vue3";
// import { markRaw } from "vue";
// import { VueReCaptcha } from "vue-recaptcha-v3";
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const pinia = createPinia();

// pinia.use(({ store }) => {
//   store.router = markRaw(router);
// });

const app = createApp(App).use(pinia).use(router).use(i18n);

app.use(Toast, {
  hideProgressBar: true,
});
app.use(vClickOutside);
// app.use(VueReCaptcha, { siteKey: "6LfFvrkiAAAAACeHAQ6s5SoCDmD19FjLWaQqPSWP" });
app.use(Vue3ColorPicker);
router.isReady().then(() => app.mount("#app"));
