<template>
  <!-- this is for auth modals -->
  <div
    v-if="store.getModalData && store.getModalData.options?.hideBackdrop"
    ref="modal_wrap"
    :class="{ transparent: store.getModalData.options?.hideBackdrop }"
    class="backdrop"
    @click="handleClickOutsideModal"
  >
    <div class="wrappLogoContent">
      <div class="wrappLogo">
        <img src="../assets/images/logoForAuth.svg" alt="" />
      </div>

      <div
        :class="{ transparent: store.getModalData.options?.transparentModal }"
        class="modal-wrapper"
      >
        <div class="modal-header">
          <h2>{{ store.getModalData.title }}</h2>
          <img
            v-if="!store.getModalData.options?.hideClose"
            src="../assets/icons/toastClose.svg"
            alt=""
            @click="store.setModalData(null)"
          />
        </div>
        <div class="modal-content">
          <component
            :is="modalsMap[store.getModalData.component]"
            :options="store.getModalData.options"
            @close="store.setModalData(null)"
          >
          </component>
        </div>
      </div>
    </div>
  </div>
  <!-- split for new design for auth modals-->
  <div
    v-else-if="store.getModalData"
    ref="modal_wrap"
    :class="{ transparent: store.getModalData.options?.hideBackdrop }"
    class="backdrop"
    @click="handleClickOutsideModal"
  >
    <div
      :class="{ transparent: store.getModalData.options?.transparentModal }"
      class="modal-wrapper"
    >
      <div class="modal-header">
        <h2>{{ store.getModalData.title }}</h2>
        <img
          v-if="!store.getModalData.options?.hideClose"
          src="../assets/icons/toastClose.svg"
          alt=""
          @click="store.setModalData(null)"
        />
      </div>
      <div class="modal-content">
        <component
          :is="modalsMap[store.getModalData.component]"
          :options="store.getModalData.options"
          @close="store.setModalData(null)"
        >
        </component>
      </div>
    </div>
  </div>
</template>
<script setup>
import loginModal from "./modals/loginModal.vue";
import registerModal from "./modals/registerModal.vue";
import newProjectModal from "./modals/newProjectModal.vue";
import newTaskModal from "./modals/newTaskModal.vue";
import editTaskModal from "./modals/editTaskModal.vue";
import addHoursModal from "./modals/addHoursModal.vue";
import editProfileModal from "./modals/editProfileModal.vue";
import forgotPasswordModal from "./modals/forgotPasswordModal.vue";
import newPasswordModal from "./modals/newPasswordModal.vue";
import newUserModal from "./modals/newUserModal.vue";
import newClientModal from "./modals/newClientModal.vue";
import editClientModal from "./modals/editClientModal.vue";
import confirmModal from "./modals/confirmModal.vue";
import editHoursModal from "./modals/editHoursModal.vue";
import newStatusModal from "./modals/newStatusModal.vue";
import editStatusModal from "./modals/editStatusModal.vue";
import newTaskTypeModal from "./modals/newTaskTypeModal.vue";
import editTaskTypeModal from "./modals/editTaskTypeModal.vue";
import newHourTypeModal from "./modals/newHourTypeModal.vue";
import editHourTypeModal from "./modals/editHourTypeModal.vue";
import editProjectModal from "./modals/editProjectModal.vue";
import addEstimationModal from "./modals/addEstimationModal.vue";
import editEstimationModal from "./modals/editEstimationModal.vue";
import infoHoursModal from "./modals/infoHoursModal.vue";
import editMemberModal from "./modals/editMemberModal.vue";
import resetPasswordModal from "./modals/resetPasswordModal.vue";
import addFile from "./modals/addFile.vue";
import editStatus from "./modals/editStatus.vue";
import editAvailabilityType from "./modals/editAvailabilityType.vue";
import addAvailabilityType from "./modals/addAvailabilityType.vue";
import editMemberEpModal from "./modals/editMemberEpModal.vue";
import { useRoute } from "vue-router";

import { ModalStore } from "@/store/modalStore";
import { HoursStore } from "@/store/hoursStore";
import { focusFirstElement } from "@/assets/js/helpers";

const route = useRoute();
const store = ModalStore();
const hoursStore = HoursStore();
const modal_wrap = ref();
const confirm_open = ref(false);
function handleEscape(e) {
  if (e.key === "Escape") {
    if (confirm_open.value) {
      confirm_open.value = false;
      return;
    }
    handleCloseModal();
  }
}

function handleCloseModal() {
  if (hoursStore.isDescriptionFilled) {
    confirm_open.value = true;
    if (
      confirm(
        "Are you sure you want to stop entering hours? Your hours description will be deleted if you click on OK"
      )
    ) {
      store.setModalData(null);
      hoursStore.clearDescriptionFilled();
    }
    setTimeout(() => {
      confirm_open.value = false;
    }, 100);
  } else {
    store.setModalData(null);
    hoursStore.clearDescriptionFilled();
  }
}

const handleClickOutsideModal = (event) => {
  if (event.target === modal_wrap.value && !route.path.startsWith("/auth/")) {
    handleCloseModal();
  }
};
watchEffect(() => {
  if (store.getModalData?.options?.hideClose) {
    return;
  }
  if (store.modalData) {
    document.addEventListener("keyup", handleEscape);
  } else {
    document.removeEventListener("keyup", handleEscape);
  }
});

const stop = watchEffect(() => {
  if (modal_wrap.value) {
    focusFirstElement(modal_wrap.value);
    stop();
  }
});

const modalsMap = {
  loginModal,
  registerModal,
  confirmModal,
  newProjectModal,
  newTaskModal,
  editTaskModal,
  addHoursModal,
  editHoursModal,
  editProfileModal,
  forgotPasswordModal,
  newPasswordModal,
  newUserModal,
  newClientModal,
  editClientModal,
  newStatusModal,
  editStatusModal,
  newTaskTypeModal,
  editTaskTypeModal,
  newHourTypeModal,
  editHourTypeModal,
  editProjectModal,
  addEstimationModal,
  editEstimationModal,
  infoHoursModal,
  editMemberModal,
  resetPasswordModal,
  addFile,
  editStatus,
  editAvailabilityType,
  addAvailabilityType,
  editMemberEpModal,
};
</script>
<style lang="scss" scoped>
.backdrop {
  position: fixed;
  inset: 0;
  background-color: var(--background-modal-cover);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .modal-wrapper {
    // background: #1b1b1e;
    // border: 2px solid #242427;
    // border-radius: 10px;
    background: var(--background-modal);
    border: var(--border-modal);
    box-shadow: var(--box-shadow-modal);
    border-radius: var(--border-radius-modal);
    min-width: 395px;
    width: auto;
    max-width: 100%;

    // overflow: hidden;

    .modal-header {
      // height: 69px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;
      padding: 20px 40px 20px 45px;
      // background-image: url("../assets/images/modalHeaderBackground.png");
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;

      img {
        height: 15px;
        cursor: pointer;
        transition: all 0.5s;
      }

      img:hover {
        filter: invert(100%);
        transition: all 0.5s;
      }

      h2 {
        // font-weight: 700;
        // font-size: 16px;
        // line-height: 17px;
        // color: #ecedf2;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-text-modal);
        max-width: 50%;
        max-width: calc(100% - 25px);
        //  white-space: nowrap;
      }

      h2::first-letter {
        text-transform: capitalize;
      }
    }

    .modal-content {
      padding: 10px 40px 17px 45px;
      // padding-top: 22px;
      // padding-top: 10px;
      // padding-bottom: 22px;
      box-sizing: border-box;
    }
  }

  .modal-wrapper.transparent {
    // background: rgba(27 27 30 / 85%);
    background: var(--background-modal);
    border: var(--border-modal);
    box-shadow: var(-box-shadow-modal);
    border-radius: 10px;
  }
}

.backdrop.transparent {
  background: transparent;

  .wrappLogoContent {
    margin: auto;
    margin-top: 2%;
    margin-left: 15%;
  }
  .modal-header {
    // height: 69px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    padding: 45px 65px 30px 65px;
  }
  .modal-content {
    padding: 0px 65px 45px 65px;
  }
}

@media only screen and (max-width: 768px) {
  .backdrop.transparent {
    .wrappLogoContent {
      margin: 0;
      img {
        // display: none;
        display: block;
        margin: auto;
        margin-bottom: -40px;
        width: 300px;
      }
    }
  }
}
</style>
