<template>
  <div class="input-wrapper">
    <basic-input
      v-model="email"
      placeholder="Email"
      label="Email"
      :setFocus="true"
    />
  </div>
  <div class="input-wrapper">
    <basic-dropdown
      :list="roles"
      :selected="role"
      :labelKey="'rol_name'"
      :title="'Role'"
      @selected="setRole($event)"
    />
  </div>
  <basic-button label="Add user" @click="addUser" />
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { openCustomToast } from "@/assets/js/helpers";

defineEmits(["close"]);
const props = defineProps(["options"]);
const companyStore = CompanyStore();
const modalStore = ModalStore();
const email = ref<string | null>(null);
const role = ref<any>(null);
const roles = ref([]);

async function getRoles() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.getRoles(params);
    roles.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getRoles();

async function addUser() {
  if (email.value && role.value) {
    try {
      const params: any = {
        cmp_id: companyStore.getActiveCompany?.cmp_id,
        rol_id: role.value.rol_id,
        email: email.value,
      };
      await api.addUser(params);
      if (props.options.update) {
        props.options.update();
      }
      modalStore.setModalData(null);
      openCustomToast("You have successfully added a user.", "success");
    } catch (error: any) {
      if (error.response.data.message === "member-limit") {
        openCustomToast("Member limit.", "error");
      } else if (error.response.data.message === "invalid-email") {
        openCustomToast("Invalid email.", "error");
      } else if (error.response.data.message === "user-not-found") {
        openCustomToast(
          "User not found.",
          "error",
          "The user with that email is not registered."
        );
      } else {
        openCustomToast("An error occurred while adding the user.", "error");
      }
    }
  } else {
    openCustomToast('The fields "Email" and "Role" are required.', "error");
  }
}
function setRole(asd: any) {
  role.value = asd;
}
</script>
<style lang="scss" scoped>
.input-wrapper {
  margin-bottom: 20px;
}
</style>
