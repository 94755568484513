<template>
  <div class="wrapp-member">
    <div class="left">
      <div class="input-wrapper">
        {{ options.usr_emails }}
        <basic-input
          v-model="options.data.usr_email"
          label="Email"
          :disabled="true"
        />
      </div>
      <div class="input-wrapper">
        <basic-input v-model="data.name" label="Name" :disabled="true" />
      </div>
      <div class="input-wrapper">
        <basic-input
          v-model="options.data.usr_phone"
          label="Phone"
          :disabled="true"
        />
      </div>

      <div class="input-wrapper">
        <basic-dropdown
          :list="roles"
          :selected="role"
          :labelKey="'rol_name'"
          :title="'Role'"
          @selected="setRole($event)"
        />
      </div>

      <basic-button label="Save" @click="editMember" />
    </div>

    <div v-if="false" class="right"></div>
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { openCustomToast } from "@/assets/js/helpers";
import { type Role } from "@/assets/js/types";

defineEmits(["close"]);
const props = defineProps(["options"]);
const companyStore = CompanyStore();
const modalStore = ModalStore();
const role = ref<Role | null>(null);
const roles = ref<Role[]>([]);
const data = ref<any>({});

onMounted(() => {
  setupData();
});

function setupData() {
  data.value.name =
    props.options.data.usr_name + " " + props.options.data.usr_lastname;
  role.value = {
    rol_id: props.options.data.rol_id,
    rol_name: props.options.data.rol_name,
  };
}

async function getRoles() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.getRoles(params);
    roles.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getRoles();

async function editMember() {
  if (role.value) {
    try {
      const params: any = {
        cmp_id: companyStore.getActiveCompany?.cmp_id,
        rol_id: role.value.rol_id,
        usr_id: props.options.data.user.usr_id,
      };
      await api.editMember(params);
      if (props.options.update) {
        props.options.update();
      }
      modalStore.setModalData(null);
      openCustomToast("You have successfully changed the user.", "success");
    } catch (error) {
      console.log(error);
      openCustomToast("An error occurred while changing the user.", "error");
    }
  } else {
    openCustomToast('The field "Role" is required.', "error");
  }
}
function setRole(asd: any) {
  role.value = asd;
}
</script>
<style lang="scss" scoped>
.wrapp-member {
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    min-width: 316px;
    .input-wrapper {
      margin-bottom: 20px;
    }
  }
  .left {
    margin-right: 36px;
  }
}
</style>
