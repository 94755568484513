<template>
  <div class="users-page-wrapper">
    <table-wrapper
      :data="users"
      :options="options"
      upSpaceDown="63px"
      @create="openCreateModal"
      @search="onSearch"
      @delete-row="openConfirmModal"
      @edit="openEditModal"
      @edit-ep="openEditEpModal"
    />
    <pagination-one
      v-if="count > 0"
      v-model:page="page"
      :perPage="perPage"
      :offset="offset"
      :count="count"
      prevText="Prev"
      nextText="Next"
      style="margin-top: auto"
      @page-changed="onChange"
    />
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { type Member, type Modal } from "@/assets/js/types";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";
import { usePagination } from "@/assets/js/composables";

const companyStore = CompanyStore();
const modalStore = ModalStore();
const users = ref<Member[]>([]);
const { count, offset, onChange, resetPagination, page, perPage } =
  usePagination(getUsers, 11);
const search = ref<string | null>(null);

onMounted(() => {
  getUsers();
});

watch(
  () => companyStore.activeCompany,
  () => {
    // getUsers();
    resetPagination();
  }
);

async function getUsers() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      offset: offset.value - 1,
      limit: perPage.value,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    const res = await api.getUsers(params);
    users.value = res.data.data;
    count.value = res.data.meta_info.count;
  } catch (error) {
    console.log(error);
  }
}

function onSearch(data: string) {
  search.value = data;
  resetPagination();
}

const options = ref({
  title: "",
  searchable: true,
  customSearch: true,
  editable: true,
  openEditEpModal: checkPermission(["seniority-edit"]),
  deletable: true,
  creatable: checkPermission(["members-add"]),
  noData: "There are currently no users",
  uniqueProperty: "id",
  columns: [
    // {
    //   key: "user",
    //   type: "created-by",
    //   label: "Korisnik",
    // },
    {
      key: "usr_name",
      type: "avatar-name",
      label: "First name",
    },
    {
      key: "usr_lastname",
      type: "title",
      label: "Last name",
    },
    {
      key: "rol_name",
      type: "title",
      label: "Rola",
    },
    // {
    //   key: "rol_name", // izmeni kljuc
    //   type: "title",
    //   label: "Seniority",
    // },
    {
      key: "usr_phone",
      type: "title",
      label: "Phone number",
    },
    {
      key: "usr_email",
      type: "title",
      label: "Email",
    },
    {
      key: "assigner",
      type: "created-by",
      label: "Created by",
    },
    {
      key: "mbr_created",
      type: "date",
      label: "Date created",
    },
  ],
});

if (
  checkPermission(["seniority-edit"]) &&
  companyStore.getActiveCompany?.cmp_id === 4
) {
  options.value.columns.push({
    key: "",
    type: "edit-ep",
    label: "",
  });
}

if (checkPermission(["members-add"]) && checkPermission(["roles-add"])) {
  options.value.columns.push({
    key: "",
    type: "edit",
    label: "",
  });
}

if (checkPermission(["members-add"])) {
  options.value.columns.push({
    key: "",
    type: "delete",
    label: "",
  });
}

function openCreateModal() {
  const modal: Modal = {
    title: "Add new user",
    component: "newUserModal",
    options: {
      update: () => {
        getUsers();
      },
    },
  };
  modalStore.setModalData(modal);
}

function openConfirmModal(member: Member) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete member?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Member: <strong>${member.usr_name} </strong><br>`,
        data() {
          return { member };
        },
      },
      confirm: () => {
        deleteMember(member);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteMember(member: Member) {
  try {
    const params: any = {
      usr_id: member.user.usr_id,
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    await api.deleteMember(params);
    openCustomToast(
      "You have successfully removed the user from the company.",
      "success"
    );
  } catch (error) {
    console.log(error);
    openCustomToast(
      "An error occurred while removing the user from the company.",
      "error"
    );
  } finally {
    getUsers();
  }
}

function openEditModal(member: Member) {
  const data: Modal = {
    component: "editMemberModal",
    title: "Edit member",
    options: {
      data: member,
      update: () => {
        getUsers();
      },
    },
  };
  modalStore.setModalData(data);
}
function openEditEpModal(member: Member) {
  const data: Modal = {
    component: "editMemberEpModal",
    title: "Edit member",
    options: {
      data: member,
      update: () => {
        getUsers();
      },
    },
  };
  modalStore.setModalData(data);
}
</script>
<style lang="scss" scoped>
.users-page-wrapper {
  height: 100%;
  padding-top: 55px;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
</style>
