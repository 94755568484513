<template>
  <div class="table-outer-wrapper scrollbar">
    <div class="table-upper-wrapper" :style="{ 'margin-bottom': upSpaceDown }">
      <div class="upper-actions">
        <basic-search
          v-if="options.searchable"
          v-model="search"
          style="align-self: center"
          :placeholder="'Search...'"
          :width="'300px'"
          :widthInput="'261px'"
          @update:model-value="emit('search', $event)"
        />
        <basic-button
          v-if="options.creatable"
          :label="options.createLabel ? options.createLabel : 'Create new'"
          :width="'140px'"
          :image="'plus'"
          :imageDisabled="plus"
          style="margin-left: 20px"
          @click="emitCreate"
        />
      </div>
    </div>
    <div ref="tableWrapper" class="table-wrapper">
      <div class="table-head">
        <div
          v-for="(cell, i) in options.columns"
          :key="i"
          :class="{
            dropdown: cell.type === 'dropdown',
            small: ['edit', 'delete', 'info'].includes(cell.type),
          }"
          :style="cell.style ? cell.style : null"
          class="column-head"
        >
          <span>{{ cell.label }}</span>
          <img
            v-show="cell.sort && sortTo?.name === cell.key"
            src="./sort.svg"
            alt="sortImage"
            @click="sort(cell)"
          />
          <img
            v-show="cell.sort && sortTo?.name !== cell.key"
            src="./noSort.svg"
            alt="sortImage"
            @click="sort(cell)"
          />
        </div>
      </div>

      <table-row
        v-for="row in data"
        :key="
          row[options.uniqueProperty] ? row[options.uniqueProperty] : row.id
        "
        :depth="0"
        :data="row"
        :options="options"
        :parentTree="parentTree"
        :open="parentTree.includes(row.tsk_id)"
        @clicked="emitRowClick"
        @delete="emit('deleteRow', $event)"
        @edit="emit('edit', $event)"
        @info="emit('info', $event)"
        @contextmenu="handleClickContext"
        @change-value-checkbox="changeValueCheckbox"
        @edit-ep="emit('edit-ep', $event)"
      >
        <slot name="dropdown" :row="row"></slot>
      </table-row>
    </div>
    <h3 v-if="data.length < 1" class="no-data">
      {{ options.noData ? options.noData : "No data in table" }}
    </h3>
    <VueSimpleContextMenu
      v-if="options.contextOptions"
      ref="vueSimpleContextMenu"
      :elementId="'context-' + context_id"
      :options="options.contextOptions"
      @option-clicked="optionClicked"
    />
  </div>
</template>

<script lang="ts">
import plus from "./plus.svg";
import { onMounted, ref, computed, watchEffect } from "vue";
import Sortable from "sortablejs";
import { TransferStore } from "@/store/transferStore";
import router from "@/router";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import { ProjectStore } from "@/store/projectStore";
import { ModalStore } from "@/store/modalStore";
import { type Modal } from "@/assets/js/types";

import { HoursStore } from "@/store/hoursStore";
import { defaultStore } from "@/store";
// import api from "@/api";

export default {
  name: "TableComponent",
  components: {
    VueSimpleContextMenu,
  },
  props: {
    data: {
      type: Array<any>,
      required: false,
      default: () => [],
    },
    options: {
      type: Object,
      required: true,
    },
    parentTree: {
      type: Array,
      default: () => [],
    },
    sortTo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    upSpaceDown: {
      default: "20px",
    },
  },
  emits: [
    "deleteRow",
    "edit",
    "create",
    "rowClick",
    "search",
    "update",
    "set-lock",
    "emit-create",
    "sort",
    "info",
    "change-value-checkbox",
    "edit-ep",
  ],
  setup(props, { emit }) {
    const store = defaultStore();
    const context_id = ref(0);
    const modalStore = ModalStore();
    const hoursStore = HoursStore();
    const columns = ref(JSON.parse(JSON.stringify(props.options.columns)));
    const dataToShow = ref(JSON.parse(JSON.stringify(props.data)));
    // const order = ref(true);
    const columnsToShow = ref(props.options.columns.map((el) => el.key));
    const columnsOptions = ref(props.options.columns.map((el) => el.key));
    const search = ref("");
    const tableWrapper = ref(null);
    const sortable = ref(null);
    const transferStore = TransferStore();
    const vueSimpleContextMenu = ref<any>(null);
    const projectStore = ProjectStore();
    const dataContextMy = ref<Record<string, number>>({
      tsk_id: 0,
      pro_id: 0,
      tsk_locked: -1,
    });

    onMounted(() => {
      context_id.value = store.getNewContextId();
      // if (props.options.sortable) {
      sortable.value = Sortable.create(tableWrapper.value, {
        group: {
          name: "table",
          put: false,
          pull: false,
        },
        handle: ".table-row",
        sort: false,
        dragClass: "ghost",
        disabled: !props.options.sortable,
        onStart: function (evt) {
          const dropEl = evt.originalEvent.target;
          if (dropEl.getAttribute("data-lock") !== null) {
            return;
          }
          const parentRow = dropEl.closest(".table-row");
          if (parentRow.getAttribute("data-lock") !== null) {
            return;
          }
          const ids = JSON.parse(parentRow.getAttribute("data-id"));
          const type = parentRow.getAttribute("data-type");

          const targetData = {
            type,
            data: ids,
          };
          transferStore.setTarget(targetData);
        },
        onEnd: function (evt) {
          const dropEl = evt.originalEvent.target;
          if (dropEl.getAttribute("data-lock") !== null) {
            return;
          }
          const parentRow = dropEl.closest(".table-row");
          if (parentRow.getAttribute("data-lock") !== null) {
            return;
          }
          let ids = JSON.parse(parentRow.getAttribute("data-id"));
          const type = parentRow.getAttribute("data-type");

          if (dropEl === parentRow) {
            ids = {
              tsk_id: parseInt(
                router.currentRoute.value.params.projectId as string
              ),
            };
          }

          const destinationData = {
            type,
            data: ids,
          };
          transferStore.setDestination(destinationData);
        },
      });
      // }
    });

    watchEffect(() => {
      if (!search.value || search.value === "") {
        dataToShow.value = JSON.parse(JSON.stringify(props.data));
      } else {
        dataToShow.value = dataToShow.value.filter((data) => {
          let res = false;

          const columns = getColumns.value.map((column) => {
            return column.searchable ? column.key : null;
          });
          const keys = columns.filter((key) => key);

          for (let index = 0; index < keys.length; index++) {
            if (
              data[keys[index]]
                .toLowerCase()
                .indexOf(search.value.toLowerCase()) > -1
            ) {
              res = true;
            }
          }
          return res;
        });
      }
    });

    const getColumns = computed(() => {
      return columns.value.filter((column) =>
        columnsToShow.value.includes(column.key)
      );
    });

    function sort(column) {
      // console.log(column)
      emit("sort", column.key);

      // order.value = !order.value;
      // if (column.type === "text" || column.type === "title") {
      //   dataToShow.value.sort((a, b) => {
      //     const A = a[column.key].toUpperCase();
      //     const B = b[column.key].toUpperCase();
      //     if (A < B) {
      //       return order.value ? -1 : 1;
      //     }
      //     if (A > B) {
      //       return order.value ? 1 : -1;
      //     }
      //     return 0;
      //   });
      // } else if (column.type === "date") {
      //   dataToShow.value.sort((a, b) => {
      //     if (order.value) {
      //       return new Date(a[column.key]) - new Date(b[column.key]);
      //     } else {
      //       return new Date(b[column.key]) - new Date(a[column.key]);
      //     }
      //   });
      // } else if (column.type === "checkbox") {
      //   dataToShow.value.sort((a, b) => {
      //     if (order.value) {
      //       return a[column.key] ? 1 : -1;
      //     } else {
      //       return b[column.key] ? 1 : -1;
      //     }
      //   });
      // }
    }

    function handleClickContext(params) {
      if (!props.options.contextOptions) {
        return;
      }
      let event = params[0];
      const d = params[1];
      if (
        router.currentRoute.value.name === "MyTasks" ||
        router.currentRoute.value.name === "MyTask" ||
        router.currentRoute.value.name === "MyTaskSection" ||
        router.currentRoute.value.name === "MyProjectsList" ||
        router.currentRoute.value.name === "MyProjectTasks" ||
        router.currentRoute.value.name === "MyProjectTaskSection"
      ) {
        // console.log(event);
        // if (Array.isArray(event)) {
        //   event = event[0];
        // }
        event = setEvent(event);

        event.preventDefault();

        dataContextMy.value = d;
        projectStore.setSelectedProjectTaskId(d.tsk_id);
        projectStore.setSelectedProjectId(d.pro_id);
        vueSimpleContextMenu.value.showMenu(event, { test: 123 });
      }
    }
    async function update(taskData) {
      hoursStore.getHours(taskData.tsk_id);
      emit("update");
    }
    function setEvent(e: any): any {
      if (!Array.isArray(e)) {
        return e;
      }
      return setEvent(e[0]);
    }

    function openModal(taskData) {
      const data: Modal = {
        component: "addHoursModal",
        title: "Add hours",
        options: {
          update: () => {
            update(taskData);
          },
          data: {
            tsk_name: taskData.tsk_name,
            tsk_id: taskData.tsk_id,
            tsk_color: taskData.tsk_color,
          },
        },
      };
      modalStore.setModalData(data);
    }

    function optionClicked(d) {
      switch (d.option.to_do) {
        case "project":
          if (dataContextMy.value.tsk_id && dataContextMy.value.pro_id) {
            openTask(
              dataContextMy.value.tsk_id,
              dataContextMy.value.pro_id,
              dataContextMy.value.first_parent
            );
          } else {
            openTask(
              projectStore.getSelectedProjectTaskId,
              projectStore.getSelectedProjectId,
              dataContextMy.value.first_parent
            );
          }
          break;
        case "add_hours":
          // const router = useRouter();
          // router.push("/add-hours");
          openModal(dataContextMy.value);
          break;
        case "delete":
          if (dataContextMy.value.deletable) {
            // console.log("delete", dataContextMy.value.tsk_id);
          } else {
            // console.log("no privileges");
          }
          break;
        case "archive":
          // if (dataContextMy.value.deletable) {
          //
          // } else {
          //
          // }
          break;
        case "lock":
          lock(dataContextMy.value.tsk_id, dataContextMy.value?.tsk_locked);

          break;
        case "create_subtask":
          emit("emit-create", dataContextMy.value.tsk_id);
          break;
        case "cut-task":
          // until selecting tasks is finished can only handle one task
          transferStore.setTasksCopy("cut", [dataContextMy.value]);
          break;
        case "copy-task":
          // until selecting tasks is finished can only handle one task
          transferStore.setTasksCopy("copy", [dataContextMy.value]);
          break;
        case "paste-task":
          transferStore.pasteTasks(dataContextMy.value);
          break;
        case "copy-hour":
          transferStore.setHoursCopy("copy", [dataContextMy.value]);
          break;
        case "cut-hour":
          transferStore.setHoursCopy("cut", [dataContextMy.value]);
          break;
        case "paste-hour":
          transferStore.pasteHours(dataContextMy.value);
          break;
        default:
          break;
      }
      vueSimpleContextMenu.value.hideContextMenu();
    }
    function lock(tsk_id: number, tsk_locked: number) {
      const params = {
        tsk_id,
        tsk_locked: tsk_locked !== null && tsk_locked !== 0 ? 0 : 1,
      };
      emit("set-lock", params);
      // try {
      //   await api.editProject(params);
      //   emit("refresh-list");
      // } catch (error) {

      // }
    }
    function openTask(task, pro, parent) {
      const params = JSON.parse(
        JSON.stringify(router.currentRoute.value.params)
      );
      params.taskId = Number(task);
      params.projectId = Number(pro);
      if (!params.section) {
        params.section = "details";
      }
      projectStore.setSelectedProjectTaskSection("details");
      router.push({
        name: "MyProjectTaskSection",
        params,
        query: { root: parent },
      });
    }

    function emitDelete(res) {
      emit("deleteRow", res);
    }

    function emitEdit(res) {
      emit("edit", res);
    }

    function emitCreate() {
      emit("create");
    }

    function updateColumnsToShow(e) {
      columnsToShow.value = e;
    }

    function emitRowClick(r) {
      emit("rowClick", r);
    }
    function changeValueCheckbox(e: any) {
      emit("change-value-checkbox", e);
    }

    return {
      getColumns,
      sort,
      dataToShow,
      columnsToShow,
      columnsOptions,
      search,
      plus,
      emitEdit,
      emitCreate,
      updateColumnsToShow,
      emitRowClick,
      emit,
      tableWrapper,
      optionClicked,
      handleClickContext,
      vueSimpleContextMenu,
      openModal,
      update,
      changeValueCheckbox,
      context_id,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/scroll";

.table-outer-wrapper {
  width: 100%;
  overflow: auto;

  // padding-right: 10px;

  .table-upper-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .upper-actions {
      width: 100%;
      margin-left: auto;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .no-data {
    margin-left: 15px;
  }
}

.table-wrapper {
  display: table;
  width: 100%;
  .table-head {
    display: table-row;

    .column-head {
      width: 500px;
      display: table-cell;
      padding: 0 8px;
      box-sizing: border-box;
      min-height: 28px;
      font-weight: 700;
      font-size: 11px;
      line-height: 12px;
      text-transform: uppercase;
      color: var(--text-color-table-head);
      // color: red;
      cursor: pointer;
      padding-bottom: 8px;
      white-space: nowrap;

      img {
        margin-left: 5px;
      }

      margin-bottom: 10px;
    }

    .column-head.dropdown {
      padding-left: 15px;
    }
    .column-head.dropdown,
    .column-head.small {
      width: 0;
    }
  }
  // :deep(.table-row:hover) {

  // }
}
</style>
<style>
/* .table-outer-wrapper .table-row .table-data {
  border-bottom: 1px solid #2e2e32;
} */

.ghost {
  background-color: #1b1b1e;
  border-left: none;
}

/* .column-wrapper:first-child .table-data {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.column-wrapper:last-child .table-data {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
} */
</style>
