<template>
  <div class="inputs-wrapper availability-type">
    <basic-input
      v-model="data.avt_name"
      type="text"
      label="Availability type name"
      :setFocus="true"
    />
    <div class="input-wrapper">
      <SelectColor
        :old="data.avt_color"
        :profile="true"
        :numberOfcolor="5"
        @color="setColor"
      ></SelectColor>
    </div>
  </div>
  <div class="inputs-wrapper availability-type">
    <basic-button label="Create" @click="saveChanges" />
  </div>
</template>
<script setup lang="ts">
import { ModalStore } from "../../store/modalStore";
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";

const modalStore = ModalStore();
const companyStore = CompanyStore();
const props = defineProps(["options"]);
defineEmits(["close"]);

//
const data: any = reactive({
  avt_name: null,
  avt_color: null,
});

onMounted(() => {});

function setColor(e: string) {
  data.avt_color = e;
}

async function saveChanges() {
  if (!data.avt_name) {
    openCustomToast("The 'Availability type name' field is required", "error");
    return;
  }
  if (!data.avt_color) {
    openCustomToast("The 'Availability type color' field is required", "error");
    return;
  }

  const params: any = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    avt_name: data.avt_name,
    avt_color: data.avt_color.startsWith("#")
      ? data.avt_color.substring(1)
      : data.avt_color,
  };

  try {
    await api.addAvailabilityTypes(params);
    openCustomToast(
      "You have successfully add the availability type",
      "success"
    );
    if (props.options.update) {
      props.options.update();
    }
    modalStore.setModalData(null);
  } catch (error: any) {
    openCustomToast(
      "An error occurred while adding the availability type",
      "error"
    );
  }
}
</script>
<style lang="scss">
.inputs-wrapper.availability-type {
  margin-bottom: 25px;
  .input-wrapper {
    margin-bottom: 15px;
  }
  .btn-wrapper-two {
    margin-left: auto;
    max-width: 100px;
  }
}
</style>
