<template>
  <div class="wrapp-client-modal">
    <div class="wrap-main-flex">
      <!-- <basic-input
        v-model="data.name"
        placeholder="Client"
        label="Client"
        costumHeight="77px"
        fontSize="30px"
        fontWeight="500"
      /> -->
      <div class="input-wrapper-wrp">
        <basic-input
          v-model="data.name"
          placeholder="Client"
          label="Client"
          fontWeight="500"
          :color="defaultInputColor"
          :setFocus="true"
        />
        <div class="wrapNum">
          <basic-input
            v-model="data.regnumber"
            placeholder="Reg. number"
            label="Registration number (MB)"
          />

          <basic-input
            v-model="data.taxnumber"
            placeholder="Tax number"
            label="Tax number (PIB)"
          />
        </div>
      </div>

      <image-add
        labelText="Logo"
        costumHeight="108px"
        :imageProp="data.img_id === null ? null : getOldImg(data.img_id)"
        @deleteimg="deleteImg"
        @setimg="setImg"
      />
    </div>
    <!--ADDERSS-->
    <div class="input-wrapper">
      <basic-input
        v-model="data.cli_address"
        :color="defaultInputColor"
        placeholder="Address"
        label="Address"
      />
    </div>

    <div class="wrap-flex">
      <div class="content3">
        <basic-input v-model="data.cli_city" placeholder="City" label="City" />
        <basic-input
          v-model="data.postal_code"
          placeholder="Postal code"
          label="Postal code"
        />
        <div class="input-wrapper">
          <basic-dropdown
            class="types"
            :list="countries"
            :selected="data.cli_country"
            :searchable="true"
            :labelKey="'cnt_name'"
            :title="'Country'"
            @selected="setCountry"
          />
        </div>
      </div>

      <div class="content">
        <div class="wrappHalf">
          <basic-input
            v-model="data.contactname"
            :color="defaultInputColor"
            placeholder="Contact name"
            label="Contact name"
          />

          <basic-input
            v-model="data.contactphone"
            placeholder="Contact phone"
            label="Contact phone"
          />
        </div>
        <basic-input
          v-model="data.contactemail"
          placeholder="Contact email"
          label="Contact email"
        />
      </div>
    </div>
    <basic-button :modal="true" label=" Save " @click="editClient" />
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { baseURL } from "@/api/config";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { openCustomToast } from "@/assets/js/helpers";

defineEmits(["close"]);
const props = defineProps(["options"]);
const companyStore = CompanyStore();
const modalStore = ModalStore();

const defaultInputColor = "#5190FF";

const oldData = reactive<any>({
  name: null,
  contactname: null,
  contactphone: null,
  contactemail: null,
  taxnumber: null,
  regnumber: null,
  cli_country: null,
  cli_address: null,
  cli_city: null,
  img_id: null,
  postal_code: null,
});
const data = reactive<any>({
  name: null,
  contactname: null,
  contactphone: null,
  contactemail: null,
  taxnumber: null,
  regnumber: null,
  cli_country: null,
  cli_address: null,
  cli_city: null,
  img_id: null,
  postal_code: null,
});

onMounted(() => {
  setupData();
});
// get countries
const countries = ref([]);
async function getCountries() {
  try {
    const res = await api.getCountries();
    countries.value = res.data.countries;
  } catch (e) {
    console.log(e);
  }
}
getCountries();
function setCountry(e) {
  data.cli_country = e;
}

function setupData() {
  oldData.name = props.options.data.cli_name;
  oldData.contactname = props.options.data.cli_contactname;
  oldData.contactemail = props.options.data.cli_contactemail;
  oldData.contactphone = props.options.data.cli_contactphone;
  oldData.taxnumber = props.options.data.cli_taxnumber;
  oldData.regnumber = props.options.data.cli_regnumber;

  data.name = props.options.data.cli_name;
  data.contactname = props.options.data.cli_contactname;
  data.contactemail = props.options.data.cli_contactemail;
  data.contactphone = props.options.data.cli_contactphone;
  data.taxnumber = props.options.data.cli_taxnumber;
  data.regnumber = props.options.data.cli_regnumber;

  // daa new param
  data.cli_country = { ...props.options.data.cli_country };
  data.cli_address = props.options.data.cli_address;
  data.cli_city = props.options.data.cli_city;
  data.img_id = props.options.data.img_id;
  data.postal_code = props.options.data.postal_code;

  oldData.cli_country = { ...props.options.data.cli_country };
  oldData.cli_address = props.options.data.cli_address;
  oldData.cli_city = props.options.data.cli_city;
  oldData.img_id = props.options.data.img_id;
  oldData.postal_code = props.options.data.postal_code;
}

function getOldImg(id: any) {
  if (id === null) {
    return null;
  }
  return baseURL + "/images/" + id;
}
// const getOldImg = ref<any>(
//   baseURL + "/images/" + data.img_id
// );
const image = ref<any>(null);

function setImg(e) {
  image.value = e;
  data.img_id = null;
  console.log(image.value);
}

function deleteImg() {
  data.img_id = null;
  image.value = null;
}

async function editClient() {
  if (data.name) {
    const params: any = await getParams();
    // set in getParams
    /* 
       params.cmp_id = companyStore.getActiveCompany?.cmp_id;
       params.cli_id = props.options.data.cli_id; 
    */
    try {
      await api.editClient(params);
      if (props.options.update) {
        props.options.update();
      }
      modalStore.setModalData(null);
      openCustomToast("You have successfully changed the client.", "success");
    } catch (error: any) {
      if (error.response.data.message === "nothing-changed") {
        openCustomToast("No changes.", "error");
        return;
      }
      if (error.response.data.message === "invalid-contactemail") {
        openCustomToast("The 'Contact email' field is not valid.", "error");
        return;
      }
      openCustomToast("An error occurred while changing the client.", "error");
    }
  } else {
    openCustomToast('The field "Client" is required.', "error");
  }
}

async function getParams() {
  // let params: any = {};
  const params = new FormData();

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (data[key] !== oldData[key]) {
        if (key === "cli_country") {
          if (data[key].cnt_code !== oldData[key].cnt_code) {
            params.append("cnt_code", data[key].cnt_code);
          }
        } else {
          params.append(key, data[key]);
        }
      }
    }
  }

  if (image.value) {
    params.append("image", image.value);
  }
  params.append("cmp_id", companyStore.getActiveCompany?.cmp_id);
  params.append("cli_id", props.options.data.cli_id);

  return params;
}
</script>
<style lang="scss" scoped>
// .wrapp-client-modal {
//   box-sizing: border-box;
//   width: 720px;
//   padding: 0 12px;
// }
// .wrap-main-flex {
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   height: 77px;
//   height: fit-content;

//   .input-wrapper {
//     width: 80%;
//     height: 100%;

//     .input-outer {
//       input {
//         height: 77px !important;
//       }
//     }
//   }
// }

// .wrap-flex {
//   .content {
//     max-width: 100%;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     .input-wrapper {
//       margin-bottom: 20px !important;
//       width: 45%;
//     }
//   }
//   .content:first-of-type {
//     //
//     margin-bottom: 50px;
//   }
// }
.wrapp-client-modal {
  box-sizing: border-box;
  // width: 720px;
  width: 814px;
  padding: 0 12px;
}

.wrap-main-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 77px;
  height: fit-content;
  margin-bottom: 35px;

  .input-wrapper-wrp {
    width: 80%;
    height: 100%;
    .wrapNum {
      display: flex;
      justify-content: space-between;
      .input-wrapper {
        width: 48%;
      }
    }

    .input-outer {
      input {
        height: 108px !important;
      }
    }
  }
}

.wrap-flex {
  .content3,
  .content {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .wrappHalf {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .input-wrapper {
      margin-bottom: 20px !important;
      width: 47%;
    }
  }
  .content3 {
    margin-bottom: 40px;

    .input-wrapper {
      margin-bottom: 20px !important;
      width: 31%;
    }
  }

  .content:first-of-type {
    //
    margin-bottom: 50px;
  }
}
@media screen and (width <= 820px) {
  .wrapp-client-modal {
    width: unset;
    padding: unset;
  }
}
</style>
<style lang="scss">
.wrapp-client-modal {
  .input-wrapper {
    margin-bottom: 20px !important;
  }

  .btn-wrapper-two {
    width: fit-content !important;
    margin-left: auto;
  }
}
</style>
