<template>
  <div class="reports-page-wrapper">
    <div class="left">
      <!-- <basic-list
        :listKey="'id'"
        :data="listReports"
        :label="'label'"
        :active="activeReports"
        @click="getRoleDetails"
      /> -->
      <basic-list
        :listKey="'rpt_id'"
        :data="viewTypes"
        :label="'rpt_name'"
        :active="activeReports"
        @click="getRoleDetails"
      />
    </div>
    <!-- end left -->
    <div class="right">
      <h3>{{ name }}</h3>
      <div v-for="(item, index) in activeReports?.rpt_params" :key="index">
        <div v-if="item.type === 'user_array'" class="split">
          <div class="text">{{ item.display_name }}</div>
          <div class="field">
            <chooser-view
              v-model="getData.users"
              :title="''"
              :users="users"
              :edit="true"
              :changable="false"
              @search="onSearch"
            />
          </div>
        </div>
        <!--  -->
        <div v-if="item.type === 'user'" class="split">
          <div class="text">{{ item.display_name }}</div>
          <div class="field">
            <!-- <chooser-view
              v-model="getData.users"
              :title="''"
              :users="users"
              :edit="true"
              :changable="false"
              @search="onSearch"
            /> -->
            <basic-dropdown
              :list="users"
              :selected="getData.user"
              :searchable="true"
              :labelKey="'usr_displayname'"
              style="align-self: flex-end; width: 270px"
              @selected="setUser"
            />
          </div>
        </div>
        <!--  -->
        <div v-if="item.type === 'projects'" class="split">
          <div class="text">Projects</div>
          <div class="field">
            <dropdownTwo
              v-model="tsk_id"
              :options="projects"
              dropdownLabel="tsk_name"
              dropdownKey="tsk_id"
              class="mt-5"
              :label="item.name"
              :disabled="false"
            />
          </div>
        </div>

        <!--  -->
        <div v-if="item.name === 'date_from'" class="split">
          <div class="text">{{ item.display_name }}</div>
          <div class="field">
            <Datepicker
              v-model="getData.date_from"
              :clearable="false"
              format="dd.MM.yyyy"
              :enable-time-picker="false"
              :dark="true"
              placeholder="Date from"
              input-class-name="datepicker-input"
              menu-class-name="datepicker-dropdown"
              style="width: 270px"
              :max-date="getData.date_to"
            />
          </div>
        </div>
        <div v-if="item.name === 'date_to'" class="split">
          <div class="text">{{ item.display_name }}</div>
          <div class="field">
            <Datepicker
              v-model="getData.date_to"
              :clearable="false"
              format="dd.MM.yyyy"
              :enable-time-picker="false"
              :dark="true"
              placeholder="Date to"
              input-class-name="datepicker-input"
              menu-class-name="datepicker-dropdown"
              style="width: 270px"
              :min-date="getData.date_from"
            />
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <basic-button
          label="Run"
          :disabled="disabled"
          style="width: 100px"
          @click="getReports"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onActivated, watch } from "vue";
import { CompanyStore } from "@/store/companyStore";
import type { User } from "@/assets/js/types";
// import { type Task } from "@/assets/js/types";
import dayjs from "dayjs";
import api from "@/api";
import { debounce, openCustomToast } from "@/assets/js/helpers";
import Datepicker from "@vuepic/vue-datepicker";
import { DateInstance } from "@/assets/js/dateHelper";
import { time } from "console";
// import { checkPermission, openCustomToast } from "@/assets/js/helpers";
interface Task {
  tsk_id: number;
  tsk_name: string;
  description?: string;
  client?: string;
  project?: string;
  due_date?: string;
  hours?: string;
  assigned?: User[];
  created?: User;
  children?: Task[];
}

const companyStore = CompanyStore();
const viewTypes: any = ref([]);

/* const listReports = ref([
  { id: 1, label: "Sati za klijenta" },
  { id: 2, label: "Sati po korisniku" },
  { id: 3, label: "Sati po projektu" },
]); */

const activeReports = ref<any>();
const name = ref<string | null>(null);
const projects = ref<Task[]>([]);
const disabled = ref(false);
// params
const tsk_id = ref<Task[]>([]);
const getData = ref({
  users: <User[]>[],
  user: null,
  date_from: null,
  date_to: null,
});

// const users = reactive<User[]>([]);
const users = ref<User[]>([]);
const onSearch = debounce(
  function () {
    getUsers(arguments[0]);
  },
  500,
  false
);

async function getUsers(search?: string) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    if (search) {
      params.search = search;
    }
    const res = await api.searchUsers(params);
    users.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

async function getProjects(locked?: number) {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      offset: 0,
      limit: 100,
    };
    const res = await api.getProjects(params);
    projects.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getViewReports();
  getProjects();
});
onActivated(() => {
  getViewReports();
  clearFilds();
  getProjects();
});

function getRoleDetails(e: any) {
  activeReports.value = e;
  name.value = e.rpt_name;
  console.log(e);
}

async function getViewReports() {
  const params: any = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
  };
  try {
    let res = await api.getViewReports(params);
    viewTypes.value = res.data.data;
    getRoleDetails(viewTypes.value[0]);
  } catch (e) {
    console.log(e);
  }
}

async function getReports() {
  disabled.value = true;
  // if (tsk_id.value.length === 0) {
  //   disabled.value = false;
  //   openCustomToast(`Parameter Projects is required.`, "error");
  //   return;
  // }

  const params: any = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    // tsk_id: getTasksId(),
    query_params: [],
  };

  for (let i = 0; i < activeReports.value.rpt_params.length; i++) {
    if (activeReports.value.rpt_params[i].required === 1) {
      if (
        // (activeReports.value.rpt_params[i].name === "user" ||
        //   activeReports.value.rpt_params[i].name === "users") &&
        getData.value.users.length > 0 &&
        activeReports.value.rpt_params[i].type === "user_array"
      ) {
        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: getUsersId(),
        });
      } else if (
        // (activeReports.value.rpt_params[i].name === "user" ||
        //   activeReports.value.rpt_params[i].name === "users") &&
        activeReports.value.rpt_params[i].type === "user_array" &&
        getData.value.users.length <= 0
      ) {
        disabled.value = false;
        openCustomToast(
          `Parameter ${activeReports.value.rpt_params[i].display_name} is required.`,
          "error"
        );
        return;
      } else if (
        activeReports.value.rpt_params[i].type === "projects" &&
        tsk_id.value.length > 0
      ) {
        // params.projects = getTasksId();
        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: getTasksId(),
        });
      } else if (
        activeReports.value.rpt_params[i].name === "date_from" &&
        getData.value[activeReports.value.rpt_params[i].name]
      ) {
        let date = new Date(
          getData.value[activeReports.value.rpt_params[i].name]
        );
        date.setHours(0, 0, 0);
        const startOfDay = new DateInstance(
          date.toString(),
          "",
          true
        ).formatDate("YYYY-MM-DD HH:mm:ss");

        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: startOfDay,
        });
      } else if (
        activeReports.value.rpt_params[i].name === "date_to" &&
        getData.value[activeReports.value.rpt_params[i].name]
      ) {
        let date = new Date(
          getData.value[activeReports.value.rpt_params[i].name]
        );
        date.setHours(23, 59, 59);
        const endOfDay = new DateInstance(date.toString(), "", true).formatDate(
          "YYYY-MM-DD HH:mm:ss"
        );

        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: endOfDay,
        });
      } else if (getData.value[activeReports.value.rpt_params[i].name]) {
        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: getData.value[activeReports.value.rpt_params[i].name],
        });
      } else {
        disabled.value = false;
        openCustomToast(
          `Parameter ${activeReports.value.rpt_params[i].display_name} is required.`,
          "error"
        );
        return;
      }
    } else {
      if (
        // (activeReports.value.rpt_params[i].name === "user" ||
        //   activeReports.value.rpt_params[i].name === "users") &&
        getData.value.users.length > 0 &&
        activeReports.value.rpt_params[i].type === "user_array"
      ) {
        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: getUsersId(),
        });
      } else if (
        activeReports.value.rpt_params[i].type === "projects" &&
        tsk_id.value.length > 0
      ) {
        // params[activeReports.value.rpt_params[i].name] = getTasksId();
        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: getTasksId(),
        });
      } else if (getData.value[activeReports.value.rpt_params[i].name]) {
        params.query_params.push({
          name: activeReports.value.rpt_params[i].name,
          value: getData.value[activeReports.value.rpt_params[i].name],
        });
      }
    }
  }
  const time_offset = DateInstance.getTimeDifferenceFromUtc();
  params.time_offset = time_offset;
  try {
    let res = await api.getViewReportsID(activeReports.value.rpt_id, params);

    if (res.data.data.length <= 0) {
      openCustomToast(`There are no reports for the selected dates.`, "error");
    } else {
      let blob = new Blob([jsonToCsv(res.data.data)], {
        type: "application/csv",
      });

      let url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Report_for${getTasksNames()}from_${dayjs
          .utc(getData.value.date_from)
          .local()
          .format("DD.MM.YYYY.")}_to_${dayjs
          .utc(getData.value.date_to)
          .local()
          .format("DD.MM.YYYY.")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      clearFilds();
    }
    disabled.value = false;
  } catch (e: any) {
    if (e.response.data.message && e.response.status !== 500) {
      openCustomToast(e.response.data.message, "error");
    } else {
      openCustomToast(
        `An error occurred, you may not have privileges.`,
        "error"
      );
    }
    disabled.value = false;
  }
}

function jsonToCsv(json) {
  const csvRows: any = [];

  // Extract the header row from the first object
  const headers = Object.keys(json[0]);
  csvRows.push(headers.join(","));
  // Extract the data rows
  json.forEach((row: any) => {
    const values = headers.map((header) => {
      const cellValue: any = (row[header] + "").replace(/\n/g, "\\n");
      return `"${cellValue}"`;
    });
    csvRows.push(values.join(","));
  });

  // Join all rows with line breaks
  return csvRows.join("\n");
}
// function transformToCsv(data) {
//   let res = [Object.keys(data[0])];
//   res.push(...data.map((el) => Object.values(el)));
//   return res;
// }
//  let blob = new Blob(
//         [
//           transformToCsv(res.data.data)
//             .map((el) => el.join(","))
//             .join("\n"),
//         ],
//         { type: "application/csv" }
//       );

function getUsersId() {
  let res: any = [];
  for (let i = 0; i < getData.value.users.length; i++) {
    res.push(getData.value.users[i].usr_id);
  }
  return res;
}

function getTasksId() {
  let res: any = [];
  for (let i = 0; i < tsk_id.value.length; i++) {
    res.push(tsk_id.value[i].tsk_id);
  }
  return res;
}

function getTasksNames() {
  let res: any = "_";
  for (let i = 0; i < tsk_id.value.length; i++) {
    res += tsk_id.value[i].tsk_name + "_";
  }
  return res.toUpperCase();
}

function clearFilds() {
  tsk_id.value = [];
  getData.value = {
    users: [],
    user: null,
    date_from: null,
    date_to: null,
  };
  // users.value = [];
}

function setUser(e: any) {
  getData.value.user = e;
}

watch(
  () => companyStore.getActiveCompany?.cmp_id,
  (old, newV) => {
    getViewReports();
    clearFilds();
    getProjects();
  }
);
</script>
<style lang="scss" scoped>
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.reports-page-wrapper {
  padding-top: 55px;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  .right {
    margin-left: 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin-bottom: 20px;
      margin-top: 0px;
    }

    h3::first-letter {
      text-transform: capitalize;
    }

    .label {
      font-size: 14px;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .split {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      .text {
        width: 200px;
        margin-right: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        height: fit-content;
      }
      .field {
      }
    }
  }

  .left {
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .btn-wrapper {
    min-width: 100%;
    margin-top: 40px;
    display: flex;
    // justify-content: center;
  }
}
</style>
<style lang="scss">
.reports-page-wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  width: 100%;
  max-height: 400px;

  .container {
    height: auto;
    // margin-bottom: 5px; // was 20px
    margin-right: 20px;

    .label {
      text-align: left;
    }
  }

  .container:hover {
    background: none;
  }
  .datepicker-input::placeholder {
    font-weight: 400;
    color: var(--label-color);
  }
}
</style>
